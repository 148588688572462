import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loginUser } from '../actions';
import LoginComponent from '../components/auth/LoginComponent';

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginUser: loginUser
  }, dispatch);
}

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent)

export default LoginContainer;
