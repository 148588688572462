import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from '../actions';

const defaultState = {
  token: null,
  isLoggedIn: false,
  errorMessage: null
}

export default (state=defaultState, action) => {
  switch(action.type) {
    case  LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        isLoggedIn: true,
        errorMessage: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        errorMessage: action.payload
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        isLoggedIn: false,
        errorMessage: null
      }
    default:
      return state;
  }
}
