import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/toolbar.scss';
import Contentful from "../../helpers/contentful";

class SubmitToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {     
      contentful: new Contentful(),
      basePrice: null,
    };

    this.returnToEditor = this.returnToEditor.bind(this);

    this.initContent();
  }

  initContent = async () => {
    let basePrice = await this.state.contentful.getEntry(
      '538ELQymmXp0rlJE83IqW9'
    );
    this.setState({ basePrice });
  };

  returnToEditor() {
    this.props.history.push("/");
  }

  render() {  
    const belt = this.props.completedOrder ? this.props.completedOrder.belt : this.props.belt;
    const motifs = this.props.completedOrder ? belt.motifIds : belt.motifs;
    const motifsOnBelt = this.props.completedOrder ? motifs.map((x) => this.props.allMotifs.byId[x].upcharge) : motifs.map((x) => this.props.allMotifs.byId[x[0]].upcharge);

    return (
      <div id="toolbar">
        <div className="container">
          <div id="toolbar-submit-container">
            <div className="toolbar-section">
              <Link to="/"><img className="logo" src="/sb_logo.png" alt="" /></Link>
              <Link to="/faq">FAQs</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
            <div className="toolbar-section">
              { this.state.basePrice ?  
                <div>
                  <p className="toolbar-item-content">${motifsOnBelt.length > 0 ? motifsOnBelt.reduce((a, b) => a + b) + this.state.basePrice.price : this.state.basePrice.price }</p>
                  <p className="toolbar-item-label">Belt Price</p>
                </div> : null}             
              <div>
                <p className="toolbar-item-content">{belt.size}<span className="small">in</span></p>
                <p className="toolbar-item-label">Belt Size</p>
              </div>
              { this.props.location.pathname === "/submit" && motifs.map(x => x[2]).filter(x => x !== null).length > 0 ? (
                <div className="toolbar-item">
                  <label className="switch">
                    <input type="checkbox" checked={this.props.showCustomInfo} onChange={this.props.toggleCustomInfo} />
                    <span className="slider round"></span>
                  </label>
                  <p className="toolbar-item-label">Show Personalized Info</p>
                </div>
              ) : null }
              {this.props.location.pathname === "/submit" ? <button className="btn grey btn-m0" onClick={this.returnToEditor}>&larr;&nbsp;&nbsp;Return to editor</button> : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SubmitToolbar;
