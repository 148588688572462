import React, { Component } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Loading from './Loading';
import '../../styles/submit-complete.scss';
import Contentful from '../../helpers/contentful';

class SubmitComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentful: new Contentful(),
      content: null,
    };
    this.initContent();
  }

  initContent = async () => {
    let content = await this.state.contentful.getEntry(
      '5689FSJTlAgVGOJylS9Dk4'
    );
    this.setState({ content });
  };

  render() {    
    return this.props.completedOrder ? (
      <div id='submit-complete'>
        <div className='container'>
          <div id='complete-container'>
            {this.state.content ? (
              <div id='complete'>
                <h2>
                  {this.state.content.title}{' '}
                  {this.props.completedOrder.user.firstName}!
                </h2>

                {documentToReactComponents(this.state.content.text1)}

                <p className='complete-info'>
                  <span>{this.state.content.designField}</span>[{' '}
                  {this.props.completedOrder.orderNumber} ]
                </p>
                <p className='complete-info'>
                  <span>{this.state.content.emailField}</span>
                  {this.props.completedOrder.user.email}
                </p>
                {this.state.content.confirmLinkName ?                
                  <div className='confirm-link'>
                    <div className='confirm-text'>{this.state.content.confirmLinkText}</div>                  
                    <a href={this.props.linkString}>{this.state.content.confirmLinkName}</a>
                  </div>
                : 
                null}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    ) : (
      <Loading />
    );
  }
}

export default SubmitComplete;
