import React, { Component } from 'react';

class MotifPersonalization extends Component {
  render() {
    return (
      <div className="motif-personalization">
        <p onClick={this.props.toggle}>Edit</p>
        <span className="status" style={{color: this.props.data.valid ? "green" : "red"}}>{this.props.data.valid ? String.fromCharCode(10003) : String.fromCharCode(10007)}</span>
      </div>
    )
  }
}

export default MotifPersonalization;
