import { schema } from "normalizr";

const subcategory = new schema.Entity("Subcategories");


const personMotif = new schema.Entity("PersonMotifs");

const personCategory = new schema.Entity("PersonCategories", {
  PersonMotifs: [personMotif],
});

personMotif.define({
  PersonCategory: personCategory,  
});


const motif = new schema.Entity("Motifs", {
  subcategories: [subcategory],
});

const category = new schema.Entity("Categories", {
  Subcategories: [subcategory],
});

subcategory.define({
  Category: category,
  motifs: [motif],
});

const restriction = new schema.Entity("Restrictions", {
  firstSub: subcategory,
  secondSub: subcategory,
});

const categoryList = new schema.Array(category);
const motifList = new schema.Array(motif);
const restrictionList = new schema.Array(restriction);
const personCategoryList = new schema.Array(personCategory);
const personMotifList = new schema.Array(personMotif);

export {
  categoryList,
  motifList,
  restrictionList,
  personCategoryList,
  personMotifList,
};
