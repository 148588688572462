import React, { Component } from 'react';
import '../../styles/notifications.scss';

class Notifications extends Component {
  render() {
    const className = this.props.notification.type;

    return this.props.notification.present ? (
      <div id="notification" className={className}>
        <div>
          <p>{this.props.notification.text}</p>
        </div>
      </div>
    ) : null;
  }
}

export default Notifications;
