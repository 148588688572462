import React from 'react';
import '../../styles/loading.scss';

const Loading = props => {
  return (
    <div id="loading-wrapper">
      <div id="loading">
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    </div>
  )
}

export default Loading;