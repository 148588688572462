import { authHeader, handleResponse } from "../../helpers/index.js";
import { adminRequestMotifs } from "./MotifActions.js";

export const ADMIN_NOTIFICATION = "ADMIN_NOTIFICATION";
export const CLOSE_ADMIN_NOTIFICATION = "CLOSE_ADMIN_NOTIFICATION";

export function adminUploadCSV(data) {
  return function (dispatch) {
    return fetch("/api/admin/upload", {
      method: "POST",
      body: data,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((data) => {
        if (data.success) {
          dispatch(adminUploadCSVSuccess());
          dispatch(adminRequestMotifs());
        }
      });
  };
}

export function adminUploadTXT(data, isMotif) {
 
  if (isMotif) {
    return function (dispatch) {
      return fetch("/api/admin/upload/motiftxt", {
        method: "POST",
        body: data,
        headers: authHeader(),
      })
        .then((res) => handleResponse(res))
        .then((data) => {
          if (data.success) {
            dispatch(adminUploadTXTSuccess());
            dispatch(adminRequestMotifs());
          }
        });
    };
  } else {
    return function (dispatch) {
      return fetch("/api/admin/upload/txt", {
        method: "POST",
        body: data,
        headers: authHeader(),
      })
        .then((res) => handleResponse(res))
        .then((data) => {
          if (data.success) {
            dispatch(adminUploadTXTSuccess());
            dispatch(adminRequestMotifs());
          }
        });
    };
  }
}

export function adminUploadCSVSuccess() {
  return {
    type: ADMIN_NOTIFICATION,
    payload: "CSV uploaded successfully",
  };
}

export function adminUploadTXTSuccess() {
  return {
    type: ADMIN_NOTIFICATION,
    payload: "TXT uploaded successfully",
  };
}

export function adminUploadMotifImages(data) {
  return function (dispatch) {
    return fetch("/api/admin/upload/motifs", {
      method: "POST",
      body: data,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((data) => {
        dispatch(adminUploadMotifImagesSuccess(data.length));
      });
  };
}

export function adminUploadMotifImagesSuccess(length) {
  return {
    type: ADMIN_NOTIFICATION,
    payload: length + " motif image(s) uploaded successfully",
  };
}

export function closeAdminNotification() {
  return {
    type: CLOSE_ADMIN_NOTIFICATION,
  };
}
