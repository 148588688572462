import React, { Component } from 'react';
import '../../styles/modal.scss';

class ModalBackground extends Component {
  render() {
    return (
      <div id="modal-background" onClick={this.props.closeModal}>
      </div>
    )
  }
}

export default ModalBackground;
