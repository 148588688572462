import React, { Component } from "react";
import Motif from "./Motif";
import { DropTarget } from "react-dnd";
import { motifRand, validateAddToBelt } from "../../helpers";

class Belt extends Component {
  pushMotif(motif) {
    var rand = motifRand();

    if (
      validateAddToBelt(
        motif.id,
        rand,
        this.props.allMotifs,
        this.props.belt,
        this.props.restrictions,
        this.props.setNotification
      )
    ) {
      this.props.sendAnalyticsEvent(motif.id, "belt", null);
      this.props.addMotifToBelt(motif.id, rand);

      // Add motif to idea box if not there
      if (this.props.ideaBox.motifs.indexOf(motif.id) === -1) {
        this.props.addMotifToIdeaBox(motif.id);
      }
    }
  }

  removeMotif(motifIndex) {
    this.props.removeMotifFromBelt(motifIndex);
  }

  moveMotif(dragIndex, hoverIndex) {
    var originalBelt = [...this.props.belt.motifs];

    const dragMotif = originalBelt[dragIndex];

    originalBelt.splice(dragIndex, 1);
    originalBelt.splice(hoverIndex, 0, dragMotif);

    this.props.moveMotifOnBelt(originalBelt);
  }

  beltHeight() {
    const beltSize = this.props.belt.size;
    return "belt-size-" + beltSize;
  }

  render() {
    const { canDrop, isOver, connectDropTarget } = this.props;
    const isActive = canDrop && isOver;
    const className = "isActive";
    const beltSizeClass = this.beltHeight();
    const addedMotifs = this.props.belt.motifs.length ? this.props.belt.motifs.map(x =>  this.props.allMotifs.byId[x[0]]) : []
    const beltWithMotifs = {
      ...this.props.belt, addedMotifs
    }

    
    return connectDropTarget(
      <div
        id="belt-mock"
        className={isActive ? className + " " + beltSizeClass : beltSizeClass}
      >
        {this.props.belt.motifs.length > 0
          ? this.props.belt.motifs.map((motifTuple, i) => {
          
              return (
                <Motif
                  key={"belt-" + motifTuple[0] + "-" + motifTuple[1]}
                  listId="belt"
                  index={i}
                  beltWithMotifs={beltWithMotifs}
                  moveMotif={this.moveMotif.bind(this)}
                  motif={this.props.allMotifs.byId[motifTuple[0]]}
                  allPersonMotifs={motifTuple[2] ? this.props.allPersonMotifs : []}
                  motifId={motifTuple[0]}
                  motifRand={motifTuple[1]}
                  personalizedData={motifTuple[2]}
                  removeMotif={this.removeMotif.bind(this)}
                  showPremiumModal={this.props.showPremiumModal}
                  showPersonalizationModal={this.props.showPersonalizationModal}
                  updateMotifOnBelt={this.props.updateMotifOnBelt}
                  showCustomInfo={this.props.showCustomInfo}
                  setNotification={this.props.setNotification}
                />
              );
            })
          : null}
      </div>
    );
  }
}

const motifTarget = {
  drop(props, monitor, component) {
    const { id } = props;
    const sourceObj = monitor.getItem();
    if (id !== sourceObj.listId) component.pushMotif(sourceObj.motif);
    return {
      listId: id,
    };
  },
};

export default DropTarget("MOTIF", motifTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(Belt);
