import React, { Component } from "react";
import { Route, Link, NavLink } from "react-router-dom";
import { CSVLink } from "react-csv";
import "../../styles/admin.scss";
import AdminMotif from "./AdminMotif";
import AdminPersonMotif from "./AdminPersonMotif";
import AdminCategory from "./AdminCategory";
import AdminPersonCategory from "./AdminPersonCategory";
import AdminRestriction from "./AdminRestriction";
import AdminSubmittedBelts from "./AdminSubmittedBelts";
import AdminDraftedBelts from "./AdminDraftedBelts";
import AdminUpload from "./AdminUpload";
import NewMotif from "./NewMotif";
import EditMotif from "./EditMotif";

class Admin extends Component {
  constructor(props) {
    super(props);
 
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.props.adminRequestMotifs();
    this.props.adminRequestCategories();
    this.props.adminRequestPersonMotifs();
    this.props.adminRequestPersonCategories();
    this.props.adminRequestAnalytics();
  }

  logout() {
    this.props.logoutUser();
    this.props.history.push("/login");
  }

  render() {  
    return (
      <div>
        <div className="admin-header">
          <div className="container">
            <h3>S&amp;B Generator | Admin Console</h3>
            <Link to="/">Back to App</Link>
            <p className="logout" onClick={this.logout}>
              Logout
            </p>
          </div>
        </div>
        <div className="container" id="admin-content">
          {this.props.notification ? (
            <div className="admin-notification">
              <p>{this.props.notification}</p>
              <img
                src="/close_icon.png"
                alt=""
                onClick={this.props.closeAdminNotification}
              />
            </div>
          ) : null}
          <div id="admin-overview" className="cardbox">
            {this.props.analytics && this.props.motifs ? (
              <div>
                <div className="row">
                  <div className="col-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        height: "100%",
                        textAlign: "center",
                      }}
                    >
                      <div>
                        <h4 className="m0"># of Completed Belts</h4>
                        <p className="stat not-last">
                          {this.props.analytics.completedBelts}
                        </p>
                      </div>
                      <div>
                        <h4 className="m0"># of Drafted Belts</h4>
                        <p className="stat not-last">
                          {this.props.analytics.draftedBelts}
                        </p>
                      </div>
                      <div>
                        <h4 className="m0">Share Clicks</h4>
                        <p className="stat">
                          {this.props.analytics.shareLinkClicks}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <h4>Top Motifs (to belt)</h4>
                    <ul>
                      {this.props.analytics.belt.map((x, i) => {
                        return (
                          <li key={"belt" + i}>
                            <p>{x.count}</p>{" "}
                            {this.props.motifs.byId[x.MotifId]
                              ? this.props.motifs.byId[x.MotifId].name
                              : x.MotifId}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="col-3">
                    <h4>Top Motifs (to idea box)</h4>
                    <ul>
                      {this.props.analytics.ideaBox.map((x, i) => {
                        return (
                          <li key={"ideabox" + i}>
                            <p>{x.count}</p>{" "}
                            {this.props.motifs.byId[x.MotifId]
                              ? this.props.motifs.byId[x.MotifId].name
                              : x.MotifId}
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="col-3">
                    <h4>Top Motifs (submitted)</h4>
                    <ul>
                      {this.props.analytics.submitted.map((x, i) => {
                        return (
                          <li key={"submitted" + i}>
                            <p>{x.count}</p>{" "}
                            {this.props.motifs.byId[x.MotifId]
                              ? this.props.motifs.byId[x.MotifId].name
                              : x.MotifId}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div id="modules-router">
            <NavLink activeClassName="active" to="/admin/motifs">
              Motifs
            </NavLink>
            <NavLink activeClassName="active" to="/admin/personmotifs">
              Personalized Motifs
            </NavLink>
            <NavLink activeClassName="active" to="/admin/categories">
              Categories
            </NavLink>
            <NavLink activeClassName="active" to="/admin/personcategories">
              Personalized Categories
            </NavLink>
            <NavLink activeClassName="active" to="/admin/restrictions">
              Restrictions
            </NavLink>
            <NavLink activeClassName="active" to="/admin/submitted">
              Completed Belts
            </NavLink>
            <NavLink activeClassName="active" to="/admin/drafts">
              Drafted Belts
            </NavLink>
            <NavLink activeClassName="active" to="/admin/upload">
              Upload
            </NavLink>
          </div>

          <Route
            path="/admin/motifs/new"
            render={(props) => (
              <NewMotif
                {...props}
                categories={this.props.categories}
                subcategories={this.props.subcategories}
                adminCreateMotif={this.props.adminCreateMotif}
              />
            )}
          />
          <Route
            path="/admin/motifs/:id/edit"
            render={(props) => (
              <EditMotif
                {...props}
                motifs={this.props.motifs}
                categories={this.props.categories}
                subcategories={this.props.subcategories}
                adminCreateMotif={this.props.adminCreateMotif}
                adminUpdateMotif={this.props.adminUpdateMotif}
                adminDeleteMotif={this.props.adminDeleteMotif}
              />
            )}
          />

          <div id="admin-modules" className="cardbox">
            <Route
              path="/admin/motifs"
              render={(props) => (
                <AdminMotif
                  {...this.props}
                  motifs={this.props.motifs}
                  categories={this.props.categories}
                  subcategories={this.props.subcategories}
                />
              )}
            />
            <Route
              path="/admin/personmotifs"
              render={(props) => (
                <AdminPersonMotif
                  {...this.props}
                  motifs={this.props.personMotifs}
                  categories={this.props.personCategories}
                  adminDeleteMotif={this.props.adminDeletePersonMotif}
                />
              )}
            />
            <Route
              path="/admin/categories"
              render={(props) => (
                <AdminCategory
                  {...this.props}
                  categories={this.props.categories}
                  subcategories={this.props.subcategories}
                  adminUpdateCategoryOrder={this.props.adminUpdateCategoryOrder}
                  adminCreateCategory={this.props.adminCreateCategory}
                  adminDeleteCategory={this.props.adminDeleteCategory}
                  adminUpdateCategory={this.props.adminUpdateCategory}
                  adminAddSubcategory={this.props.adminAddSubcategory}
                  adminDeleteSubcategory={this.props.adminDeleteSubcategory}
                  adminUpdateSubcategory={this.props.adminUpdateSubcategory}
                />
              )}
            />
            <Route
              path="/admin/personcategories"
              render={(props) => (
                <AdminPersonCategory
                  {...this.props}
                  categories={this.props.personCategories}
                  adminCreateCategory={this.props.adminCreatePersonCategory}
                  adminDeleteCategory={this.props.adminDeletePersonCategory}
                  adminUpdateCategory={this.props.adminUpdatePersonCategory}
                />
              )}
            />
            <Route
              path="/admin/restrictions"
              render={(props) => (
                <AdminRestriction
                  {...this.props}
                  restrictions={this.props.restrictions}
                  categories={this.props.categories}
                  subcategories={this.props.subcategories}
                  adminCreateRestriction={this.props.adminCreateRestriction}
                  adminDeleteRestriction={this.props.adminDeleteRestriction}
                  adminRequestRestrictions={this.props.adminRequestRestrictions}
                />
              )}
            />
            <Route
              path="/admin/submitted"
              render={(props) => <AdminSubmittedBelts {...this.props} />}
            />
            <Route
              path="/admin/drafts"
              render={(props) => <AdminDraftedBelts {...this.props} />}
            />
            <Route
              path="/admin/upload"
              render={(props) => <AdminUpload {...this.props} />}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
