import React, { Component } from "react";
import "../../styles/libraryIdea.scss";
import Library from "./Library";
import IdeaBoxContainer from "../../containers/IdeaBoxContainer";
import Popup from "./Popup";
import pick from "lodash/pick";

class LibraryIdea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themeFilter: "",
      searchFilter: "",
      searchMotifs: [],
      library: {
        Motifs: {byId: {}, allIds: []},
        Categories: {byId: {}, allIds: []},
        Subcategories: {byId: {}, allIds: []},
      },
      motifStringsById: {},
      searching: false
    }

    this.clearIdeaBox = this.clearIdeaBox.bind(this);
    this.handleThemeChange = this.handleThemeChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
  }

  componentDidMount() {
    var motifStrings = {};
    Object.keys(this.props.library.Motifs.byId).map(key => {

      // Get category and subcategory string
      var categoryString = this.props.library.Motifs.byId[key].subcategories.map(id => {
        return this.props.library.Subcategories.byId[id].name + this.props.library.Categories.byId[this.props.library.Subcategories.byId[id].CategoryId].name;
      }).join("");

      // Get motif string
      motifStrings[key] = Object.values(pick(this.props.library.Motifs.byId[key], ['name', 'searchTags'])).join("").toLowerCase() + categoryString.toLowerCase();

      return null;
    });

    this.setState({
      library: this.props.library,
      motifStringsById: motifStrings
    });
  }

  clearIdeaBox() {
    let beltMotifs = [...new Set(this.props.belt.motifs.map(x => x[0]))];
    if (beltMotifs.length > 0) {
      this.props.setNotification("Belt motifs were left in your Idea Box");
    }
    this.props.clearIdeaBox(beltMotifs);
  }

  handleThemeChange(e) {
    if (e.target.value === "viewall") {
      this.handleClearFilters();
    } else {
      this.setState({
        searchFilter: "",
        themeFilter: e.target.value,
        library: {
          ...this.state.library,
          Categories: {
            ...this.state.library.Categories,
            allIds: [e.target.value]
          }
        },
        searchMotifs: [],
        searching: false
      });
    }
  }

  handleSearchChange(e) {
    if (e.target.value === "") {
      this.setState({
        searchFilter: "",
        themeFilter: "",
        library: this.props.library,
        searchMotifs: [],
        searching: false
      });
    } else {
      // Filter motifs based on search term
      var matchedMotifs = [];
      Object.keys(this.state.motifStringsById).forEach(key => {
        if (this.state.motifStringsById[key].indexOf(e.target.value.toLowerCase()) !== -1) {
          matchedMotifs.push(key);
          return;
        } else {
          return;
        }
      });

      this.setState({
        themeFilter: "",
        searchFilter: e.target.value,
        searchMotifs: matchedMotifs,
        searching: true
      });
    }
  }

  handleClearFilters() {
    this.setState({
      searchFilter: "",
      themeFilter: "",
      library: this.props.library,
      searchMotifs: [],
      searching: false
    });
  }
  
  buildCategoryOptions() {
    return this.props.library.Categories.allIds.map(x => {
      return (
        <option key={"category-option-" + x} value={x} >{this.props.library.Categories.byId[x].name}</option>
      );
    });
  }

  render() {
    return (
      <div id="library-idea-wrapper">
        <div id="library-idea-toolbar">
          <div className="container">
            <div id="library-idea-toolbar-container">
              <div id="library-toolbar">
                <h4>Motif Library</h4>
                <Popup popupOrder={1} top={"-175px"} location={"top"} />
                <div>
                  <select className="form-control" value={this.state.themeFilter} onChange={this.handleThemeChange}>
                    <option value="" disabled>Browse by theme</option>
                    <option value="viewall">View all</option>
                    <option value="" disabled>-------------------</option>
                    {this.buildCategoryOptions()}
                  </select>
                  <input className="form-control" placeholder="Search motifs ..." value={this.state.searchFilter} onChange={this.handleSearchChange} />
                  { this.state.themeFilter !== "" || this.state.searchFilter !== "" ? <button className="btn reset-button" onClick={this.handleClearFilters}>Reset</button> : null }
                </div>
              </div>
              <div id="idea-box-toolbar">
                <h4>Idea Box</h4>
                { this.props.ideaBox.motifs.length > 0 ? <button className="btn reset-button clear" onClick={this.clearIdeaBox}>Clear</button> : null }
              </div>
            </div>
          </div>
        </div>
        <div id="library-idea">
          <div className="container">
            <div id="library-idea-container">
              <Library library={this.state.library}
                       searchMotifs={this.state.searchMotifs}
                       searching={this.state.searching}
                       addMotifToBelt={this.props.addMotifToBelt}
                       addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                       ideaBox={this.props.ideaBox}
                       belt={this.props.belt}
                       restrictions={this.props.restrictions}
                       setNotification={this.props.setNotification}
                       showPremiumModal={this.props.showPremiumModal}
                       showPersonalizationModal={this.props.showPersonalizationModal}
                       sendAnalyticsEvent={this.props.sendAnalyticsEvent} />
              
              <IdeaBoxContainer addMotifToBelt={this.props.addMotifToBelt} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LibraryIdea;
