const getDateCompoundConfigs = (compounds, type) => {
  let configs = [];
  let width = 0;
  let height = 0;

  const keyArr = Object.keys(compounds);

  const findMaxWidth = () => {
    keyArr.forEach((pos) => {
      Object.keys(compounds[pos])
        .filter((key) => key === "width")
        .forEach((key) => {
          if (compounds[pos][key] > width) {
            width = compounds[pos][key];
          }
        });
    });    
  };

  const findNewPosition = (padTop = 1, padLeft = 0) => {
    keyArr.forEach((key) => {
        compounds[key].pixelConfigs.forEach((item) => {
        
          configs.push({
            ...item,
            pos: [
              item.pos[0] + Math.round((width - compounds[key].width) / 2) + (key === "top" ? padLeft : 0),
              item.pos[1] + height,
            ],
            color: item.color,
          });
        });
        height = height + compounds[key].height + padTop;
      });
      height = height - padTop
  };

  if (type === "weddingRingsWithDate") {
    findMaxWidth();
    findNewPosition(0, 1);
  } else if (type === "initialsWithDate") {
    findMaxWidth();
    findNewPosition(3);
  }
 
  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getDateCompoundConfigs;
