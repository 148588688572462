import { authHeader, handleResponse } from "../../helpers/index.js";

export const ADMIN_RECEIVE_MOTIFS = "ADMIN_RECEIVE_MOTIFS";
export const ADMIN_CREATE_MOTIF_SUCCESS = "ADMIN_CREATE_MOTIF_SUCCESS";
export const ADMIN_UPDATE_MOTIF_SUCCESS = "ADMIN_UPDATE_MOTIF_SUCCESS";
export const ADMIN_DELETE_MOTIF_SUCCESS = "ADMIN_DELETE_MOTIF_SUCCESS";

export const ADMIN_RECEIVE_PERSON_MOTIFS = "ADMIN_RECEIVE_PERSON_MOTIFS";
export const ADMIN_CREATE_PERSON_MOTIF_SUCCESS = "ADMIN_CREATE_PERSON_MOTIF_SUCCESS";
export const ADMIN_UPDATE_PERSON_MOTIF_SUCCESS = "ADMIN_UPDATE_PERSON_MOTIF_SUCCESS";
export const ADMIN_DELETE_PERSON_MOTIF_SUCCESS = "ADMIN_DELETE_PERSON_MOTIF_SUCCESS";

export function adminRequestMotifs() {
  return function (dispatch) {
    return fetch("/api/admin/motifs", {
      headers: authHeader(),
    })
      .then((res) => handleResponse(res, dispatch))
      .then((json) => {
        if (json) {
          dispatch(adminReceiveMotifs(json));
        }
      });
  };
}

export function adminRequestPersonMotifs() {
  return function (dispatch) {
    return fetch("/api/admin/personmotifs", {
      headers: authHeader(),
    })
      .then((res) => handleResponse(res, dispatch))
      .then((json) => {
        if (json) {
          dispatch(adminReceivePersonMotifs(json));
        }
      });
  };
}

export function adminReceiveMotifs(motifs) {    
  return {
    type: ADMIN_RECEIVE_MOTIFS,
    payload: motifs,
  };
}

export function adminReceivePersonMotifs(motifs) {    
  return {
    type: ADMIN_RECEIVE_PERSON_MOTIFS,
    payload: motifs,
  };
}

export function adminCreateMotif(motif) {
  return function (dispatch) {
    return fetch("/api/admin/motifs", {
      method: "POST",
      body: motif,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((motif) => {
        if (motif) {
          dispatch(adminCreateMotifSuccess(motif));
        }
      });
  };
}

export function adminCreatePersonMotif(motif) {
  return function (dispatch) {
    return fetch("/api/admin/personmotifs", {
      method: "POST",
      body: motif,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((motif) => {
        if (motif) {
          dispatch(adminCreatePersonMotifSuccess(motif));
        }
      });
  };
}

export function adminCreateMotifSuccess(motif) {
  return {
    type: ADMIN_CREATE_MOTIF_SUCCESS,
    payload: motif,
  };
}

export function adminCreatePersonMotifSuccess(motif) {
  return {
    type: ADMIN_CREATE_PERSON_MOTIF_SUCCESS,
    payload: motif,
  };
}

export function adminUpdateMotif(updatedMotif, motifId) {
  return function (dispatch) {
    return fetch("/api/admin/motifs/" + motifId + "/edit", {
      method: "PATCH",
      body: updatedMotif,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((updatedMotif) => {
        if (updatedMotif) {
          dispatch(adminUpdateMotifSuccess(updatedMotif));
        }
      });
  };
}

export function adminUpdatePersonMotif(updatedMotif, motifId) {
  return function (dispatch) {
    return fetch("/api/admin/personmotifs/" + motifId + "/edit", {
      method: "PATCH",
      body: updatedMotif,
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((updatedMotif) => {
        if (updatedMotif) {
          dispatch(adminUpdatePersonMotifSuccess(updatedMotif));
        }
      });
  };
}

export function adminUpdateMotifSuccess(updatedMotif) {
  return {
    type: ADMIN_UPDATE_MOTIF_SUCCESS,
    payload: updatedMotif,
  };
}

export function adminUpdatePersonMotifSuccess(updatedMotif) {
  return {
    type: ADMIN_UPDATE_PERSON_MOTIF_SUCCESS,
    payload: updatedMotif,
  };
}

export function adminDeleteMotif(motifId) {
  return function (dispatch) {
    return fetch("/api/admin/motifs/" + motifId, {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((deletedId) => {
        if (deletedId) {
          dispatch(adminDeleteMotifSuccess(deletedId));
        }
      });
  };
}

export function adminDeleteMotifSuccess(motifId) {
  return {
    type: ADMIN_DELETE_MOTIF_SUCCESS,
    payload: motifId,
  };
}

export function adminDeletePersonMotif(motifId) {
  return function (dispatch) {
    return fetch("/api/admin/personmotifs/" + motifId, {
      method: "DELETE",
      headers: authHeader(),
    })
      .then((res) => handleResponse(res))
      .then((deletedId) => {
        if (deletedId) {
          dispatch(adminDeletePersonMotifSuccess(deletedId));
        }
      });
  };
}

export function adminDeletePersonMotifSuccess(motifId) {
  return {
    type: ADMIN_DELETE_PERSON_MOTIF_SUCCESS,
    payload: motifId,
  };
}
