import React, { Component } from "react";
import "../../../styles/personalization.scss";

const InitialsRow = (props) => {
  return (
    <div>
      <h5>{props.viewName}</h5>
      <div className="radio-group">
        <p>Color</p>
        <div datatip="Pink">
          <input
            type="radio"
            className="initials-pink"
            checked={props.row.color === "#f994ae"}
            value="#f994ae"
            id={props.rowName + "pink"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "pink"}></label>
        </div>
        <div datatip="Blue">
          <input
            type="radio"
            className="initials-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "blue"}></label>
        </div>
        <div datatip="Mint">
          <input
            type="radio"
            className="initials-mint"
            checked={props.row.color === "#89dc97"}
            value="#89dc97"
            id={props.rowName + "mint"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "mint"}></label>
        </div>
      </div>
      <br />
      <div className="edit-flex">
        <input
          value={
            props.row.first.includes("R-")
              ? props.row.first.replace("R-", "")
              : props.row.first
          }
          onChange={(e) => props.change(e, props.rowName, "first")}
          onClick={() => props.clearInput(props.rowName, "first")}
        />
        <input
          value={
            props.row.second.includes("R-")
              ? props.row.second.replace("R-", "")
              : props.row.second
          }
          onChange={(e) => props.change(e, props.rowName, "second")}
          onClick={() => props.clearInput(props.rowName, "second")}
        />
        <input
          value={
            props.row.third.includes("R-")
              ? props.row.third.replace("R-", "")
              : props.row.third
          }
          onChange={(e) => props.change(e, props.rowName, "third")}
          onClick={() => props.clearInput(props.rowName, "third")}
        />
        <input
          value={
            props.row.fourth.includes("R-")
              ? props.row.fourth.replace("R-", "")
              : props.row.fourth
          }
          onChange={(e) => props.change(e, props.rowName, "fourth")}
          onClick={() => props.clearInput(props.rowName, "fourth")}
        />
      </div>
      <br />
      <div className="suffix-child">
        <p onClick={() => props.addSuffix(props.rowName, "R-Jr")}>Jr</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-II")}>II</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-III")}>III</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-IV")}>IV</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-V")}>V</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-VI")}>VI</p>
      </div>
    </div>
  );
};

class TwoStackedInitials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      bottom: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      error: null,
    };

    this.change = this.change.bind(this);
    this.addSuffix = this.addSuffix.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isLetter(str) {
    return (str.length === 1 && str.match(/[A-Z]/i)) || str === "";
  }

  componentDidMount() {
    const props = this.props.data.data;

    if (Object.keys(props.initials).length > 0) {
      this.setState({
        top: {
          first: props.initials.top.first,
          second: props.initials.top.second,
          third: props.initials.top.third,
          fourth: props.initials.top.fourth,
          color: props.initials.top.color,
        },
        bottom: {
          first: props.initials.bottom.first,
          second: props.initials.bottom.second,
          third: props.initials.bottom.third,
          fourth: props.initials.bottom.fourth,
          color: props.initials.bottom.color,
        },
      });
    }
  }

  change(e, name, current) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state)[name][current].split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      let newState = Object.assign({}, this.state);
      newState[name][current] = newLetter;
      newState.error = null;

      this.setState(newState);
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeColor(e, name) {
    const newState = Object.assign({}, this.state);
    newState[name].color = e.target.value;
    this.setState(newState);
  }

  addSuffix(name, suffix) {
    if (this.state[name].first === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          first: suffix,
        },
      });
    } else if (this.state[name].second === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          second: suffix,
        },
      });
    } else if (this.state[name].third === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          third: suffix,
        },
      });
    } else if (this.state[name].fourth === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          fourth: suffix,
        },
      });
    }
  }

  clearInput(name, current) {
    this.setState({
      [name]: {
        ...this.state[name],
        [current]: "",
      },
    });
  }

  onSubmit() {
    const initialsTop = Object.values(Object.assign({}, this.state).top);
    const initialsBottom = Object.values(Object.assign({}, this.state).bottom);

    initialsTop.shift();
    initialsBottom.shift();

    const submission = {
      type: "twoStackedInitials",
      data: {
        initials: {
          top: this.state.top,
          bottom: this.state.bottom,
        },
      },
      valid:
        initialsTop.filter((x) => x !== "").length >= 1 &&
        initialsTop.filter((x) => x !== "").length <= 4 &&
        initialsBottom.filter((x) => x !== "").length >= 1 &&
        initialsBottom.filter((x) => x !== "").length <= 4 &&
        this.state.top.color.length > 0 &&
        this.state.bottom.color.length > 0,
      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex scroll">
              <InitialsRow
                viewName="Top"
                row={this.state.top}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Bottom"
                row={this.state.bottom}
                rowName="bottom"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
            </div>
            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <div className="character-rules">
              <p>* To remove a character, please click the box</p>
              <p>* Click a suffix to add it to the next open box</p>
              <p>
                * 1-4 Characters Allowed in Each Set. Uppercase Letters and
                Suffixes Only.
              </p>
              <br />
              <br />
              <p>
                Please enter initials in First, Middle, Last Initial order. For
                example, Andrew Benjamin Carpenter's initials would be entered
                as ABC.
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default TwoStackedInitials;
