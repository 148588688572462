import React from 'react'
import { render } from 'react-dom'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Root from './components/Root'
import rootReducer from './reducers'
import { loadState, saveState } from './helpers/localStorage';

const persistedState = loadState();
const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk)
);

store.subscribe(() => {
  const { library, belt, userInfo, ideaBox, completedOrder, popup } = store.getState().generator;
  saveState({
    generator: {
      notification: { present: false,
                      text: null,
                      type: null },
      belt: belt,
      userInfo:  userInfo,
      ideaBox: ideaBox,
      library: library,
      completedOrder: completedOrder,
      popup: popup,
      time: Date.now()
    }
  });
});

render(<Root store={store} />, document.getElementById('root'))
