import { handleResponse } from "../../helpers/index.js";

export const REQUEST_LIBRARY_SUCCESS = "REQUEST_LIBRARY_SUCCESS";
export const REQUEST_PERSON_LIBRARY_SUCCESS = "REQUEST_PERSON_LIBRARY_SUCCESS";
export const REQUEST_RESTRICTIONS_SUCCESS = "REQUEST_RESTRICTIONS_SUCCESS";
export const ADD_MOTIF_TO_IDEA_BOX = "ADD_MOTIF_TO_IDEA_BOX";
export const REMOVE_MOTIF_FROM_IDEA_BOX = "REMOVE_MOTIF_FROM_IDEA_BOX";
export const REMOVE_MOTIF_FROM_BELT = "REMOVE_MOTIF_FROM_BELT";
export const ADD_USER_INFO = "ADD_USER_INFO";
export const ADD_MOTIF_TO_BELT = "ADD_MOTIF_TO_BELT";
export const MOVE_MOTIF_ON_BELT = "MOVE_MOTIF_ON_BELT";
export const LOAD_STATE_FROM_QUERY_SUCCESS = "LOAD_STATE_FROM_QUERY_SUCCESS";
export const CLEAR_BELT = "CLEAR_BELT";
export const REPLACE_BELT_MOTIFS_AND_RESIZE = "REPLACE_BELT_MOTIFS_AND_RESIZE";
export const SUBMIT_BELT_SUCCESS = "SUBMIT_BELT_SUCCESS";
export const SUBMIT_DRAFT_SUCCESS = "SUBMIT_DRAFT_SUCCESS";
export const RESET_COMPLETED_ORDER = "RESET_COMPLETED_ORDER";
export const NEXT_POPUP = "NEXT_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const UPDATE_MOTIF_ON_BELT = "UPDATE_MOTIF_ON_BELT";
export const CLEAR_IDEA_BOX = "CLEAR_IDEA_BOX";
export const START_SHARE_DATA = "START_SHARE_DATA";
export const SUCCESS_SHARE_DATA = "SUCCESS_SHARE_DATA";

export function requestLibrary() {
  return function (dispatch) {
    try {    
      return (
        fetch("/api/generator")
          // return fetch("http://localhost:5000/api/generator")
          .then((res) => {         
            return res.json();
          })
          .then((data) => {
            dispatch(requestLibrarySuccess(data));
          })
          .catch((err) => {
            console.log(err);
          })
      );
    } catch (e) {
      console.log(e);
    }
  };
}

//categories
export function requestLibrarySuccess(data) {
  let newData;
  if (data && data.length) {
    let newSubcategories;
    newData = data.map((category) => {
      if (category.Subcategories && category.Subcategories.length) {
        let newMotifs;
        newSubcategories = category.Subcategories.map((sub) => {
          if (sub.motifs && sub.motifs.length) {
            newMotifs = sub.motifs.map((motif) => {
              return {
                ...motif,
              };
            });
          } else {
            newMotifs = sub.motifs;
          }
          return {
            ...sub,
            motifs: newMotifs,
          };
        });
      } else {
        newSubcategories = category.Subcategories;
      }

      return {
        ...category,
        Subcategories: newSubcategories,
      };
    });
  }

  return {
    type: REQUEST_LIBRARY_SUCCESS,
    payload: newData,
  };
}

export function requestPersonLibrary() {
  return function (dispatch) {
    try {     
      return fetch("/api/generator/person")
        .then((res) => {        
          return res.json();
        })
        .then((data) => {
          dispatch(requestPersonLibrarySuccess(data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };
}

export function requestPersonLibrarySuccess(data) {  
  return {
    type: REQUEST_PERSON_LIBRARY_SUCCESS,
    payload: data,
  };
}

export function requestRestrictions() {
  return function (dispatch) {
    try {    
      return (
        fetch("/api/generator/restrictions")
          // return fetch("http://localhost:5000/api/generator/restrictions")
          .then((res) => res.json())
          .then((data) => {
            dispatch(requestRestrictionsSuccess(data));
          })
      );
    } catch (e) {
      console.log(e);
    }
  };
}

export function requestRestrictionsSuccess(data) {
  return {
    type: REQUEST_RESTRICTIONS_SUCCESS,
    payload: data,
  };
}

export function addMotifToIdeaBox(motifId) {
  return {
    type: ADD_MOTIF_TO_IDEA_BOX,
    payload: motifId,
  };
}

export function addUserInfo(userInfo) {  
  return {
    type: ADD_USER_INFO,
    payload: userInfo,
  };
}

export function addMotifToBelt(motifId, rand, personalizedData) {
 
  var motifData = personalizedData
    ? [motifId, rand, personalizedData]
    : [motifId, rand, null];
  return {
    type: ADD_MOTIF_TO_BELT,
    payload: motifData,
  };
}

export function removeMotifFromIdeaBox(motifIndex) {
  return {
    type: REMOVE_MOTIF_FROM_IDEA_BOX,
    payload: motifIndex,
  };
}

export function removeMotifFromBelt(motifIndex) {
  return {
    type: REMOVE_MOTIF_FROM_BELT,
    payload: motifIndex,
  };
}

export function moveMotifOnBelt(newBelt) {
  return {
    type: MOVE_MOTIF_ON_BELT,
    payload: newBelt,
  };
}

export function loadStateFromQuery(paramObj) {
  if (paramObj.share != null) {
    const uuid = paramObj.share;

    return function (dispatch) {
      return fetch("/api/generator/share?uuid=" + uuid)
        .then((res) => res.json())
        .then((data) => {
          dispatch(loadStateFromQuerySuccess(data));
        });
    };
  } else if (paramObj.completed != null) {
    const uuid = paramObj.completed;

    return function (dispatch) {
      return fetch("/api/generator/completed?uuid=" + uuid)
        .then((res) => res.json())
        .then((data) => {
          dispatch(loadStateFromQuerySuccess(data));
        });
    };
  }
}

export function loadStateFromQuerySuccess(data) {
  return {
    type: LOAD_STATE_FROM_QUERY_SUCCESS,
    payload: {
      beltData: data.beltData,
      ideaBox: data.ideaBox,
    },
  };
}

export function clearBelt() {
  return {
    type: CLEAR_BELT,
  };
}

export function replaceBeltMotifsAndResize(newMotifs, beltSize) {
  return {
    type: REPLACE_BELT_MOTIFS_AND_RESIZE,
    payload: {
      newMotifs: newMotifs,
      beltSize: beltSize,
    },
  };
}

export function submitBelt(submission) {

  return function (dispatch) {
    return fetch("/api/generator/belts", {
      method: "POST",
      body: JSON.stringify(submission),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": "fjf238fjas9if0saeifuw3h",
      },
    })
      .then((res) => handleResponse(res))
      .then((data) => {
        dispatch(submitBeltSuccess(data));
        // Set belt price, belt size, design number,
        // motif ids, first, last, email to submitted belt
        // (make sure this isn't stored in local storage)

        // clear belt data
        // clear local storage
      });
  };
}

export function submitBeltSuccess(submittedBelt) {
 
  return {
    type: SUBMIT_BELT_SUCCESS,
    payload: submittedBelt,
  };
}

export function submitDraft(submission) {
  return function (dispatch) {
    return fetch("/api/generator/belts/draft", {
      method: "POST",
      body: JSON.stringify(submission),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": "fjf238fjas9if0saeifuw3h",
      },
    })
      .then((res) => handleResponse(res))
      .then((data) => {
        dispatch(submitDraftSuccess(data));
        // Set belt price, belt size, design number,
        // motif ids, first, last, email to submitted belt
        // (make sure this isn't stored in local storage)

        // clear belt data
        // clear local storage
      });
  };
}

export function submitDraftSuccess(submittedDraft) {
  
  return {
    type: SUBMIT_DRAFT_SUCCESS,
    payload: submittedDraft,
  };
}

export function resetCompletedOrder() {
  return {
    type: RESET_COMPLETED_ORDER,
  };
}

export function nextPopup(index) {
  return {
    type: NEXT_POPUP,
    payload: index,
  };
}

export function closePopup() {
  return {
    type: CLOSE_POPUP,
  };
}

export function updateMotifOnBelt(motifId, motifRand, submission, svgConfig) {
  return {
    type: UPDATE_MOTIF_ON_BELT,
    payload: {
      motifId: motifId,
      motifRand: motifRand,
      submission: submission,
      svgConfig,
    },
  };
}

export function clearIdeaBox(motifsArr) {
  return {
    type: CLEAR_IDEA_BOX,
    payload: motifsArr,
  };
}

export function startShareData() {
  return {
    type: START_SHARE_DATA,
  };
}

export function successShareData(data) {
  return {
    type: SUCCESS_SHARE_DATA,
    payload: data.uuid,
  };
}

export function sendShareData(beltData, ideaBox) {
  return function (dispatch) {
    dispatch(startShareData());

    return fetch("/api/generator/share", {
      method: "POST",
      body: JSON.stringify({
        beltData: beltData,
        ideaBox: ideaBox,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": "fjf238fjas9if0saeifuw3h",
      },
    })
      .then((res) => handleResponse(res))
      .then((data) => {
        dispatch(successShareData(data));
      });
  };
}

export function sendAnalyticsEvent(motifId, type, data) {
  return function (dispatch) {
    return fetch("/api/generator/analytics", {
      method: "POST",
      body: JSON.stringify({
        motifId: motifId,
        type: type,
        data: data,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": "fjf238fjas9if0saeifuw3h",
      },
    })
      .then((res) => handleResponse(res))
      .then((data) => {});
  };
}

export function sendBulkAnalyticsEvent(motifs, type, data) {
  return function (dispatch) {
    return fetch("/api/generator/bulk-analytics", {
      method: "POST",
      body: JSON.stringify({
        motifs: motifs,
        type: type,
        data: data,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": "fjf238fjas9if0saeifuw3h",
      },
    })
      .then((res) => handleResponse(res))
      .then((data) => {});
  };
}
