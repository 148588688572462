const getDateCompoundConfigs = (compounds, type) => {
  let configs = [];
  let width = compounds.heart.width;
  let widthLetters = compounds.letters.width;
  let height = compounds.heart.height - 1;
  let padTop = 0;
  let padLeft =  Math.floor((width - widthLetters) / 2)

  const keyArr = Object.keys(compounds);
 
  keyArr.forEach((key) => {
    compounds[key].pixelConfigs.forEach((item) => {
      configs.push({
        ...item,
        pos: [
          item.pos[0] +
            (key === "letters" ? padLeft : 1),
          item.pos[1] + padTop,
        ],
        color: item.color,
      });
    });
    padTop = 4;
  });
 
  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getDateCompoundConfigs;
