import {
  REQUEST_LIBRARY_SUCCESS,
  REQUEST_PERSON_LIBRARY_SUCCESS,
  REQUEST_RESTRICTIONS_SUCCESS,
  ADD_MOTIF_TO_IDEA_BOX,
  REMOVE_MOTIF_FROM_IDEA_BOX,
  REMOVE_MOTIF_FROM_BELT,
  ADD_USER_INFO,
  ADD_MOTIF_TO_BELT,
  MOVE_MOTIF_ON_BELT,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  LOAD_STATE_FROM_QUERY_SUCCESS,
  CLEAR_BELT,
  REPLACE_BELT_MOTIFS_AND_RESIZE,
  SUBMIT_BELT_SUCCESS,
  SUBMIT_DRAFT_SUCCESS,
  RESET_COMPLETED_ORDER,
  NEXT_POPUP,
  CLOSE_POPUP,
  UPDATE_MOTIF_ON_BELT,
  CLEAR_IDEA_BOX,
  START_SHARE_DATA,
  SUCCESS_SHARE_DATA
} from '../actions';
import { normalize } from 'normalizr';
import { categoryList, personCategoryList} from '../schemas/index.js';

const defaultState = {
  library: {
    Motifs: {byId: {}, allIds: []},
    PersonMotifs: {byId: {}, allIds: []},
    Categories: {byId: {}, allIds: []},
    PersonCategories: {byId: {}, allIds: []},
    Subcategories: {byId: {}, allIds: []},
    Restrictions: []
  },
  ideaBox: {motifs: []},
  belt: {motifs: [], size: 36, hasSet: false},
  userInfo: null,
  notification: { present: false,
                  text: null,
                  type: null},
  completedOrder: null,
  popup: {
    index: 0,
    show: true
  },
  shareUUID: null
};

export default (state=defaultState, action) => {
  switch(action.type) {
    case  REQUEST_LIBRARY_SUCCESS:
      var normalizedLibraryData = normalize(action.payload, categoryList);
      return {
        ...state,
        library: {
          ...state.library,
          Motifs: {...state.library.Motifs, byId: normalizedLibraryData.entities.Motifs},
          Categories: {...state.library.Categories, byId: normalizedLibraryData.entities.Categories, allIds: normalizedLibraryData.result},
          Subcategories: {...state.library.Subcategories, byId: normalizedLibraryData.entities.Subcategories}
        }
      };
    case  REQUEST_PERSON_LIBRARY_SUCCESS:
      var normalizedPersonLibraryData = normalize(action.payload, personCategoryList);
     
      return {
        ...state,
        library: {
          ...state.library,
          PersonMotifs: {...state.library.PersonMotifs, byId: normalizedPersonLibraryData.entities.PersonMotifs},
          PersonCategories: {...state.library.PersonCategories, byId: normalizedPersonLibraryData.entities.PersonCategories, allIds: normalizedPersonLibraryData.result},         
        }
      };
    case REQUEST_RESTRICTIONS_SUCCESS:
      return {
        ...state,
        library: {
          ...state.library,
          Restrictions: action.payload
        }
      }
    case ADD_MOTIF_TO_IDEA_BOX:
      return {
        ...state,
        ideaBox: {
          ...state.ideaBox,
          motifs: [
            ...state.ideaBox.motifs,
            action.payload
          ]
        }
      }
    case MOVE_MOTIF_ON_BELT:
      return {
        ...state,
        belt: {
          ...state.belt,
          motifs: action.payload
        }
      }
    case REMOVE_MOTIF_FROM_IDEA_BOX:
      const newIdeaBox = Object.assign({}, state.ideaBox);
      newIdeaBox.motifs.splice(action.payload, 1);
      return {
        ...state,
        ideaBox: newIdeaBox
      }
    case REMOVE_MOTIF_FROM_BELT:
      const newBelt = Object.assign({}, state.belt);
      newBelt.motifs.splice(action.payload, 1);
      return {
        ...state,
        belt: newBelt
      }
      case ADD_USER_INFO:
        return {
          ...state,
          userInfo: {
            ...action.payload,
          },
        };
    case ADD_MOTIF_TO_BELT:
      return {
        ...state,
        belt: {
          ...state.belt,
          motifs: [
            ...state.belt.motifs,
            action.payload
          ]
        }
      }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          present: true,
          text: action.payload.text,
          type: action.payload.type
        }
      }
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          present: false,
          text: null,
          type: null
        }
      }
    case LOAD_STATE_FROM_QUERY_SUCCESS:
      var newIdeaBoxArr = [];
      var newMotifsArr = [];
      var count = 0;
      var errorNotification = defaultState.notification;

      action.payload.beltData.motifs.forEach((triple, i) => {
        if (state.library.Motifs.byId[triple[0]]) {
          newMotifsArr.push(triple);
        } else {
          count += 1;
        }
      })

      action.payload.ideaBox.forEach((id, i) => {
        if (state.library.Motifs.byId[id]) {
          newIdeaBoxArr.push(id);
        } else {
          count += 1;
        }
      })

      action.payload.beltData.motifs = newMotifsArr;

      if (count > 0) {
        errorNotification = {
          present: true,
          text: count + " motifs were removed from your Belt and Idea Box because they're no longer available.",
          type: "error"
        }
      }

      return {
        ...state,
        notification: errorNotification,
        belt: action.payload.beltData,
        ideaBox: {
          motifs: newIdeaBoxArr
        }
      }
    case CLEAR_BELT:
      return {
        ...state,
        belt: {
          ...state.belt,
          motifs: []
        }
      }
    case REPLACE_BELT_MOTIFS_AND_RESIZE:
      return {
        ...state,
        belt: {
          ...state.belt,
          size: action.payload.beltSize,
          motifs: action.payload.newMotifs,
          hasSet: true
        }
      }
    case SUBMIT_BELT_SUCCESS:
      // Set belt price, belt size, design number,
      // motif ids, first, last, email to submitted belt
      // (make sure this isn't stored in local storage)

      // On generator load (props incoming)
      // clear belt data
      // clear local storage
      return {
        ...state,
        belt: defaultState.belt,
        ideaBox: defaultState.ideaBox,
        completedOrder: {
          orderNumber: action.payload.orderNumber,
          user: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            phone: action.payload.phone
          },
          belt: {
            motifIds: action.payload.motifIds,
            price: action.payload.beltPrice,
            size: action.payload.beltSize
          }
        }
      }
    case SUBMIT_DRAFT_SUCCESS:
      // Set belt price, belt size, design number,
      // motif ids, first, last, email to submitted belt
      // (make sure this isn't stored in local storage)

      // On generator load (props incoming)
      // clear belt data
      // clear local storage
      return {
        ...state,
        belt: defaultState.belt,
        ideaBox: defaultState.ideaBox,
        completedOrder: {
          orderNumber: action.payload.orderNumber,
          user: {
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            email: action.payload.email,
            phone: action.payload.phone
          },
          belt: {
            motifIds: action.payload.motifIds,
            price: action.payload.beltPrice,
            size: action.payload.beltSize
          }
        }
      }
    case RESET_COMPLETED_ORDER:
      return {
        ...state,
        completedOrder: defaultState.completedOrder
      }
    case NEXT_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
          index: action.payload
        }
      }
    case CLOSE_POPUP:
      return {
        ...state,
        popup: {
          ...state.popup,
          show: false
        }
      }
    case UPDATE_MOTIF_ON_BELT:
      let personalizationNewBelt = Object.assign({}, state.belt);   
      const index = personalizationNewBelt.motifs.map(x => x.slice(0, 2).join("")).indexOf([action.payload.motifId, action.payload.motifRand].join(""));
      personalizationNewBelt.motifs[index] = [action.payload.motifId, action.payload.motifRand, action.payload.submission, action.payload.svgConfig]     
     
      return {
        ...state,
        belt: personalizationNewBelt
      }
    case CLEAR_IDEA_BOX:
      return {
        ...state,
        ideaBox: {
          motifs: action.payload
        }
      }
    case START_SHARE_DATA:
      return {
        ...state,
        shareUUID: null
      }
    case SUCCESS_SHARE_DATA:
      return {
        ...state,
        shareUUID: action.payload
      }
    default:
      return state;
  }
}
