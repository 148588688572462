import React, { Component } from "react";
import { CHUNK_SIZE_SVG } from "../../helpers/constants";
import initSVG from "../../helpers/customMotifs/initSVG";

const SelectedPersonMotif = (props) => {
  return (
    <div className="admin-motif-info-container">
      <div className="background" onClick={props.close}></div>
      <div>
        <h5>
          {props.motif.name}
          <svg
            // className="motif-thumbnail"
            version="1.1"
            baseProfile="full"
            xmlns="http://www.w3.org/2000/svg"
            height={22 * CHUNK_SIZE_SVG}
            viewBox={`0 0 ${(props.motif.width + 1) * CHUNK_SIZE_SVG} 88`}
          >
            {props.motif ? initSVG(props.motif).map((item) => item) : null}
          </svg>
        </h5>
        <p>
          <b>Name: </b>
          {props.motif.name}
        </p>
        <p>
          <b>Key: </b>
          {props.motif.key}
        </p>
        <p>
          <b>Category: </b>
          {/* {props.categories.byId[props.motif.personCategoryId].name} */}
        </p>
        <p>
          <b>Width: </b>
          {props.motif.width}
        </p>
        <p>
          <b>Height: </b>
          {props.motif.height}
        </p>
      </div>
    </div>
  );
};

class AdminPersonMotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motifs: [],
      filter: "",
      filterCategory: "",
      filteredMotifs: [],
      selectedMotif: null,
      selectedCategory: null,
    };
    this.routeChange = this.routeChange.bind(this);
    this.deleteMotif = this.deleteMotif.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      filter: "",
      motifs: this.props.motifs.allIds.map((id) => this.props.motifs.byId[id]),
      filteredMotifs: this.props.motifs.allIds.map(
        (id) => this.props.motifs.byId[id]
      ),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.motifs !== prevProps.motifs) {
      this.setState({
        filter: "",
        motifs: this.props.motifs.allIds.map(
          (id) => this.props.motifs.byId[id]
        ),
        filteredMotifs: this.props.motifs.allIds.map(
          (id) => this.props.motifs.byId[id]
        ),
      });
    }
  }

  routeChange(id) {
    let path = "/admin/personmotifs/" + id + "/edit";
    this.props.history.push(path);
  }

  handleFilterChange({ target }) {
  
    const { name, value } = target;
    if (value !== "" && name === "motif") {
      this.setState({
        filteredMotifs: this.state.motifs.filter(
          (motif) => motif.name.toLowerCase().indexOf(value.toLowerCase()) >= 0
        ),
        filter: value,
        filterCategory: "",
      });
    } else if (value !== "" && name === "category") {
      let filteredMotifs = this.state.motifs.filter((motif) => {
        let categ = this.props.categories.byId[motif.PersonCategoryId]
          ? this.props.categories.byId[motif.PersonCategoryId].name
          : "";
        return categ.toLowerCase().indexOf(value.toLowerCase()) >= 0;
      });
      this.setState({
        filteredMotifs: filteredMotifs,
        filterCategory: value,
        filter: "",
      });
    } else {
      this.setState({
        filteredMotifs: this.state.motifs,
        filter: value,
        filterCategory: value
      });
    }
  }

  deleteMotif(e, id) {
    e.preventDefault();
    this.props.adminDeleteMotif(id);
  }

  createMotifTableRows() {
    return this.state.filteredMotifs
      .filter((m) => m !== undefined)
      .map((motif) => {
        return (
          <tr
            key={motif.id}
            // onClick={() => this.setState({ selectedMotif: motif })}
          >
            <td style={{ textAlign: "center" }}>
              <svg
                // className="motif-thumbnail"
                version="1.1"
                baseProfile="full"
                xmlns="http://www.w3.org/2000/svg"
                height={22 * CHUNK_SIZE_SVG}
                viewBox={`0 0 ${(motif.width + 1) * CHUNK_SIZE_SVG} 88`}
              >
                {motif.pixelConfigs ? initSVG(motif).map((item) => item) : null}
              </svg>
            </td>
            <td>
              <p>{motif.id}</p>
            </td>
            <td>
              <p className="motif-name">{motif.name}</p>
            </td>
            <td>
              <p>{motif.key}</p>
            </td>
            <td>
              <p>
                {this.props.categories.byId[motif.PersonCategoryId]
                  ? this.props.categories.byId[motif.PersonCategoryId].name
                  : ""}
              </p>
            </td>
            <td>
              <p>{motif.width}</p>
            </td>
            <td>
              <p>{motif.height}</p>
            </td>
            <td>
              <p
                className="delete-personmotif"
                onClick={(e) => this.deleteMotif(e, motif.id)}
              >
                Delete
              </p>
            </td>
          </tr>
        );
      });
  }

  render() {
    return (
      <div id="admin-motifs">
        <div className="selected">
          <div>
            {this.state.selectedMotif ? (
              <SelectedPersonMotif
                motif={this.state.selectedMotif}
                categories={this.props.categories}
                close={() => this.setState({ selectedMotif: null })}
              />
            ) : null}
            <p className="results-count">
              {this.state.filteredMotifs.length} personalized motifs
            </p>
            <input
              name="motif"
              className="form-control"
              placeholder="Search Morif"
              value={this.state.filter}
              onChange={this.handleFilterChange}
            />
          </div>
          <div>
            {this.state.selectedCategory ? (
              <SelectedPersonMotif
                motif={this.state.selectedMotif}
                categories={this.props.categories}
                close={() => this.setState({ selectedMotif: null })}
              />
            ) : null}
            <p className="results-count">
              {this.state.filteredMotifs.length} personalized motifs
            </p>
            <input
              name="category"
              className="form-control"
              placeholder="Search Category"
              value={this.state.filterCategory}
              onChange={this.handleFilterChange}
            />
          </div>
        </div>

        <table className="motif-list">
          <thead>
            <tr>
              <th style={{ width: "100px", textAlign: "center" }}>Thumbnail</th>
              <th>Id</th>
              <th>Name</th>
              <th>Key</th>
              <th>Category</th>
              <th style={{ width: "100px" }}>Width</th>
              <th style={{ width: "100px" }}>Height</th>
              <th style={{ width: "75px" }}></th>
            </tr>
          </thead>
          <tbody>{this.createMotifTableRows()}</tbody>
        </table>
      </div>
    );
  }
}

export default AdminPersonMotif;
