import React, { Component } from 'react'

class EditSubcategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subName: "",
      visible: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {
    this.setState({
      subName: this.props.subcategory.name
    });
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    this.props.adminUpdateSubcategory({
      ...this.props.subcategory,
      name: this.state.subName
    });

    this.setState({
      visible: false
    });
  }

  toggleEdit() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <div>
        { this.state.visible ? (
            <div id="edit-subcategory">
              <input className="form-control" type="text" name="subName" value={this.state.subName} onChange={this.handleChange} />
              <button className="btn btn-secondary" onClick={this.handleSubmit}>Update Name</button>
            </div>
          ) : (
            <p className="toggle-edit" onClick={this.toggleEdit}>Edit</p>
          )
        }
      </div>
    )
  }
}

export default EditSubcategory;
