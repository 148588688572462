export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('sbg_s');
    if (serializedState === null) {
      return undefined;
    }
    var currentEpoch = Date.now();
    var jsonState = JSON.parse(serializedState);

    // 90 * 60 * 1000 = 1.5 hr local storage validity
    if (jsonState.generator && jsonState.generator.time && (Date.now() <= (jsonState.generator.time) + (90 * 60 * 1000))) {
      return jsonState;
    } else {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('sbg_s', serializedState);
  } catch (err) {
    // Ignore errors
  }
}
