import React, { Component } from "react";
import "../../../styles/personalization.scss";

const ColorRow = (props) => {
  return (
    <div>
      <div className="radio-group">
        <p>Choose Color</p>
        <div datatip="Light Blue">
          <input
            type="radio"
            className="initials-light-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "light-blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "light-blue"}></label>
        </div>
        <div datatip="Cornflower">
          <input
            type="radio"
            className="initials-cornflower"
            checked={props.row.color === "#5c95d4"}
            value="#5c95d4"
            id={props.rowName + "cornflower"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "cornflower"}></label>
        </div>
        <div datatip="Purple">
          <input
            type="radio"
            className="initials-purple"
            checked={props.row.color === "#7859c6"}
            value="#7859c6"
            id={props.rowName + "purple"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "purple"}></label>
        </div>
        <div datatip="Red">
          <input
            type="radio"
            className="initials-red"
            checked={props.row.color === "#bc0000"}
            value="#bc0000"
            id={props.rowName + "red"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "red"}></label>
        </div>
        <div datatip="Melon">
          <input
            type="radio"
            className="initials-melon"
            checked={props.row.color === "#f67668"}
            value="#f67668"
            id={props.rowName + "melon"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "melon"}></label>
        </div>
        <div datatip="White">
          <input
            type="radio"
            className="initials-white"
            checked={props.row.color === "#ffffff"}
            value="#ffffff"
            id={props.rowName + "white"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "white"}></label>
        </div>
      </div>
    </div>
  );
};
const ColorRow2 = (props) => {
  return (
    <div className="radio-group">
      <div>
        <div datatip="Kelly">
          <input
            type="radio"
            className="initials-kelly"
            checked={props.row.color === "#239a4f"}
            value="#239a4f"
            id={props.rowName + "kelly"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "kelly"}></label>
        </div>
        <div datatip="Moss">
          <input
            type="radio"
            className="initials-moss"
            checked={props.row.color === "#85bb64"}
            value="#85bb64"
            id={props.rowName + "moss"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "moss"}></label>
        </div>
        <div datatip="Light Garnet">
          <input
            type="radio"
            className="initials-lightGarnet"
            checked={props.row.color === "#980a0a"}
            value="#980a0a"
            id={props.rowName + "lightGarnet"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "lightGarnet"}></label>
        </div>
        <div datatip="Orange">
          <input
            type="radio"
            className="initials-orange"
            checked={props.row.color === "#f8750c"}
            value="#f8750c"
            id={props.rowName + "orange"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "orange"}></label>
        </div>
        <div datatip="Yellow">
          <input
            type="radio"
            className="initials-yellow"
            checked={props.row.color === "#fccb20"}
            value="#fccb20"
            id={props.rowName + "yellow"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "yellow"}></label>
        </div>
        <div datatip="Gold">
          <input
            type="radio"
            className="initials-gold"
            checked={props.row.color === "#e0be61"}
            value="#e0be61"
            id={props.rowName + "gold"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "gold"}></label>
        </div>
      </div>
    </div>
  );
};

class GreekInitials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      color: "",
      error: null,
    };

    this.changeFirst = this.changeFirst.bind(this);
    this.changeSecond = this.changeSecond.bind(this);
    this.changeThird = this.changeThird.bind(this);
    this.changeFourth = this.changeFourth.bind(this);
    this.changeFifth = this.changeFifth.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.addGreek = this.addGreek.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isGreek(str) {
    return (str && str.length === 1 && greek[str] !== undefined) || str === "";
  }

  componentDidMount() {
    this.first.focus();
    if (this.props.data.data.initials.length > 0) {
      this.setState({
        first: this.props.data.data.initials[0],
        second: this.props.data.data.initials[1],
        third: this.props.data.data.initials[2],
        fourth: this.props.data.data.initials[3],
        fifth: this.props.data.data.initials[4],
        color: this.props.data.data.color,
      });
    }
  }

  clearInput(name) {
    this.setState({
      [name]: "",
    });
  }

  addGreek(letter) {
    if (this.state.first === "") {
      this.setState({
        first: letter,
      });
    } else if (this.state.second === "") {
      this.setState({
        second: letter,
      });
    } else if (this.state.third === "") {
      this.setState({
        third: letter,
      });
    } else if (this.state.fourth === "") {
      this.setState({
        fourth: letter,
      });
    } else if (this.state.fifth === "") {
      this.setState({
        fifth: letter,
      });
    }
  }

  changeFirst(e) {
 
    const input = e.target.value.toUpperCase().split("");
  
    const state = Object.assign({}, this.state).first.split("");
   
    let newLetter = input.filter((n) => !state.includes(n)).join("");
  
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    
    }  
      this.setState({
        first: newLetter,
        error: null,
      });
      this.first.focus();  
  }

  changeSecond(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).second.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }
    this.setState({
      second: newLetter,
      error: null,
    });
    this.second.focus();
  }

  changeThird(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).third.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    this.setState({
      third: newLetter,
      error: null,
    });
    this.third.focus();
  }

  changeFourth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fourth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    this.setState({
      fourth: newLetter,
      error: null,
    });
    this.fourth.focus();
  }

  changeFifth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fifth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    this.setState({
      fifth: newLetter,
      error: null,
    });
    this.fifth.focus();
  }

  changeColor(e) {
    this.setState({ ...this.state, color: e.target.value });
  }

  onSubmit() {
    const initials = [
      this.state.first,
      this.state.second,
      this.state.third,
      this.state.fourth,
      this.state.fifth,
    ];
    const submission = {
      type: "greekInitials",
      data: {
        initials: initials,
        color: this.state.color,
      },
      valid:
        initials.filter((x) => x !== "").length >= 1 &&
        initials.filter((x) => x !== "").length <= 5 &&
        this.state.color !== "",

      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex scroll">
              <input
                ref={(first) => {
                  this.first = first;
                }}
                value={greek[this.state.first] ? greek[this.state.first] : ""}
                // onChange={this.changeFirst}
                onClick={() => this.clearInput("first")}
              />
              <input
                ref={(second) => {
                  this.second = second;
                }}
                value={greek[this.state.second] ? greek[this.state.second] : ""}
                onChange={this.changeSecond}
                onClick={() => this.clearInput("second")}
              />
              <input
                ref={(third) => {
                  this.third = third;
                }}
                value={greek[this.state.third] ? greek[this.state.third] : ""}
                onChange={this.changeThird}
                onClick={() => this.clearInput("third")}
              />
              <input
                ref={(fourth) => {
                  this.fourth = fourth;
                }}
                value={greek[this.state.fourth] ? greek[this.state.fourth] : ""}
                onChange={this.changeFourth}
                onClick={() => this.clearInput("fourth")}
              />
              <input
                ref={(fifth) => {
                  this.fifth = fifth;
                }}
                value={greek[this.state.fifth] ? greek[this.state.fifth] : ""}
                onChange={this.changeFifth}
                onClick={() => this.clearInput("fifth")}
              />

              <ColorRow
                row={this.state}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <br />
              <ColorRow2
                row={this.state}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <div className="chooseLetters">Choose Letters</div>
              <div className="initialsButtons">
                <div key="Alpha" onClick={() => this.addGreek("Alpha")}>
                  {greek["Alpha"]}
                </div>
                <div key="Beta" onClick={() => this.addGreek("Beta")}>
                  {greek["Beta"]}
                </div>
                <div key="Gamma" onClick={() => this.addGreek("Gamma")}>
                  {greek["Gamma"]}
                </div>
                <div key="Delta" onClick={() => this.addGreek("Delta")}>
                  {greek["Delta"]}
                </div>
                <div key="Epsilon" onClick={() => this.addGreek("Epsilon")}>
                  {greek["Epsilon"]}
                </div>
                <div key="Zeta" onClick={() => this.addGreek("Zeta")}>
                  {greek["Zeta"]}
                </div>
                <div key="Eta" onClick={() => this.addGreek("Eta")}>
                  {greek["Eta"]}
                </div>
                <div key="Theta" onClick={() => this.addGreek("Theta")}>
                  {greek["Theta"]}
                </div>
                <div key="Iota" onClick={() => this.addGreek("Iota")}>
                  {greek["Iota"]}
                </div>
                <div key="Kappa" onClick={() => this.addGreek("Kappa")}>
                  {greek["Kappa"]}
                </div>
                <div key="Lambda" onClick={() => this.addGreek("Lambda")}>
                  {greek["Lambda"]}
                </div>
                <div key="Mu" onClick={() => this.addGreek("Mu")}>
                  {greek["Mu"]}
                </div>
                <div key="Nu" onClick={() => this.addGreek("Nu")}>
                  {greek["Nu"]}
                </div>
                <div key="Xi" onClick={() => this.addGreek("Xi")}>
                  {greek["Xi"]}
                </div>
                <div key="Omicron" onClick={() => this.addGreek("Omicron")}>
                  {greek["Omicron"]}
                </div>
                <div key="Pi" onClick={() => this.addGreek("Pi")}>
                  {greek["Pi"]}
                </div>
                <div key="Rho" onClick={() => this.addGreek("Rho")}>
                  {greek["Rho"]}
                </div>
                <div key="Sigma" onClick={() => this.addGreek("Sigma")}>
                  {greek["Sigma"]}
                </div>
                <div key="Tau" onClick={() => this.addGreek("Tau")}>
                  {greek["Tau"]}
                </div>
                <div key="Upsilon" onClick={() => this.addGreek("Upsilon")}>
                  {greek["Upsilon"]}
                </div>
                <div key="Phi" onClick={() => this.addGreek("Phi")}>
                  {greek["Phi"]}
                </div>
                <div key="Chi" onClick={() => this.addGreek("Chi")}>
                  {greek["Chi"]}
                </div>
                <div key="Psi" onClick={() => this.addGreek("Psi")}>
                  {greek["Psi"]}
                </div>
                <div key="Omega" onClick={() => this.addGreek("Omega")}>
                  {greek["Omega"]}
                </div>
              </div>
            </div>

            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <br />
            <div className="character-rules">
              <p>* To remove a character, please click the box</p>
              <p>* Click a suffix to add it to the next open box</p>
              <p>1-5 Characters Allowed.</p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default GreekInitials;

const greek = {
  Alpha: "\u0391",
  Beta: "\u0392",
  Gamma: "\u0393",
  Delta: "\u0394",
  Epsilon: "\u0395",
  Zeta: "\u0396",
  Eta: "\u0397",
  Theta: "\u0398",
  Iota: "\u0399",
  Kappa: "\u039A",
  Lambda: "\u039B",
  Mu: "\u039C",
  Nu: "\u039D",
  Xi: "\u039E",
  Omicron: "\u039F",
  Pi: "\u03A0",
  Rho: "\u03A1",
  Sigma: "\u03A3",
  Tau: "\u03A4",
  Upsilon: "\u03A5",
  Phi: "\u03A6",
  Chi: "\u03A7",
  Psi: "\u03A8",
  Omega: "\u03A9",
};
