const getConfigs10px = (motifsArr = [], color, category) => {
  let topPadding = 0;
  let prevMotif;
  let prevPadding = 0;
  let padding = 1;
  let curLeftPad = 0;
  if (!color) {
    color = "#ffffff";
  }
  let configs = [];

  motifsArr.forEach((curMotif) => {
    if (
      category.toLowerCase() === "letters_10px" &&
      prevMotif &&
      prevMotif.key.toLowerCase() === "a" &&
      ["t", "v", "w"].includes(curMotif.key.toLowerCase())
    ) {
      prevPadding = -1;
    } else if (
      category.toLowerCase() === "letters_10px" &&
      prevMotif &&
      ["t", "v", "w"].includes(prevMotif.key.toLowerCase()) &&
      curMotif.key.toLowerCase() === "a"
    ) {
      prevPadding = -1;
    } else if (
      category.toLowerCase() === "letters_10px" &&
      prevMotif &&
      ["R-Jr", "R-II", "R-III", "R-IV", "R-V", "R-VI"].includes(curMotif.key)
    ) {
      prevPadding = 3;
    } else if (
      ["letters_5px", "letters_8px"].includes(category.toLowerCase()) &&
      prevMotif &&
      ["R-Jr", "R-II", "R-III", "R-IV", "R-V", "R-VI"].includes(curMotif.key)
    ) {
      prevPadding = 2;
    } else if (
      curMotif.key === "+" &&
      category.toLowerCase() === "letters_8px"
    ) {
      topPadding = 2;
    }

    if (curMotif && curMotif.pixelConfigs) {
      let curConfigs = curMotif.pixelConfigs;
      let minX = Infinity;
      let minY = Infinity;

      curConfigs.forEach((item) => {
        if (item.pos[0] < minX) {
          minX = item.pos[0];
        }
        if (item.pos[1] < minY) {
          minY = item.pos[1];
        }
      });
      let difX = minX - 1;
      let difY = minY - 1;

      curConfigs.forEach((item) => {
        let pos = [
          item.pos[0] - difX + curLeftPad + prevPadding,
          item.pos[1] - difY + topPadding,
        ];
        configs.push({
          ...item,
          pos,
          color: color,
        });
      });
    }

    curLeftPad =
      curLeftPad +
      (curMotif && curMotif.width ? curMotif.width : 0) +
      padding +
      prevPadding;

    prevPadding = 0;
    topPadding = 0;
    prevMotif = curMotif;
  });

  //compound width, height

  let minX = Infinity;
  let maxX = 0;
  let minY = Infinity;
  let maxY = 0;

  configs.forEach((el) => {
    if (el.pos[0] < minX) {
      minX = el.pos[0];
    }
    if (el.pos[0] > maxX) {
      maxX = el.pos[0];
    }
    if (el.pos[1] < minY) {
      minY = el.pos[1];
    }
    if (el.pos[1] > maxY) {
      maxY = el.pos[1];
    }
  });

  let width = maxX - minX + 1;
  let height = maxY - minY + 1;

  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getConfigs10px;
