import { authHeader, handleResponse } from '../../helpers/index.js';

export const ADMIN_RECEIVE_RESTRICTIONS = "ADMIN_RECEIVE_RESTRICTIONS";
export const ADMIN_CREATE_RESTRICTION_SUCCESS = "ADMIN_CREATE_RESTRICTION_SUCCESS";
export const ADMIN_DELETE_RESTRICTION_SUCCESS = "ADMIN_DELETE_RESTRICTION_SUCCESS";

export function adminRequestRestrictions() {
  return function(dispatch) {
    return fetch("/api/admin/restrictions", {
      headers: authHeader()
    })
      .then(res => handleResponse(res))
      .then(restrictions => {
        if (restrictions) {
          dispatch(adminRecieveRestrictions(restrictions))
        }
      });
  }
}

export function adminRecieveRestrictions(restrictions) {
  return {
    type: ADMIN_RECEIVE_RESTRICTIONS,
    payload: restrictions
  }
}

export function adminCreateRestriction(restriction) {
  return function(dispatch) {
    return fetch("/api/admin/restrictions", {
      method: "POST",
      body: JSON.stringify(restriction),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(restriction => {
      if (restriction) {
        dispatch(adminCreateRestrictionSuccess(restriction))
      }
    });
  }
}

export function adminCreateRestrictionSuccess(restriction) {
  return {
    type: ADMIN_CREATE_RESTRICTION_SUCCESS,
    payload: restriction
  }
}

export function adminDeleteRestriction(id) {
  return function(dispatch) {
    return fetch("/api/admin/restrictions/" + id, {
      method: "DELETE",
      headers: authHeader()
    })
    .then(res => handleResponse(res))
    .then(deletedId => {
      if (deletedId) {
        dispatch(adminDeleteRestrictionSuccess(deletedId))
      }
    })
  }
}

export function adminDeleteRestrictionSuccess(id) {
  return {
    type: ADMIN_DELETE_RESTRICTION_SUCCESS,
    payload: id
  }
}
