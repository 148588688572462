import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {  } from '../actions';
import Notifications from '../components/generator/Notifications';

const mapStateToProps = (state) => {
  return {
    notification: state.generator.notification
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch);
}

const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)

export default NotificationsContainer;
