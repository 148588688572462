import React, { Component } from 'react'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import NewCategory from './NewCategory';
import EditCategory from './EditCategory';
import EditSubcategory from './EditSubcategory';

const DragHandle = SortableHandle(() => {
  return (
    <svg id="drag-handle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" version="1.1" width="50px" height="50px">
      <g>
        <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z "/>
      </g>
    </svg>
  )
});

const SortableItem = SortableElement(({category, subcategories, deleteCategory, adminUpdateCategory, adminAddSubcategory, adminDeleteSubcategory, adminUpdateSubcategory}) => {
  return (
    <div className="category-container">
      <div className="category">
        <p>{category.name}</p>
        <EditCategory id={category.id} name={category.name} adminUpdateCategory={adminUpdateCategory} adminAddSubcategory={adminAddSubcategory} />
        <p className="delete" onClick={() => deleteCategory(category.id)} >Delete</p>
        <DragHandle />
      </div>
      <div className="subcategory-container">
        {category.Subcategories.length > 0 ? category.Subcategories.map(subcategoryId => {
          return (
            <div key={"sub-" + subcategories.byId[subcategoryId].id} className="subcategory">
              <p>{subcategories.byId[subcategoryId].name}</p>
              <EditSubcategory subcategory={subcategories.byId[subcategoryId]} adminUpdateSubcategory={adminUpdateSubcategory} />
              <p className="delete" onClick={() => adminDeleteSubcategory(subcategories.byId[subcategoryId])}>Delete</p>
            </div>
          )
        }) : null}
      </div>
    </div>
  )
});

const SortableList = SortableContainer(({ids, categories, subcategories, deleteCategory, adminUpdateCategory, adminAddSubcategory, adminDeleteSubcategory, adminUpdateSubcategory}) => {
  return (
    <div>
      {ids.map((id, index) => (
        <SortableItem key={categories.byId[id].id} index={index} category={categories.byId[id]} subcategories={subcategories} style={{zIndex: 100000}} deleteCategory={deleteCategory} adminUpdateCategory={adminUpdateCategory} adminAddSubcategory={adminAddSubcategory} adminDeleteSubcategory={adminDeleteSubcategory} adminUpdateSubcategory={adminUpdateSubcategory} />
      ))}
    </div>
  );
});

class AdminCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allIds: []
    }

    this.onSortEnd = this.onSortEnd.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
  }

  componentDidMount() {
    this.setState({
      allIds: this.props.categories.allIds
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.categories.allIds !== prevProps.categories.allIds) {
      this.setState({
        allIds: this.props.categories.allIds
      });
    }
  }

  onSortEnd({oldIndex, newIndex}) {
    var oldOrder = this.props.categories.allIds;
    var newOrder = arrayMove(oldOrder, oldIndex, newIndex);
    var categories = this.props.categories;

    var updatedCategories = newOrder.map((categoryId, index) => {
      return {...categories.byId[categoryId], order: index + 1}
    });

    this.props.adminUpdateCategoryOrder(updatedCategories);
    this.setState({
      allIds: newOrder
    });
  };

  deleteCategory(id) {
    this.props.adminDeleteCategory(id);
  }

  render() {
    return (
      <div id="admin-categories">
        <NewCategory adminCreateCategory={this.props.adminCreateCategory} />
        <hr />
        { this.state.allIds.length > 0 ? <SortableList useDragHandle={true} ids={this.state.allIds} categories={this.props.categories} subcategories={this.props.subcategories} onSortEnd={this.onSortEnd} deleteCategory={this.deleteCategory} adminUpdateCategory={this.props.adminUpdateCategory} adminAddSubcategory={this.props.adminAddSubcategory} adminDeleteSubcategory={this.props.adminDeleteSubcategory} adminUpdateSubcategory={this.props.adminUpdateSubcategory} /> : null}
      </div>
    )
  }
}

export default AdminCategory