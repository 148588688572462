import { authHeader, handleResponse } from '../../helpers/index.js';
import { adminRequestMotifs } from "./MotifActions.js";
import { adminRequestPersonMotifs } from "./MotifActions.js";
import { adminRequestRestrictions } from "./RestrictionActions.js";

export const ADMIN_RECEIVE_CATEGORIES = "ADMIN_RECEIVE_CATEGORIES";
export const ADMIN_UPDATE_CATEGORY_ORDER_SUCCESS = "ADMIN_UPDATE_CATEGORY_ORDER_SUCCESS";
export const ADMIN_CREATE_CATEGORY_SUCCESS = "ADMIN_CREATE_CATEGORY_SUCCESS";
export const ADMIN_UPDATE_CATEGORY_SUCCESS = "ADMIN_UPDATE_CATEGORY_SUCCESS";
export const ADMIN_DELETE_CATEGORY_SUCCESS = "ADMIN_DELETE_CATEGORY_SUCCESS";

export const ADMIN_RECEIVE_PERSON_CATEGORIES = "ADMIN_RECEIVE_PERSON_CATEGORIES";
export const ADMIN_CREATE_PERSON_CATEGORY_SUCCESS = "ADMIN_CREATE_PERSON_CATEGORY_SUCCESS";
export const ADMIN_UPDATE_PERSON_CATEGORY_SUCCESS = "ADMIN_UPDATE_PERSON_CATEGORY_SUCCESS";
export const ADMIN_DELETE_PERSON_CATEGORY_SUCCESS = "ADMIN_DELETE_PERSON_CATEGORY_SUCCESS";

export function adminRequestCategories() {
  return function(dispatch) {
    return fetch("/api/admin/categories", {
      headers: authHeader()
    })
      .then(res => handleResponse(res))
      .then(json => {
        if (json) {
          dispatch(adminReceiveCategories(json));
        }
      });
  }
}

export function adminReceiveCategories(categories) {
  return {
    type: ADMIN_RECEIVE_CATEGORIES,
    payload: categories
  }
}

export function adminUpdateCategoryOrder(categoryArr) {
  return function(dispatch) {
    return fetch("/api/admin/categories", {
      method: "PATCH",
      body: JSON.stringify(categoryArr),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(newCategoryArr => {
      if (newCategoryArr) {
        dispatch(adminUpdateCategoryOrderSuccess(newCategoryArr));
      }
    });
  }
}

export function adminUpdateCategoryOrderSuccess(newCategoryArr) {
  return {
    type: ADMIN_UPDATE_CATEGORY_ORDER_SUCCESS,
    payload: newCategoryArr
  }
}

export function adminCreateCategory(category) {
  return function(dispatch) {
    return fetch("/api/admin/categories", {
      method: "POST",
      body: JSON.stringify(category),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(newCategory => {
      if (newCategory) {
        dispatch(adminCreateCategorySuccess(newCategory));
      }
    });
  }
}

export function adminCreateCategorySuccess(category) {
  return {
    type: ADMIN_CREATE_CATEGORY_SUCCESS,
    payload: category
  }
}

export function adminUpdateCategory(id, newName) {
  return function(dispatch) {
    return fetch("/api/admin/categories/" + id, {
      method: "PATCH",
      body: JSON.stringify({name: newName}),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(category => {
      if (category) {
        dispatch(adminUpdateCategorySuccess(category));
      }
    });
  }
}

export function adminUpdateCategorySuccess(category) {
  return {
    type: ADMIN_UPDATE_CATEGORY_SUCCESS,
    payload: category
  }
}

export function adminDeleteCategory(id) {
  return function(dispatch) {
    return fetch("/api/admin/categories/" + id , {
      method: "DELETE",
      headers: authHeader()
    })
    .then(res => handleResponse(res))
    .then(deletedId => {
      if (deletedId) {
        dispatch(adminDeleteCategorySuccess(deletedId));
        dispatch(adminRequestMotifs());
        dispatch(adminRequestRestrictions());
      }
    });
  }
}

export function adminDeleteCategorySuccess(id) {
  return {
    type: ADMIN_DELETE_CATEGORY_SUCCESS,
    payload: id
  }
}


////////for personilized category

export function adminRequestPersonCategories() {
  return function(dispatch) {
    return fetch("/api/admin/personcategories", {
      headers: authHeader()
    })
      .then(res => handleResponse(res))
      .then(json => {
        if (json) {
          dispatch(adminReceivePersonCategories(json));
        }
      });
  }
}

export function adminReceivePersonCategories(categories) {
  return {
    type: ADMIN_RECEIVE_PERSON_CATEGORIES,
    payload: categories
  }
}

export function adminCreatePersonCategory(category) {
  return function(dispatch) {
    return fetch("/api/admin/personcategories", {
      method: "POST",
      body: JSON.stringify(category),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(newCategory => {
      if (newCategory) {
        dispatch(adminCreatePersonCategorySuccess(newCategory));
      }
    });
  }
}

export function adminCreatePersonCategorySuccess(category) {
  return {
    type: ADMIN_CREATE_CATEGORY_SUCCESS,
    payload: category
  }
}

export function adminUpdatePersonCategory(id, newName) {
  return function(dispatch) {
    return fetch("/api/admin/personcategories/" + id, {
      method: "PATCH",
      body: JSON.stringify({name: newName}),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(category => {
      if (category) {
        dispatch(adminUpdatePersonCategorySuccess(category));
      }
    });
  }
}

export function adminUpdatePersonCategorySuccess(category) {
  return {
    type: ADMIN_UPDATE_CATEGORY_SUCCESS,
    payload: category
  }
}

export function adminDeletePersonCategory(id) {
  return function(dispatch) {
    return fetch("/api/admin/personcategories/" + id , {
      method: "DELETE",
      headers: authHeader()
    })
    .then(res => handleResponse(res))
    .then(deletedId => {
      if (deletedId) {
        dispatch(adminDeletePersonCategorySuccess(deletedId));
        dispatch(adminRequestPersonMotifs());          
      }
    });
  }
}

export function adminDeletePersonCategorySuccess(id) {
  return {
    type: ADMIN_DELETE_CATEGORY_SUCCESS,
    payload: id
  }
}

