import React, { Component } from "react";
import "../../../styles/personalization.scss";

const InitialsRow = (props) => {
  return (
    <div>
      <h5>{props.viewName}</h5>
      <div className="radio-group">
        <p>Color</p>
        <div datatip="Pink">
          <input
            type="radio"
            className="initials-pink"
            checked={props.row.color === "#f994ae"}
            value="#f994ae"
            id={props.rowName + "pink"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "pink"}></label>
        </div>
        <div datatip="Blue">
          <input
            type="radio"
            className="initials-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "blue"}></label>
        </div>
        <div datatip="Mint">
          <input
            type="radio"
            className="initials-mint"
            checked={props.row.color === "#89dc97"}
            value="#89dc97"
            id={props.rowName + "mint"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "mint"}></label>
        </div>
      </div>
      <br />
      <div className="edit-flex">
        <input
          value={
            props.row.first.includes("R-")
              ? props.row.first.replace("R-", "")
              : props.row.first
          }
          onChange={(e) => props.change(e, props.rowName, "first")}
          onClick={() => props.clearInput(props.rowName, "first")}
        />
        <input
          value={props.row.second.includes("R-")
          ? props.row.second.replace("R-", "")
          : props.row.second}
          onChange={(e) => props.change(e, props.rowName, "second")}
          onClick={() => props.clearInput(props.rowName, "second")}
        />
        <input
          value={props.row.third.includes("R-")
          ? props.row.third.replace("R-", "")
          : props.row.third}
          onChange={(e) => props.change(e, props.rowName, "third")}
          onClick={() => props.clearInput(props.rowName, "third")}
        />
        <input
          value={props.row.fourth.includes("R-")
          ? props.row.fourth.replace("R-", "")
          : props.row.fourth}
          onChange={(e) => props.change(e, props.rowName, "fourth")}
          onClick={() => props.clearInput(props.rowName, "fourth")}
        />
      </div>
      <br />
      <div className="suffix-child">
        <p onClick={() => props.addSuffix(props.rowName, "R-Jr")}>Jr</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-II")}>II</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-III")}>III</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-IV")}>IV</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-V")}>V</p>
        <p onClick={() => props.addSuffix(props.rowName, "R-VI")}>VI</p>
      </div>
    </div>
  );
};

class SixStackedInitials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      topLeft: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      bottomLeft: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      topRight: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      bottomRight: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      middleLeft: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      middleRight: {
        color: "",
        first: "",
        second: "",
        third: "",
        fourth: "",
      },
      error: null,
    };

    this.change = this.change.bind(this);
    this.addSuffix = this.addSuffix.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isLetter(str) {
    return (str.length === 1 && str.match(/[A-Z]/i)) || str === "";
  }

  componentDidMount() {
    const props = this.props.data.data;

    if (Object.keys(props.initials).length > 0) {
      this.setState({
        topLeft: {
          first: props.initials.topLeft.first,
          second: props.initials.topLeft.second,
          third: props.initials.topLeft.third,
          fourth: props.initials.topLeft.fourth,
          color: props.initials.topLeft.color,
        },
        bottomLeft: {
          first: props.initials.bottomLeft.first,
          second: props.initials.bottomLeft.second,
          third: props.initials.bottomLeft.third,
          fourth: props.initials.bottomLeft.fourth,
          color: props.initials.bottomLeft.color,
        },
        topRight: {
          first: props.initials.topRight.first,
          second: props.initials.topRight.second,
          third: props.initials.topRight.third,
          fourth: props.initials.topRight.fourth,
          color: props.initials.topRight.color,
        },
        bottomRight: {
          first: props.initials.bottomRight.first,
          second: props.initials.bottomRight.second,
          third: props.initials.bottomRight.third,
          fourth: props.initials.bottomRight.fourth,
          color: props.initials.bottomRight.color,
        },
        middleRight: {
          first: props.initials.middleRight.first,
          second: props.initials.middleRight.second,
          third: props.initials.middleRight.third,
          fourth: props.initials.middleRight.fourth,
          color: props.initials.middleRight.color,
        },
        middleLeft: {
          first: props.initials.middleLeft.first,
          second: props.initials.middleLeft.second,
          third: props.initials.middleLeft.third,
          fourth: props.initials.middleLeft.fourth,
          color: props.initials.middleLeft.color,
        },
      });
    }
  }

  change(e, name, current) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state)[name][current].split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      let newState = Object.assign({}, this.state);
      newState[name][current] = newLetter;
      newState.error = null;

      this.setState(newState);
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeColor(e, name) {
    const newState = Object.assign({}, this.state);
    newState[name].color = e.target.value;
    this.setState(newState);
  }

  addSuffix(name, suffix) {
    if (this.state[name].first === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          first: suffix,
        },
      });
    } else if (this.state[name].second === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          second: suffix,
        },
      });
    } else if (this.state[name].third === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          third: suffix,
        },
      });
    } else if (this.state[name].fourth === "") {
      this.setState({
        [name]: {
          ...this.state[name],
          fourth: suffix,
        },
      });
    }
  }

  clearInput(name, current) {
    this.setState({
      [name]: {
        ...this.state[name],
        [current]: "",
      },
    });
  }

  onSubmit() {
    const initialsTopLeft = Object.values(
      Object.assign({}, this.state).topLeft
    );
    const initialsBottomLeft = Object.values(
      Object.assign({}, this.state).bottomLeft
    );
    const initialsTopRight = Object.values(
      Object.assign({}, this.state).topRight
    );
    const initialsBottomRight = Object.values(
      Object.assign({}, this.state).bottomRight
    );
    const initialsMiddleLeft = Object.values(
      Object.assign({}, this.state).middleLeft
    );
    const initialsMiddleRight = Object.values(
      Object.assign({}, this.state).middleRight
    );

    initialsTopLeft.shift();
    initialsBottomLeft.shift();
    initialsTopRight.shift();
    initialsBottomRight.shift();
    initialsMiddleLeft.shift();
    initialsMiddleRight.shift();

    const submission = {
      type: "sixStackedInitials",
      data: {
        initials: {
          topLeft: this.state.topLeft,
          topRight: this.state.topRight,
          middleLeft: this.state.middleLeft,
          middleRight: this.state.middleRight,
          bottomLeft: this.state.bottomLeft,
          bottomRight: this.state.bottomRight,
        },
      },
      valid:
        initialsTopLeft.filter((x) => x !== "").length >= 1 &&
        initialsTopLeft.filter((x) => x !== "").length <= 4 &&
        initialsBottomLeft.filter((x) => x !== "").length >= 1 &&
        initialsBottomLeft.filter((x) => x !== "").length <= 4 &&
        initialsTopRight.filter((x) => x !== "").length >= 1 &&
        initialsTopRight.filter((x) => x !== "").length <= 4 &&
        initialsBottomRight.filter((x) => x !== "").length >= 1 &&
        initialsBottomRight.filter((x) => x !== "").length <= 4 &&
        initialsMiddleLeft.filter((x) => x !== "").length >= 1 &&
        initialsMiddleLeft.filter((x) => x !== "").length <= 4 &&
        initialsMiddleRight.filter((x) => x !== "").length >= 1 &&
        initialsMiddleRight.filter((x) => x !== "").length <= 4 &&
        this.state.topRight.color.length > 0 &&
        this.state.bottomRight.color.length > 0 &&
        this.state.topLeft.color.length > 0 &&
        this.state.bottomLeft.color.length > 0 &&
        this.state.middleLeft.color.length > 0 &&
        this.state.middleRight.color.length > 0,
      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex scroll">
              <InitialsRow
                viewName="Top Left"
                row={this.state.topLeft}
                rowName="topLeft"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Top Right"
                row={this.state.topRight}
                rowName="topRight"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Middle Left"
                row={this.state.middleLeft}
                rowName="middleLeft"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Middle Right"
                row={this.state.middleRight}
                rowName="middleRight"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Bottom Left"
                row={this.state.bottomLeft}
                rowName="bottomLeft"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <InitialsRow
                viewName="Bottom Right"
                row={this.state.bottomRight}
                rowName="bottomRight"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
            </div>
            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <div className="character-rules">
              <p>* To remove a character, please click the box</p>
              <p>* Click a suffix to add it to the next open box</p>
              <p>
                * 1-4 Characters Allowed in Each Set. Uppercase Letters and
                Suffixes Only.
              </p>
              <br />
              <br />
              <p>
                Please enter initials in First, Middle, Last Initial order. For
                example, Andrew Benjamin Carpenter's initials would be entered
                as ABC.
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default SixStackedInitials;
