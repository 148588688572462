import { authHeader, handleResponse } from '../../helpers/index.js';
import { adminRequestMotifs } from "./MotifActions.js";
import { adminRequestRestrictions } from "./RestrictionActions.js";

export const ADMIN_ADD_SUBCATEGORY_SUCCESS = "ADMIN_ADD_SUBCATEGORY_SUCCESS";
export const ADMIN_UPDATE_SUBCATEGORY_SUCCESS = "ADMIN_UPDATE_SUBCATEGORY_SUCCESS";
export const ADMIN_DELETE_SUBCATEGORY_SUCCESS = "ADMIN_DELETE_SUBCATEGORY_SUCCESS";

export function adminAddSubcategory(id, name) {
  return function(dispatch) {
    return fetch("/api/admin/categories/" + id + "/subcategory", {
      method: "POST",
      body: JSON.stringify({name: name}),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(subcategory => {
      if (subcategory) {
        dispatch(adminAddSubcategorySuccess(subcategory));
      }
    });
  }
}

export function adminAddSubcategorySuccess(subcategory) {
  return {
    type: ADMIN_ADD_SUBCATEGORY_SUCCESS,
    payload: subcategory
  }
}

export function adminUpdateSubcategory(subcategory) {
  return function(dispatch) {
    return fetch("/api/admin/categories/" + subcategory.CategoryId + "/subcategory/" + subcategory.id, {
      method: "PATCH",
      body: JSON.stringify(subcategory),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(subcategory => {
      if (subcategory) {
        dispatch(adminUpdateSubcategorySuccess(subcategory));
      }
    });
  }
}

export function adminUpdateSubcategorySuccess(subcategory) {
  return {
    type: ADMIN_UPDATE_SUBCATEGORY_SUCCESS,
    payload: subcategory
  }
}

export function adminDeleteSubcategory(subcategory) {
  return function(dispatch) {
    return fetch("/api/admin/categories/" + subcategory.CategoryId + "/subcategory/" + subcategory.id, {
      method: "DELETE",
      headers: authHeader()
    })
    .then(res => handleResponse(res))
    .then(deletedId => {
      if (deletedId) {
        dispatch(adminDeleteSubcategorySuccess(deletedId));
        dispatch(adminRequestMotifs());
        dispatch(adminRequestRestrictions());
      }
    })
  }
}

export function adminDeleteSubcategorySuccess(id) {
  return {
    type: ADMIN_DELETE_SUBCATEGORY_SUCCESS,
    payload: id
  }
}
