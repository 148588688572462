import React, { useEffect, useRef, useState } from "react";

const CHUNK_SIZE = 4;

const getPngFromSvg = (svgConfig) => {
  const canvas = document.createElement("canvas");
  canvas.width = (svgConfig.width + 1) * CHUNK_SIZE;
  canvas.height = 88;  
  const context2D = canvas.getContext("2d");
 
  let paddingTop = 0;

    switch (svgConfig.height) {
      case 22:
      case 21:
      case 20:
        paddingTop = 0;
        break;
      case 19:
      case 18:
        paddingTop = 1;
        break;
      case 17:
      case 16:
        paddingTop = 2;
        break;
      case 15:
      case 14:
        paddingTop = 3;
        break;
      case 13:
      case 12:
        paddingTop = 4;
        break;
      case 11:
      case 10:
        paddingTop = 5;
        break;
      case 9:
      case 8:
        paddingTop = 6;
        break;
      case 7:
      case 6:
        paddingTop = 7;
        break;
      case 5:
      case 4:
        paddingTop = 8;
        break;

      default:
        paddingTop = 0;
        break;
    }   

  if (svgConfig.pixelConfigs) {  
    svgConfig.pixelConfigs.forEach((item) => {
      context2D.beginPath();
      context2D.rect(
        (item.pos[0] - 1) * CHUNK_SIZE,
        (item.pos[1] + paddingTop) * CHUNK_SIZE,
        CHUNK_SIZE,
        CHUNK_SIZE
      );
      context2D.fillStyle = item.color;
      context2D.fill();
    });
  }
  
  return canvas.toDataURL("image/png");

};

export default getPngFromSvg;
