import React, { Component } from "react";

class AdminUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      motifs: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitTXT = this.onSubmitTXT.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onMultiSubmit = this.onMultiSubmit.bind(this);
    this.onMultiChange = this.onMultiChange.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  }

  onMultiChange(e) {
    this.setState({
      [e.target.name]: e.target.files,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const newState = Object.assign({}, this.state);
    const data = new FormData();

    data.append("file", newState.file);

    this.props.adminUploadCSV(data);
    this.props.history.push("/admin/motifs");
  }

  onSubmitTXT(e, isMotif) {
    e.preventDefault();
   
    const newState = Object.assign({}, this.state);
    const data = new FormData();
  
    data.append("file", newState.file);
  
    this.props.adminUploadTXT(data, isMotif);
    this.props.history.push("/admin/motifs");
  }

  onMultiSubmit(e) {
    e.preventDefault();
    const newState = Object.assign({}, this.state);
    const data = new FormData();

    Object.keys(newState.motifs).forEach((x) => {
      data.append("file", newState.motifs[x]);
    });

    this.props.adminUploadMotifImages(data);
  }

  render() {
    return (
      <div id="admin-upload">
        <div className="row">
          <div className="col">
            <h5>Instructions</h5>
            <ol>
              <li>
                Upload any motif image files <b>before</b> uploading the
                modified CSV
              </li>
              <li>
                Wait for 5-10 seconds after uploading to allow the files to
                propogate
              </li>
              <li>Reload the page</li>
              <li>
                Upload the motif csv (you only need to upload the relevant
                "sheet"; e.g. library, licenced library, etc.)
              </li>
              <li>Wait for 5-10 seconds after uploading</li>
              <li>Reload the page</li>
              <li>Use the search to check that the motifs were updated</li>
            </ol>

            <p>
              <i>
                * When overwriting images, you will have to wait 1 hour for
                image caches to clear so you can see the new image in the app.
              </i>
            </p>
            <p>
              <i>
                * Image file names must match filenames specified in the motif
                CSV
              </i>
            </p>
          </div>
        </div>

        <hr />
        <br />

        <div className="row">
          <div className="col-6">
            <h5>Upload Motif Images</h5>
            <p>Choose multiple motif image files to upload (max 20)</p>
            <form onSubmit={this.onMultiSubmit}>
              <div className="form-group">
                <input
                  multiple={true}
                  type="file"
                  name="motifs"
                  id="motifs"
                  className="file-input"
                  onChange={this.onMultiChange}
                />
              </div>
              <button type="submit" className="btn btn-success btn-m0">
                Submit Motifs
              </button>
            </form>
          </div>
          <div className="col-6">
            <h5>Upload Motif CSV</h5>
            <p>
              Export a page of the motif library database Google sheet file into
              a CSV format and upload it here to modify &amp; add motifs (1 page
              at a time)
            </p>

            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="file-input"
                  onChange={this.onChange}
                />
              </div>
              <button type="submit" className="btn btn-success btn-m0">
                Submit CSV
              </button>
            </form>
          </div>
          <div className="col-6">
            <h5>Upload Motif TXT</h5>
            <p></p>

            <form onSubmit={(e) => this.onSubmitTXT(e, true)}>
              <div className="form-group">
                <input
                  type="file"
                  name="file"
                  id="fileTXT"
                  className="file-input"
                  onChange={this.onChange}
                />
              </div>
              <button type="submit" className="btn btn-success btn-m0">
                Submit TXT
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminUpload;
