import { createClient } from "contentful";

const config = {
  space: "q7tgybrcljva",
  accessToken: "xHiN5c7RNIl7usx1M8tSUi-F50MvTCewYS5z3DhO72U",
};

export default class Contentful {
  constructor() {
    this.client = createClient(config);
  }

  getEntry = (entryId) => {
    return new Promise((resolve, reject) => {
      this.client.getEntry(entryId).then(function (entry) {
        resolve(entry.fields);
      });
    });
  };

  async getEntries() {
    return new Promise((resolve, reject) => {
      this.client.getEntries().then(function (entries) {
        let entryFields = entries.items.map(function (entry) {
          return entry.fields;
        });
        resolve(entryFields);
      });
    });
  }
}
