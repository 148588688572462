// Component Helpers
import React from "react";
import { Route, Redirect } from "react-router-dom";

export function handleResponse(res) {
  if (res.status !== 200) {
    return false;
  } else {
    return res.json();
  }
}

export function authHeader() {
  // return authorization header with jwt token
  let admin = JSON.parse(localStorage.getItem("admin"));

  if (admin && admin.token) {
    return { Authorization: "Bearer " + admin.token };
  } else {
    return {};
  }
}

export const motifRand = () => {
  return Math.floor(Math.random() * 1000) + 1;
};

export const sum = (array) => {
  if (array.length > 0) {
    return array.reduce((t, n) => t + n);
  } else {
    return 0;
  }
};

// Belt width sizing
export const sizing = {
  28: 414,
  29: 432,
  30: 450,
  31: 468,
  32: 486,
  33: 504,
  34: 522,
  35: 540,
  36: 558,
  37: 576,
  38: 594,
  39: 612,
  40: 630,
  41: 648,
  42: 666,
  43: 684,
  44: 702,
  45: 720,
  46: 738,
  47: 756,
  48: 774,
  49: 792,
  50: 810,
  51: 828,
  52: 846,
};

export const validateAddToBelt = (
  motifId,
  rand,
  allMotifs,
  belt,
  restrictions,
  setNotification
) => {
  var returnValue = true;
  const newMotif = allMotifs.byId[motifId];

  // Check sizing
  const sideSpacing = 38 * 2;
  const minGapSpacing =
    belt.motifs.length > 0 ? (belt.motifs.length - 1) * 9 : 0;
  const motifWidths = sum(
    belt.motifs.map((x) => (x[3] ? x[3].width : allMotifs.byId[x[0]].width))
  );
  const newMotifWidth = allMotifs.byId[motifId].width + 9;

  const totalWidth = sideSpacing + minGapSpacing + motifWidths + newMotifWidth;
  const allowedWidth = sizing[belt.size.toString()];
 
  if (totalWidth > allowedWidth) {
    setNotification(
      "This motif doesn't fit on your belt, make room by removing a current motif.",
      "error",
      10000
    );
    returnValue = false;
  }

  // Check max motifs
  if (belt.motifs.length >= 16 && returnValue) {
    setNotification(
      "Your belt already has the max number of motifs. Remove one to add this one.",
      "error",
      10000
    );
    returnValue = false;
  }

  // Check max uses
  if (returnValue) {
    var counts = {};
    belt.motifs
      .map((x) => x[0])
      .forEach((y) => {
        counts[y] = counts[y] ? counts[y] + 1 : 1;
      });

    if (newMotif.maxUses >= counts[newMotif.id]) {
      setNotification(
        "Due to licensing restrictions, we cannot use this icon more than once on each belt.",
        "error",
        10000
      );
      returnValue = false;
    }
  }

  // Check restrictions
  if (returnValue) {
    // for each motif on belt go thorugh and build it's restrictions
    const restrictionsList = [];
    belt.motifs.forEach((motifTuple) => {
      var motif = allMotifs.byId[motifTuple[0]];
      restrictions.forEach((restriction) => {
        if (motif.adminTags.indexOf(restriction.restrictionA) !== -1) {
          restrictionsList.push({
            restriction: restriction.restrictionB,
            message: restriction.message,
          });
        } else if (motif.adminTags.indexOf(restriction.restrictionB) !== -1) {
          restrictionsList.push({
            restriction: restriction.restrictionA,
            message: restriction.message,
          });
        }
      });
    });

    // go through each of those and check if new motif works or not
    restrictionsList.forEach((restriction) => {
      if (newMotif.adminTags.indexOf(restriction.restriction) !== -1) {
        // Notify
        setNotification(
          restriction.message
            ? restriction.message
            : "That motif can't be added to the belt due to restrictions.",
          "error",
          10000
        );
        returnValue = false;
      }
    });
  }

  // Check if premium (notify)
  if (newMotif.upcharge > 0 && returnValue) {
    setNotification(
      "This motif is premium and has a $" + newMotif.upcharge + " upcharge.",
      "premium",
      10000
    );
  }

  return returnValue;
};

export const validatePersonMotifAddToBelt = (
  belt,
  setNotification,
  motifRand,
  configWidth,
  type
) => {
  var returnValue = true;  

  const newBeltMotifs =
    belt.motifs.length > 0
      ? belt.motifs.filter((motif) => motif[1] !== motifRand)
      : [];

  // Check sizing
  const sideSpacing = 38 * 2;
  const minGapSpacing =
    belt.motifs.length > 0 ? (belt.motifs.length - 1) * 9 : 0;
  const motifWidths =
    sum(
      newBeltMotifs.map((x) =>
        x[3]
          ? x[3].width
          : belt.addedMotifs.find((motif) => motif.id === x[0])
          ? belt.addedMotifs.find((motif) => motif.id === x[0]).width
          : 0
      )
    ) + configWidth; 
  const totalWidth = sideSpacing + minGapSpacing + motifWidths;
  const allowedWidth = sizing[belt.size.toString()]; 

  if (totalWidth > allowedWidth) {
    if (type === "children") {
      setNotification(
        "This motif has too many children to fit on your belt, make room by removing a current motif or reducing the number of children.",
        "error",
        10000
      );
    } else {
      setNotification(
        "This motif has too many characters to fit on your belt, make room by removing a current motif or reducing the number of characters.",
        "error",
        10000
      );
    }

    returnValue = false;
  }
  return returnValue;
};

export const buildSelectOptions = (
  receivedCategories,
  receivedSubcategories
) => {
  if (receivedCategories) {
    return receivedCategories.allIds.map((categoryId, index) => {
      var category = receivedCategories.byId[categoryId];
      var buildSubcategories = category.Subcategories.map((subcategoryId) => {
        var subcategory = receivedSubcategories.byId[subcategoryId];
        return (
          <option key={"subcategory-" + subcategory.id} value={subcategory.id}>
            {subcategory.name}
          </option>
        );
      });
      buildSubcategories.unshift(
        <option key={"category-" + category.id} value="" disabled>
          -- {category.name} --
        </option>
      );

      if (index > 0) {
        buildSubcategories.unshift(
          <option
            key={"category-" + category.id + 100000000}
            value=""
            disabled
          ></option>
        );
      } else {
        buildSubcategories.unshift(
          <option key={"category-" + category.id + 100000000} value="" disabled>
            CHOOSE A SUBCATEGORY
          </option>
        );
      }

      return buildSubcategories;
    });
  } else {
    return "";
  }
};

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("admin") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
