import React, { Component } from "react";
import { Link } from "react-router-dom";
import Contentful from "../../helpers/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import "../../styles/info.scss";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentful: new Contentful(),
      content: null
    };

    this.initContent();
  }

  initContent = async () => {   
    let content = await this.state.contentful.getEntry(
      "3Y15P4RR4OtvgBaDjALrnW"
    );
    this.setState({ content });
  };

  render() {
    return (
      <>
        {this.state.content ? (
          <div>
            <div id="faq" className="info">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-10">
                 
                    <h1>
                      {this.state.content.title}
                      <Link className="btn right ghost white" to="/">
                        &larr;&nbsp;&nbsp; back to life belt
                      </Link>
                    </h1>

                    <h2>{this.state.content.subtitle1}</h2>
                    <div>
                      {documentToReactComponents(this.state.content.text1)}
                    </div>

                    <hr />

                    <h2>{this.state.content.subtitle2}</h2>
                    <div>
                      {documentToReactComponents(this.state.content.text2)}
                    </div>

                    <hr />

                    <h2>{this.state.content.subtitle3}</h2>
                    <div>
                      {documentToReactComponents(this.state.content.text3part1)}

                      <img
                        src={this.state.content.beltExamples.fields.file.url}
                        alt=""
                      />
                      <p></p>
                      {documentToReactComponents(this.state.content.text3part2)}
                    </div>
                    <hr />

                    <h2 id="sizing">{this.state.content.subtitle4}</h2>
                    <div>
                      {documentToReactComponents(this.state.content.text4)}

                      <hr />

                      <h2 id="measuring-instructions">
                        {this.state.content.subtitle5}
                      </h2>
                      {documentToReactComponents(this.state.content.text5part1)}

                      <img
                        src={
                          this.state.content.measuringWithBelt.fields.file.url
                        }
                        style={{ maxWidth: "400px", margin: "20px 0" }}
                        alt=""
                      />

                      {documentToReactComponents(this.state.content.text5part2)}
                      <img
                        src={
                          this.state.content.measuringDirections.fields.file.url
                        }
                        style={{ maxWidth: "800px", margin: "20px 0" }}
                        alt=""
                      />

                      {documentToReactComponents(this.state.content.text5part3)}
                    </div>

                    <hr />

                    <h2>{this.state.content.subtitle6}</h2>
                    <div>
                      {documentToReactComponents(this.state.content.text6)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default FAQ;
