import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestLibrary,
  requestPersonLibrary,
  requestRestrictions,
  addMotifToIdeaBox,
  addUserInfo,
  addMotifToBelt,
  removeMotifFromBelt,
  moveMotifOnBelt,
  loadStateFromQuery,
  clearBelt,
  setNotification,
  hideNotification,
  replaceBeltMotifsAndResize,
  updateMotifOnBelt,
  clearIdeaBox,
  sendShareData,
  sendAnalyticsEvent,
} from "../actions";
import Generator from "../components/generator/Generator";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

const mapStateToProps = (state) => {
  return {
    library: state.generator.library,
    ideaBox: state.generator.ideaBox,
    belt: state.generator.belt,
    userInfo: state.generator.userInfo,
    restrictions: state.generator.library.Restrictions,
    shareUUID: state.generator.shareUUID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestLibrary: requestLibrary,
      requestPersonLibrary: requestPersonLibrary,
      requestRestrictions: requestRestrictions,
      addMotifToIdeaBox: addMotifToIdeaBox,
      addUserInfo: addUserInfo,
      addMotifToBelt: addMotifToBelt,
      removeMotifFromBelt: removeMotifFromBelt,
      moveMotifOnBelt: moveMotifOnBelt,
      loadStateFromQuery: loadStateFromQuery,
      clearBelt: clearBelt,
      setNotification: setNotification,
      hideNotification: hideNotification,
      replaceBeltMotifsAndResize: replaceBeltMotifsAndResize,
      updateMotifOnBelt: updateMotifOnBelt,
      clearIdeaBox: clearIdeaBox,
      sendShareData: sendShareData,
      sendAnalyticsEvent: sendAnalyticsEvent,
    },
    dispatch
  );
};

const GeneratorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(Generator));

export default GeneratorContainer;
