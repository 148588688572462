import {
  ADMIN_CREATE_MOTIF_SUCCESS,
  ADMIN_DELETE_MOTIF_SUCCESS,
  ADMIN_RECEIVE_MOTIFS,
  ADMIN_RECEIVE_CATEGORIES,
  ADMIN_UPDATE_CATEGORY_ORDER_SUCCESS,
  ADMIN_CREATE_CATEGORY_SUCCESS,
  ADMIN_DELETE_CATEGORY_SUCCESS,
  ADMIN_UPDATE_CATEGORY_SUCCESS,
  ADMIN_CREATE_PERSON_MOTIF_SUCCESS,
  ADMIN_UPDATE_PERSON_MOTIF_SUCCESS,
  ADMIN_DELETE_PERSON_MOTIF_SUCCESS,
  ADMIN_RECEIVE_PERSON_MOTIFS,
  ADMIN_RECEIVE_PERSON_CATEGORIES,  
  ADMIN_CREATE_PERSON_CATEGORY_SUCCESS,
  ADMIN_DELETE_PERSON_CATEGORY_SUCCESS,
  ADMIN_UPDATE_PERSON_CATEGORY_SUCCESS,
  ADMIN_ADD_SUBCATEGORY_SUCCESS,
  ADMIN_DELETE_SUBCATEGORY_SUCCESS,
  ADMIN_UPDATE_SUBCATEGORY_SUCCESS,
  ADMIN_UPDATE_MOTIF_SUCCESS,
  ADMIN_CREATE_RESTRICTION_SUCCESS,
  ADMIN_RECEIVE_RESTRICTIONS,
  ADMIN_DELETE_RESTRICTION_SUCCESS,
  ADMIN_RECEIVE_SUBMITTED_BELTS,
  ADMIN_RECEIVE_DRAFTED_BELTS,
  ADMIN_RECEIVE_ANALYTICS,
  ADMIN_NOTIFICATION,
  CLOSE_ADMIN_NOTIFICATION,
  ADMIN_RECEIVE_FULL_ANALYTICS
} from '../actions';
import { normalize } from 'normalizr';
import { categoryList, motifList, restrictionList, personCategoryList, personMotifList } from '../schemas/index.js';

const defaultState = {
  Motifs: {byId: {}, allIds: []},
  PersonMotifs: {byId: {}, allIds: []},
  Categories: {byId: {}, allIds: []},
  PersonCategories: {byId: {}, allIds: []},
  Subcategories: {byId: {}, allIds: []},
  Restrictions: {byId: {}, allIds: []},
  SubmittedBelts: [],
  DraftedBelts: [],
  Analytics: null,
  FullAnalytics: null,
  notification: null
}

export default (state=defaultState, action) => {
  switch(action.type) {
    case ADMIN_RECEIVE_RESTRICTIONS:
      var normalizedRestrictionData = normalize(action.payload, restrictionList);
      return {
        ...state,
        Restrictions: {
          ...state.Restrictions,
          byId: normalizedRestrictionData.entities.Restrictions,
          allIds: normalizedRestrictionData.result
        },
        Subcategories: {
          ...state.Subcategories,
          byId: {
            ...state.Subcategories.byId,
            ...normalizedRestrictionData.entities.Subcategories
          }
        }
      };
    case ADMIN_CREATE_RESTRICTION_SUCCESS:
      return {
        ...state,
        Restrictions: {
          byId: {
            ...state.Restrictions.byId,
            [action.payload.id]: action.payload
          },
          allIds: [...state.Restrictions.allIds, action.payload.id]
        }
      };
    case ADMIN_DELETE_RESTRICTION_SUCCESS:
      var newDeleteRestrictionIds = [...state.Restrictions.allIds];
      newDeleteRestrictionIds.splice(state.Restrictions.allIds.indexOf(parseInt(action.payload)), 1);
      var newDeleteRestrictionState = {
        ...state,
        Restrictions: {
          ...state.Restrictions,
          allIds: newDeleteRestrictionIds
        }
      }
      delete newDeleteRestrictionState.Restrictions.byId[action.payload];
      return newDeleteRestrictionState;
    case ADMIN_CREATE_MOTIF_SUCCESS:
      return {
        ...state,
        Motifs: {
          byId: {
            ...state.Motifs.byId,
            [action.payload.id]: action.payload
          },
          allIds: [...state.Motifs.allIds, action.payload.id]
        }
      };
    case ADMIN_UPDATE_MOTIF_SUCCESS:
      return {
        ...state,
        Motifs: {
          ...state.Motifs,
          byId: {
            ...state.Motifs.byId,
            [action.payload.id]: action.payload
          }
        }
      };
    case ADMIN_DELETE_MOTIF_SUCCESS:
      var newDeleteMotifIds = [...state.Motifs.allIds];
      newDeleteMotifIds.splice(state.Motifs.allIds.indexOf(parseInt(action.payload)), 1);
      var newDeleteMotifState = {
        ...state,
        Motifs: {
          ...state.Motifs,
          allIds: newDeleteMotifIds
        }
      }
      delete newDeleteMotifState.Motifs.byId[action.payload]
      return newDeleteMotifState;
    case ADMIN_RECEIVE_MOTIFS:
      var normalizedMotifs = normalize(action.payload, motifList);
      return {
        ...state,
        Motifs: {
          byId: normalizedMotifs.entities.Motifs,
          allIds: normalizedMotifs.result
        },
        Subcategories: {
          ...state.Subcategories,
          byId: {
            ...state.Subcategories.byId,
            ...normalizedMotifs.entities.Subcategories
          }
        }
      };
    case ADMIN_RECEIVE_CATEGORIES:
      var normalizedData = normalize(action.payload, categoryList);
      return {
        ...state,
        Categories: {...state.Categories, byId: normalizedData.entities.Categories, allIds: normalizedData.result},
        Subcategories: {...state.Subcategories, byId: normalizedData.entities.Subcategories}
      };
    case ADMIN_UPDATE_CATEGORY_ORDER_SUCCESS:
      return {
        ...state,
        Categories: {...state.Categories, allIds: action.payload}
      };
    case ADMIN_CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        Categories: {byId: {...state.Categories.byId, [action.payload.id]: {...action.payload, Subcategories: []}}, allIds: [...state.Categories.allIds, action.payload.id]}
      }
    case ADMIN_DELETE_CATEGORY_SUCCESS:
      var newIdsArr = state.Categories.allIds;
      newIdsArr.splice(state.Categories.allIds.indexOf(parseInt(action.payload)), 1);
      var newState = {
        ...state,
        Categories: {...state.Categories, allIds: newIdsArr}
      }
      delete newState.Categories.byId[action.payload];
      return newState;
    case ADMIN_UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        Categories: {...state.Categories, byId: {...state.Categories.byId, [action.payload.id]: {...state.Categories.byId[action.payload.id], name: action.payload.name}}}
      };
    case ADMIN_ADD_SUBCATEGORY_SUCCESS:
      const categoryId = action.payload.CategoryId;
      return {
        ...state,
        Categories: {
          ...state.Categories,
          byId: {
            ...state.Categories.byId,
            [categoryId]: {
              ...state.Categories.byId[categoryId],
              Subcategories: [
                ...state.Categories.byId[categoryId].Subcategories,
                action.payload.id
              ]
            }
          }
        },
        Subcategories: {...state.Subcategories, byId: {...state.Subcategories.byId, [action.payload.id]: action.payload}, allIds: [...state.Subcategories.allIds, action.payload.id]}
      };
    case ADMIN_DELETE_SUBCATEGORY_SUCCESS:
      var deleteNewIdsArr = state.Subcategories.allIds;
      deleteNewIdsArr.splice(deleteNewIdsArr.indexOf(parseInt(action.payload.subcategoryId)), 1);

      var newSubIdsArr = state.Categories.byId[action.payload.categoryId].Subcategories;
      newSubIdsArr.splice(newSubIdsArr.indexOf(parseInt(action.payload.subcategoryId)), 1);

      var deleteNewState = {
        ...state,
        Subcategories: {
          ...state.Subcategories,
          allIds: deleteNewIdsArr
        },
        Categories: {
          ...state.Categories,
          byId: {
            ...state.Categories.byId,
            [action.payload.categoryId]: {
              ...state.Categories.byId[action.payload.categoryId],
              Subcategories: newSubIdsArr
            }
          }
        }
      }

      delete deleteNewState.Subcategories.byId[action.payload.subcategoryId]
      return deleteNewState
    case ADMIN_UPDATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        Subcategories: {
          ...state.Subcategories,
          byId: {
            ...state.Subcategories.byId,
            [action.payload.id]: action.payload
          }
        }
      }
    case ADMIN_RECEIVE_SUBMITTED_BELTS:
      return {
        ...state,
        SubmittedBelts: action.payload
      }
    case ADMIN_RECEIVE_DRAFTED_BELTS:
      return {
        ...state,
        DraftedBelts: action.payload
      }
    case ADMIN_RECEIVE_ANALYTICS:
      return {
        ...state,
        Analytics: action.payload
      }
    case ADMIN_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      }
    case CLOSE_ADMIN_NOTIFICATION:
      return {
        ...state,
        notification: null
      }
    case ADMIN_RECEIVE_FULL_ANALYTICS:
      return {
        ...state,
        FullAnalytics: action.payload
      }
      ///// cases for personilized
      case ADMIN_CREATE_PERSON_MOTIF_SUCCESS:
      return {
        ...state,
        PersonMotifs: {
          byId: {
            ...state.PersonMotifs.byId,
            [action.payload.id]: action.payload
          },
          allIds: [...state.PersonMotifs.allIds, action.payload.id]
        }
      };
    case ADMIN_UPDATE_PERSON_MOTIF_SUCCESS:
      return {
        ...state,
        PersonMotifs: {
          ...state.PersonMotifs,
          byId: {
            ...state.PersonMotifs.byId,
            [action.payload.id]: action.payload
          }
        }
      };
    case ADMIN_DELETE_PERSON_MOTIF_SUCCESS:
      var newDeletePersonMotifIds = [...state.PersonMotifs.allIds];
      newDeletePersonMotifIds.splice(state.PersonMotifs.allIds.indexOf(parseInt(action.payload)), 1);
      var newDeletePersonMotifState = {
        ...state,
        PersonMotifs: {
          ...state.PersonMotifs,
          allIds: newDeletePersonMotifIds
        }
      }
      delete newDeletePersonMotifState.PersonMotifs.byId[action.payload]
      return newDeletePersonMotifState;
      
    case ADMIN_RECEIVE_PERSON_MOTIFS:
      var normalizedPersonMotifs = normalize(action.payload, personMotifList);
      return {
        ...state,
        PersonMotifs: {
          byId: normalizedPersonMotifs.entities.PersonMotifs,
          allIds: normalizedPersonMotifs.result
        },
        PersonCategories: {
          ...state.PersonCategories,
          byId: {
            ...state.PersonCategories.byId,
            ...normalizedPersonMotifs.entities.PersonCategories
          }
        }
      };
    case ADMIN_RECEIVE_PERSON_CATEGORIES:
      var normalizedPersonData = normalize(action.payload, personCategoryList);
      return {
        ...state,
        PersonCategories: {...state.PersonCategories, byId: normalizedPersonData.entities.PersonCategories, allIds: normalizedPersonData.result},        
      };    
    case ADMIN_CREATE_PERSON_CATEGORY_SUCCESS:
      return {
        ...state,
        PersonCategories: {byId: {...state.PersonCategories.byId, [action.payload.id]: {...action.payload}}, allIds: [...state.PersonCategories.allIds, action.payload.id]}
      }
    case ADMIN_DELETE_PERSON_CATEGORY_SUCCESS:
      var newIdsArrPerson = state.PersonCategories.allIds;
      newIdsArrPerson.splice(state.PersonCategories.allIds.indexOf(parseInt(action.payload)), 1);
      var newStatePerson = {
        ...state,
        PersonCategories: {...state.Categories, allIds: newIdsArrPerson}
      }
      delete newStatePerson.PersonCategories.byId[action.payload];
      return newStatePerson;
    case ADMIN_UPDATE_PERSON_CATEGORY_SUCCESS:
      return {
        ...state,
        PersonCategories: {...state.PersonCategories, byId: {...state.PersonCategories.byId, [action.payload.id]: {...state.PersonCategories.byId[action.payload.id], name: action.payload.name}}}
      };
    default:
      return state;
  }
}
