export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
let timeout;

export function setNotification(text="Default", type="default", duration=5000) {
  return function(dispatch) {
    dispatch(showNotification(text, type));
    clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      dispatch(hideNotification());
    }, duration);
  }
}

export function hideNotification() {
  return {
    type: HIDE_NOTIFICATION
  }
}

function showNotification(text, type) {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      text: text,
      type: type
    }
  }
}
