import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Contentful from '../../helpers/contentful';

import '../../styles/info.scss';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentful: new Contentful(),
      content: null,
    };
    this.initContent();
  }

  initContent = async () => {
    let content = await this.state.contentful.getEntry(
      '7JfFwtX5VmGje4QUhJGJVM'
    );
    this.setState({ content });
  };

  render() {
    return (
      <div>
        <div id='contact' className='info'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-6'>
                {this.state.content ? (
                  <>
                    <h1>
                      {this.state.content.title}{' '}
                      <Link className='btn right ghost white' to='/'>
                        &larr;&nbsp;&nbsp; back to life belt
                      </Link>
                    </h1>

                    <strong>
                      <span className='contentText'>
                        {this.state.content.text1}{' '}
                      </span>
                      <span className='contentText'>
                        <Link to='/faq'>FAQ Page</Link>
                      </span>
                      <span className='contentText'>
                        {this.state.content.text2}{' '}
                      </span>
                    </strong>
                    <hr />
                    {documentToReactComponents(this.state.content.text3)}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
