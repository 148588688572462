import React, { Component } from 'react'

const SelectedMotif = props => {
  return (
    <div className="admin-motif-info-container">
      <div className="background" onClick={props.close}></div>
      <div>
        <h5>{props.motif.name}<img alt="" src={"https://lifebelt-new.s3.us-east-2.amazonaws.com/" + props.motif.filename} className="thumbnail" /></h5>
        <p><b>Filename: </b>{props.motif.filename}</p>
        <p><b>Subcategories: </b>{props.motif.subcategories.map((subcategoryId, index) => props.subcategories.byId[subcategoryId].name + (index === props.motif.subcategories.length - 1 ? "" : ", ") )}</p>
        <p><b>Width: </b>{props.motif.width}</p>
        <p><b>Height: </b>{props.motif.height}</p>
        <p><b>Background Colors: </b>{props.motif.backgroundColors ? props.motif.backgroundColors.join(", ") : "-"}</p>
        <p><b>Admin Tags: </b>{props.motif.adminTags && props.motif.adminTags[0] ? props.motif.adminTags.join(", ") : "-"}</p>
        <p><b>Search Tags: </b>{props.motif.searchTags ? props.motif.searchTags.split(",").join(", ") : "-"}</p>
        <p><b>Upcharge: </b>{props.motif.upcharge ? "$" + props.motif.upcharge : "-"}</p>
        <p><b>Max Uses: </b>{props.motif.maxUses ? props.motif.maxUses : "-"}</p>
        <p><b>Priority: </b>{props.motif.priority ? props.motif.priority : "-"}</p>
        <p><b>Color Key: </b>{props.motif.colorKey ? props.motif.colorKey : "-"}</p>
        
        <p><b>Motif ID: </b>{props.motif.id ? props.motif.id : "-"}</p>
      </div>
    </div>
  )
}

class AdminMotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      motifs: [],
      filter: "",
      filteredMotifs: [],
      selectedMotif: null
    };
    this.routeChange = this.routeChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      filter: "",
      motifs: this.props.motifs.allIds.map(id => this.props.motifs.byId[id]),
      filteredMotifs: this.props.motifs.allIds.map(id => this.props.motifs.byId[id])
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.motifs !== prevProps.motifs) {
      this.setState({
        filter: "",
        motifs: this.props.motifs.allIds.map(id => this.props.motifs.byId[id]),
        filteredMotifs: this.props.motifs.allIds.map(id => this.props.motifs.byId[id])
      });
    }
  }

  routeChange(id) {
    let path = "/admin/motifs/" + id + "/edit";
    this.props.history.push(path)
  }

  handleFilterChange(e) {
    if (e.target.value !== "") {
      this.setState({
        filteredMotifs: this.state.motifs.filter(motif => motif.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0),
        filter: e.target.value
      });
    } else {
      this.setState({
        filteredMotifs: this.state.motifs,
        filter: e.target.value
      })
    }
  }

  createMotifTableRows() {
    return this.state.filteredMotifs.map((motif) => {
      return(
        <tr key={motif.id} onClick={() => this.setState({selectedMotif: motif})}>        
          <td style={{textAlign: 'center'}}><img style={{margin: '0 auto'}} alt="" src={"https://lifebelt-new.s3.us-east-2.amazonaws.com/" + motif.filename} className="motif-thumbnail" /></td>
          <td><p className="motif-name">{motif.name}</p></td>
          <td><p>{motif.id}</p></td>
          <td><p>{motif.subcategories.map((subcategoryId, index) => this.props.subcategories.byId[subcategoryId].name + (index === motif.subcategories.length - 1 ? "" : ", ") )}</p></td>
          <td><p>{motif.width}</p></td>
          <td><p>{motif.height}</p></td>
          <td><p>{motif.backgroundColors ? motif.backgroundColors.join(", ") : "-"}</p></td>
          <td><p>{motif.adminTags && motif.adminTags[0] ? motif.adminTags.join(", ") : "-"}</p></td>
          <td><p>{motif.searchTags ? motif.searchTags.split(",").join(", ") : "-"}</p></td>
          <td><p>{motif.upcharge ? "$" + motif.upcharge : "-"}</p></td>
          <td><p>{motif.maxUses ? motif.maxUses : "-"}</p></td>
          <td><p>{motif.priority ? motif.priority : "-"}</p></td>
        </tr>
      );
    });
  }

  render() {
    return (
      <div id="admin-motifs">
        { this.state.selectedMotif ? <SelectedMotif motif={this.state.selectedMotif} subcategories={this.props.subcategories} close={() => this.setState({selectedMotif: null})} /> : null }
        <p className="results-count">{this.state.filteredMotifs.length} motifs</p>
        <input className="form-control" placeholder="Search Library" value={this.state.filter} onChange={this.handleFilterChange} />
        <table className="motif-list">
          <thead>
            <tr>
              <th style={{width: '75px', textAlign: 'center'}}>Thumbnail</th>
              <th>Name</th>
              <th>Id</th>
              <th>Subcategories</th>
              <th style={{width: '50px'}}>Width</th>
              <th style={{width: '50px'}}>Height</th>
              <th style={{width: '125px'}}>Bkg. Colors</th>
              <th>Admin Tags</th>
              <th>Search Tags</th>
              <th style={{width: '75px'}}>Upcharge</th>
              <th style={{width: '80px'}}>Max Uses</th>
              <th style={{width: '75px'}}>Priority</th>
            </tr>
          </thead>
          <tbody>
            {this.createMotifTableRows()}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AdminMotif
