import React, { Component } from 'react';
import '../../../styles/personalization.scss';

class HeartWithInitials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      second: "",
      error: null
    }

    this.changeFirst = this.changeFirst.bind(this);
    this.changeSecond = this.changeSecond.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  isLetter(str) {
    return (str.length === 1 && str.match(/[A-Z]/i)) || str === "";
  }

  clearInput(e) {
    this.setState({
      [e.target.name]: ""
    });
  }

  componentDidMount() {
    this.first.focus();
    if (this.props.data.data.initials.length > 0) {
      this.setState({
        first: this.props.data.data.initials[0],
        second: this.props.data.data.initials[1]
      });
    }
  }

  changeFirst(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).first.split("");
    let newLetter = input.filter(n => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }    

    if (this.isLetter(newLetter)) {
      this.setState({
        first: newLetter,
        error: null
      });
      this.second.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z"
      })
    }
  }

  changeSecond(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).second.split("");
    let newLetter = input.filter(n => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }
  
    if (this.isLetter(newLetter)) {
      this.setState({
        second: newLetter,
        error: null
      });
      this.second.blur();
    } else {
      this.setState({
        error: "You can only enter letters A-Z"
      })
    }
  }

  onSubmit() {
    const initials = [this.state.first, this.state.second];
    const submission = {
      type: "heartWithInitials",
      data: {
        initials: initials
      },
      valid: initials.filter(x => x !== "").length === 2,
      isFirstLoad: false
    }

    if (!submission.valid) {
      this.props.setNotification('Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.', "error", 10000);
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container">
              <input ref={first => { this.first = first; }} value={this.state.first} name="first" onClick={this.clearInput} onChange={this.changeFirst} />
              <p>+</p>
              <input ref={second => { this.second = second; }} value={this.state.second} name="second" onClick={this.clearInput} onChange={this.changeSecond} />
            </div>
            {this.state.error ? <p className="edit-error">{this.state.error}</p> : null}
            <br />
            <div className="character-rules">
              <p>* To remove a letter, please click the box</p>
              <br /><br />
              <p>2 Letters Required. Uppercase Letters Only.</p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    )
  }
}

export default HeartWithInitials;
