import React, { Component } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Contentful from "../../helpers/contentful";

import "../../styles/submit-form.scss";

class SubmitForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorField: "",
      error: "",
      contentful: new Contentful(),
      content: null,
    };
    this.initContent();
    this.handleChange = this.handleChange.bind(this);
  }

  initContent = async () => {
    let content = await this.state.contentful.getEntry(
      "2Fb6dJSol2YT0aAnB2x0j6"
    );
    this.setState({ content });
  };

  componentDidMount() {
    this.props.resetCompletedOrder();
  }

  handleChange(e) {
    var newState = {};
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  }

  setError(errorField = "", error = "") {
    this.setState({
      errorField: errorField,
      error: error,
    });
  }

  validateFormAndSubmit() {
    this.setError();
    var shouldSubmit = true;
   
    if (this.props.belt.motifs.length < 6 || !this.props.belt.hasSet || !this.props.userInfo ) {
      shouldSubmit = false;
      this.props.history.push("/");
      return;
    }

    if (shouldSubmit) {
      this.props.submitBelt(this.props.userInfo);
    }
  }

  validateFormAndSubmitDraft() {
    this.setError();
    var shouldSubmit = true;

    if (this.props.belt.motifs.length < 6 || !this.props.belt.hasSet) {
      shouldSubmit = false;
      this.props.history.push("/");
      return;
    }

    if (shouldSubmit) {
      this.props.submitDraft(this.props.userInfo);
    }
  }

  render() {
  
    return (
      <div id="submit-form">
        <div className="container">
          <div className="row">
            <div className="col-7">
            {this.state.content ? (
                <div id="form-info">
                  <h2>{this.state.content.title}</h2>                  
                  {documentToReactComponents(this.state.content.text1)}
                </div>
              ) : null}
              <div className="scroll-down">scroll down for more info</div>
            </div>
            <div className="col-5">
              <div id="form">
                <div className="subInfo">
                  <div className="field">
                    <div className="fieldName">Name:</div>
                    <div className="fieldValue">
                      {this.props.userInfo.first} {this.props.userInfo.last}
                    </div>
                  </div>
                  <div className="field">
                    <div className="fieldName">Email:</div>
                    <div className="fieldValue">
                      {this.props.userInfo.email}
                    </div>
                  </div>
                  <div className="field">
                    <div className="fieldName">Phone #:</div>
                    <div className="fieldValue">
                      {this.props.userInfo.phone}
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-m0"
                  id="pay-button"
                  onClick={this.validateFormAndSubmit.bind(this)}
                >
                  <p className="main">
                    Add to Cart<span>&rarr;</span>
                  </p>
                </button>

                <button
                  className="btn btn-m0"
                  id="draft-button"
                  onClick={this.validateFormAndSubmitDraft.bind(this)}
                >
                  <p className="main">
                    Save for Later<span>&rarr;</span>
                  </p>
                  <p className="sub">
                    Not ready to check out? You will be emailed a copy of your
                    design with instructions so you can edit or complete at a
                    later time.
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitForm;
