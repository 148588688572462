const getCompoundConfigs = (compounds) => {
  let configs = [];
  let width = 0;
  let height = 0;
  let topWidth = 0;
  let middleWidth = 0;
  let bottomWidth = 0;
  let centreHeight = 0;

  const keyArr = Object.keys(compounds);

  const findMaxWidth = (padCenter = 2) => {
    if (keyArr.length < 4) {
      keyArr.forEach((pos) => {
        Object.keys(compounds[pos])
          .filter((key) => key === "width")
          .forEach((key) => {
            if (compounds[pos][key] > width) {
              width = compounds[pos][key];
            }
          });
      });
    } else {
      keyArr
        .filter((pos) => pos.includes("top"))
        .forEach((pos) => {
          Object.keys(compounds[pos])
            .filter((key) => key === "width")
            .forEach((key) => {
              topWidth += compounds[pos][key] + (pos.includes("Left") ? padCenter : 0);
            });
        });

      keyArr
        .filter((pos) => pos.includes("middle"))
        .forEach((pos) => {
          Object.keys(compounds[pos])
            .filter((key) => key === "width")
            .forEach((key) => {
              middleWidth += compounds[pos][key] + (pos.includes("Left") ? padCenter : 0);
            });
        });

      keyArr
        .filter((pos) => pos.includes("bottom"))
        .forEach((pos) => {
          Object.keys(compounds[pos])
            .filter((key) => key === "width")
            .forEach((key) => {
              bottomWidth += compounds[pos][key] + (pos.includes("Left") ? padCenter : 0);
            });
        });

      width = Math.max(...[topWidth, middleWidth, bottomWidth]);
    }   
  };

  const findPositionInLine = (
    lineCompounds,
    padCenter = 0,
    leftPad = 0,
    topPad
  ) => {
    let leftCompoundWidth = 0;
    const lineConfigs = [];
    lineCompounds.forEach((compound) => {
      if (compound.key.includes("Left")) {
        leftCompoundWidth = compound.width;
        compound.pixelConfigs.forEach((item) => {
          lineConfigs.push({
            ...item,
            pos: [item.pos[0] + leftPad, item.pos[1] + topPad],
            color: item.color,
          });
        });
      } else {
        compound.pixelConfigs.forEach((item) => {
          lineConfigs.push({
            ...item,
            pos: [
              item.pos[0] +
                (compound.key === "bottom"
                  ? leftPad
                  : leftCompoundWidth + padCenter + leftPad),
              item.pos[1] + topPad,
            ],
            color: item.color,
          });
        });
      }
    });
    return lineConfigs;
  };

  const getLineCompounds = (property) => {
    const filteredArr = [];
    keyArr
      .filter((key) => key.includes(property))
      .forEach((key) => {
        filteredArr.push({ ...compounds[key], key });
      });
    return filteredArr;
  };

  const findNewPosition = (
    padTop = 1,
    padCenter = 2,
    compoundsNumber,
    align
  ) => {
    if (align === "center") {
      keyArr
        .filter((key) => !key.includes("Right") && !key.includes("Left"))
        .forEach((key) => {
          compounds[key].pixelConfigs.forEach((item) => {
            configs.push({
              ...item,
              pos: [
                item.pos[0] + Math.floor((width - compounds[key].width) / 2),
                item.pos[1] + centreHeight,
              ],
              color: item.color,
            });
          });
          centreHeight = centreHeight + compounds[key].height + padTop;
        });
      height = centreHeight - padTop;
    } else {
      const letterHeight = 5;
      const lineCompounds = {
        top: getLineCompounds("top"),
        middle: getLineCompounds("middle"),
        bottom: getLineCompounds("bottom"),
      };

      const paddingsTop = {
        top: 0,
        middle: letterHeight + padTop,
        bottom:
          compoundsNumber > 4
            ? 2 * letterHeight + 2 * padTop
            : letterHeight + padTop,
      };

      configs = [
        ...findPositionInLine(
          lineCompounds.top,
          padCenter,
          Math.floor((width - topWidth) / 2),
          paddingsTop.top
        ),
        ...findPositionInLine(
          lineCompounds.middle,
          padCenter,
          Math.floor((width - middleWidth) / 2),
          paddingsTop.middle
        ),
        ...findPositionInLine(
          lineCompounds.bottom,
          padCenter,
          Math.floor((width - bottomWidth) / 2),
          paddingsTop.bottom
        ),
      ];      
    }   
  };

  if (keyArr.length === 3) {
    findMaxWidth();
    findNewPosition(1, 1, 3, "center");
    height = height + 1;
  } else if (keyArr.length === 2) {
    findMaxWidth();
    findNewPosition(2, 1, 2, "center");
  } else if (keyArr.length === 4) {
    findMaxWidth(4);
    findNewPosition(4, 4, 4);
    height = 2 * 5 + 4;
  } else if (keyArr.length === 5) {
    findMaxWidth(4);
    findNewPosition(1, 4, 5);
    height = 3 * 5 + 3 * 1;
  } else if (keyArr.length === 6) {
    findMaxWidth(4);
    findNewPosition(1, 4, 6);
    height = 3 * 5 + 3 * 1;
  }

  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getCompoundConfigs;
