import React, { Component } from "react";

import "../../../styles/personalization.scss";

const Child = (props) => {
  return (
    <div>
      <h5>CHILD {numbers[props.name]}</h5>
      <div className="radio-group">
        <p>Gender</p>
        <select
          value={props.data.gender}
          name={props.name + "gender"}
          onChange={(e) => props.change(e, props.name)}
        >
          <option value="">Choose a gender</option>
          <option value="boy">Boy</option>
          <option value="girl">Girl</option>
        </select>
      </div>

      <div className="radio-group">
        <p>Skin</p>
        <div datatip="Light Skin">
          <input
            type="radio"
            className="light-skin"
            checked={props.data.skin === "#f7dec0"}
            value="#f7dec0"
            id={props.name + "ec0"}
            name={props.name + "skin"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "ec0"}></label>
        </div>
        <div datatip="Medium Skin">
          <input
            type="radio"
            className="medium-skin"
            checked={props.data.skin === "#c79444"}
            value="#c79444"
            id={props.name + "444"}
            name={props.name + "skin"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "444"}></label>
        </div>
        <div datatip="Dark skin">
          <input
            type="radio"
            className="dark-skin"
            checked={props.data.skin === "#82571e"}
            value="#82571e"
            id={props.name + "71e"}
            name={props.name + "skin"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "71e"}></label>
        </div>
      </div>

      <div className="radio-group">
        <p>Hair</p>

        {props.data.skin === "#82571e" ? null : props.data.skin ===
          "#f7dec0" ? (
          <div datatip="Brown">
            <input
              type="radio"
              className="brown"
              checked={props.data.hair === "#996520"}
              value="#996520"
              id={props.name + "520"}
              name={props.name + "hair"}
              onChange={(e) => props.change(e, props.name)}
            />
            <label htmlFor={props.name + "520"}></label>
          </div>
        ) : (
          <div datatip="Brown">
            <input
              type="radio"
              className="brown2"
              checked={props.data.hair === "82571e"}
              value="82571e"
              id={props.name + "71e"}
              name={props.name + "hair"}
              onChange={(e) => props.change(e, props.name)}
            />
            <label htmlFor={props.name + "71e"}></label>
          </div>
        )}

        <div datatip="Dark Brown">
          <input
            type="radio"
            className="dark-brown"
            checked={props.data.hair === "#563914"}
            value="#563914"
            id={props.name + "914"}
            name={props.name + "hair"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "914"}></label>
        </div>
        <div datatip="Blonde">
          <input
            type="radio"
            className="blonde"
            checked={props.data.hair === "#fccb20"}
            value="#fccb20"
            id={props.name + "b20"}
            name={props.name + "hair"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "b20"}></label>
        </div>
        <div datatip="Black">
          <input
            type="radio"
            className="black"
            checked={props.data.hair === "#000000"}
            value="#000000"
            id={props.name + "000"}
            name={props.name + "hair"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "000"}></label>
        </div>
        <div datatip="Red">
          <input
            type="radio"
            className="red"
            checked={props.data.hair === "#cc6214"}
            value="#cc6214"
            id={props.name + "214"}
            name={props.name + "hair"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "214"}></label>
        </div>
      </div>

      <div className="radio-group">
        <p>Clothing</p>
        <div datatip="Blue">
          <input
            type="radio"
            className="blue"
            checked={props.data.clothing === "#5c95d4"}
            value="#5c95d4"
            id={props.name + "5d4"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "5d4"}></label>
        </div>
        <div datatip="Pink">
          <input
            type="radio"
            className="pink"
            checked={props.data.clothing === "#f57493"}
            value="#f57493"
            id={props.name + "493"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "493"}></label>
        </div>
        <div datatip="Green">
          <input
            type="radio"
            className="green"
            checked={props.data.clothing === "#239a4f"}
            value="#239a4f"
            id={props.name + "a4f"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "a4f"}></label>
        </div>
        <div datatip="Red">
          <input
            type="radio"
            className="clothing-red"
            checked={props.data.clothing === "#bc0000"}
            value="#bc0000"
            id={props.name + "001"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "001"}></label>
        </div>
        <div datatip="Purple">
          <input
            type="radio"
            className="purple"
            checked={props.data.clothing === "#9f89d7"}
            value="#9f89d7"
            id={props.name + "9d7"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "9d7"}></label>
        </div>
        <div datatip="Orange">
          <input
            type="radio"
            className="orange"
            checked={props.data.clothing === "#f8750c"}
            value="#f8750c"
            id={props.name + "50c"}
            name={props.name + "clothing"}
            onChange={(e) => props.change(e, props.name)}
          />
          <label htmlFor={props.name + "50c"}></label>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

class Children extends Component {
  constructor(props) {
    super(props);

    this.state = {
      one: {
        gender: "",
        skin: "",
        hair: "",
        clothing: "",
      },
      two: {
        gender: "",
        skin: "",
        hair: "",
        clothing: "",
      },
      three: {
        gender: "",
        skin: "",
        hair: "",
        clothing: "",
      },
      four: {
        gender: "",
        skin: "",
        hair: "",
        clothing: "",
      },
      five: {
        gender: "",
        skin: "",
        hair: "",
        clothing: "",
      },
      childNumber: 0,
      error: null,
    };

    this.change = this.change.bind(this);
    this.changeNumber = this.changeNumber.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const props = {
      ...this.props.data.data,
      childNumber: this.props.data.childNumber,
    };

    this.setState({
      ...props,
    });
  }

  change(e, child) {
    let targetName = e.target.name.slice(child.length);
    this.setState({
      [child]: {
        ...this.state[child],
        [targetName]: e.target.value,
      },
    });
  }

  changeNumber({ target }) {
    this.setState({
      ...this.state,
      childNumber: Number(target.value),
    });
  }

  onSubmit() {
    let data = {};
    let errors = {};
    if (this.state.childNumber) {
      ["one", "two", "three", "four", "five"]
        .slice(0, this.state.childNumber)
        .forEach((el) => {
          data[el] = this.state[el];
          if (
            Object.values(this.state[el]).filter((x) => x !== "").length !== 4
          ) {
            errors[el] = "error";
          }
        });
    } else {
      errors.childNumber = "error";
    }

    const submission = {
      type: "children",
      data,
      childNumber: this.state.childNumber,
      valid: Object.values(errors).length === 0,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex scroll">
              <h5>{"Number of Children".toUpperCase()}</h5>
              <div className="radio-group">
                <br />
                <select
                  value={this.state.childNumber}
                  name={"number"}
                  onChange={this.changeNumber}
                >
                  <option value="">Choose a number</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                  <option value="4">Four</option>
                  <option value="5">Five</option>
                </select>
              </div>
              <br />
              {this.state.childNumber > 0
                ? ["one", "two", "three", "four", "five"]
                    .slice(0, this.state.childNumber)
                    .map((str) => (
                      <Child
                        key={str}
                        name={str}
                        data={this.state[str]}
                        change={this.change}
                      />
                    ))
                : ["one", "two", "three"].map((str) => (
                    <Child
                      key={str}
                      name={str}
                      data={this.state[str]}
                      change={this.change}
                    />
                  ))}
            </div>
            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <div className="character-rules">
              <p>* scroll down to view all children</p>
              <p>
                Choose gender, skin color, hair color and clothing color for
                each child
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default Children;

const numbers = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
};
