import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addMotifToIdeaBox,
         removeMotifFromIdeaBox,
         setNotification,
         sendAnalyticsEvent } from '../actions';
import IdeaBox from '../components/generator/IdeaBox';

const mapStateToProps = (state) => {
  return {
    allMotifs: state.generator.library.Motifs,
    ideaBox: state.generator.ideaBox,
    belt: state.generator.belt,
    restrictions: state.generator.library.Restrictions
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    addMotifToIdeaBox: addMotifToIdeaBox,
    removeMotifFromIdeaBox: removeMotifFromIdeaBox,
    setNotification: setNotification,
    sendAnalyticsEvent: sendAnalyticsEvent
  }, dispatch);
}

const IdeaBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdeaBox)

export default IdeaBoxContainer;
