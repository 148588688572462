import React, { Component } from "react";
import PersonalizedData from "./PersonalizedData";
import "../../styles/preview-motif.scss";
import initSVG from "../../helpers/customMotifs/initSVG";
import { CHUNK_SIZE_SVG } from "../../helpers/constants";
import getPngFromSvg from "../../helpers/customMotifs/getPngFromSvg";

class PreviewMotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      svgConfig: this.props.svgConfig,
      imgSource: "",
    };
  }
  
  render() {
    let className = this.props.motif.upcharge > 0 ? " premium" : "";
    className =
      this.props.motif.dbid.indexOf("PL") >= 0
        ? className + " premium"
        : className;

    return (
      <div className={"preview-motif" + className}>
        {this.state.svgConfig ? (
          <img
            src={getPngFromSvg(this.state.svgConfig)}
            alt=""
            onMouseDown={this.handleDown}
            onClick={this.handleClick}
          />
        ) : (         
          <img
            src={
              "https://lifebelt-new.s3.us-east-2.amazonaws.com/" +
              this.props.motif.filename
            }
            alt=""
          ></img>
        )}

        {this.props.motif.upcharge > 0 ? (
          <span className="premium-tag">Premium</span>
        ) : null}
        {this.props.motif.dbid.indexOf("PL") >= 0 ? (
          <span className="premium-tag">Personalized</span>
        ) : null}
        {this.props.showCustomInfo && this.props.personalizedData ? (
          <PersonalizedData data={this.props.personalizedData} />
        ) : null}
      </div>
    );
  }
}

export default PreviewMotif;
