import React, { Component } from 'react'

class NewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    this.setState({
      name: ""
    });
    this.props.adminCreateCategory(this.state);
  }

  render() {
    return (
      <div id="new-category">
        <input className="form-control" type="text" name="name" placeholder="New category name" value={this.state.name} onChange={this.handleChange} />
        <button className="btn btn-success" onClick={this.handleSubmit}>Create Category</button>
      </div>
    )
  }
}

export default NewCategory;
