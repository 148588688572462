import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { buildSelectOptions } from '../../helpers/index.js';


class EditMotif extends Component {
  constructor(props) {
    super(props);
    this.state = {file: null, newSubcategory: ""};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.addNewSubcategory = this.addNewSubcategory.bind(this);
    this.deleteSubcategory = this.deleteSubcategory.bind(this);
  }

  componentDidMount() {
    this.setState(this.props.motifs.byId[this.props.match.params.id]);
  }

  componentDidUpdate(prevProps) {
    if (this.props.motifs !== prevProps.motifs || this.props.match.params.id !== prevProps.match.params.id) {
      this.setState(this.props.motifs.byId[this.props.match.params.id]);
    }
  }

  handleChange(e) {
    if (e.target.type === "file") {
      this.setState({
          file: e.target.files[0]
      })
    } else {
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value
      });
    }
  }

  addNewSubcategory(e) {
    if (e.target.value !== "" && (this.state.subcategories.indexOf(parseInt(e.target.value)) === -1)) {
      this.setState({
        subcategories: [...this.state.subcategories, parseInt(e.target.value)],
        newSubcategory: ""
      });
    }
  }

  deleteSubcategory(id) {
    const newSubArr = this.state.subcategories;
    this.state.subcategories.splice(this.state.subcategories.indexOf(parseInt(id)), 1);
    this.setState({
      ...this.state,
      subcategories: newSubArr
    });
  }

  handleDelete() {
    this.props.adminDeleteMotif(this.state.id);
    this.props.history.push("/admin/motifs");
  }

  handleSubmit() {
    // Reassign obj
    var updatedMotif = Object.assign({}, this.state);

    if (updatedMotif.backgroundColors && updatedMotif.backgroundColors !== "" && typeof updatedMotif.backgroundColors === "string") {
      updatedMotif.backgroundColors = updatedMotif.backgroundColors.replace(/ /g, "").split(",").map(i => parseInt(i));
    }

    if (updatedMotif.adminTags && updatedMotif.adminTags !== "" && typeof updatedMotif.adminTags === "string") {
      updatedMotif.adminTags = updatedMotif.adminTags.replace(/ /g, "").split(",");
    }

    if (updatedMotif.searchTags && updatedMotif.searchTags !== "" && typeof updatedMotif.searchTags === "string") {
      updatedMotif.searchTags = updatedMotif.searchTags.replace(/ /g, "").split(",");
    }

    const data = new FormData();

    data.append('file', updatedMotif.file);
    data.append('name', updatedMotif.name);
    data.append('width', updatedMotif.width);
    data.append('height', updatedMotif.height);
    data.append('backgroundColors', updatedMotif.backgroundColors);
    data.append('upcharge', updatedMotif.upcharge);
    data.append('maxUses', updatedMotif.maxUses);
    data.append('colorKey', updatedMotif.colorKey);
    data.append('adminTags', updatedMotif.adminTags);
    data.append('subcategories', updatedMotif.subcategories);
    data.append('searchTags', updatedMotif.searchTags);
    data.append('priority', updatedMotif.priority);

    // submit update
    this.props.adminUpdateMotif(data, this.state.id);
    this.props.history.push("/admin/motifs");
  }

  render() {
    if (this.state.name) {
      return (
        <div id="edit-motif">
          <div className="cardbox">
            <div className="cardbox-header">
              <h4>Edit: {this.state.name}</h4>
              <Link className="close-link" to="/admin/motifs">cancel</Link>
              <p className="delete-motif" onClick={this.handleDelete}>delete motif</p>
            </div>
            <div className="flexbox">
              <div>
                <img src={"https://lifebelt-new.s3.us-east-2.amazonaws.com/" + this.state.filename} alt="" />
              </div>

              <div>
                <label>Upload New File</label>
                <input type="file" className="form-control-file" onChange={this.handleChange} name="file"/>
              </div>

              <div>
                <label>Motif Name</label>
                <input className="form-control" name="name" placeholder="Name" value={this.state.name ? this.state.name : ""} onChange={this.handleChange} />
              </div>

              <div>
                <label>Width</label>
                <input className="form-control" name="width" placeholder="Width" value={this.state.width  ? this.state.width : ""} onChange={this.handleChange} />
              </div>
              
              <div>
                <label>Height</label>
                <input className="form-control" name="height" placeholder="Height" value={this.state.height ? this.state.height : ""} onChange={this.handleChange} />
              </div>
              
              <div>
                <label>Background Colors</label>
                <input className="form-control" name="backgroundColors" placeholder='Bkg Colors (separate by comma)' value={this.state.backgroundColors ? this.state.backgroundColors : ""} onChange={this.handleChange} />
              </div>
              
              <div>
                <label>Upcharge</label>
                <input className="form-control" name="upcharge" placeholder="Upcharge" value={this.state.upcharge ? this.state.upcharge : ""} onChange={this.handleChange} />
              </div>
              
              <div>
                <label>Max Uses</label>
                <input className="form-control" name="maxUses" placeholder="Max Uses" value={this.state.maxUses ? this.state.maxUses : ""} onChange={this.handleChange} />
              </div>
              
              <div>
                <label>Admin Tags</label>
                <input className="form-control" name="adminTags" placeholder='Admin Tags (separate by comma)' value={this.state.adminTags ? this.state.adminTags : ""} onChange={this.handleChange} />
              </div>

              <div>
                <label>Search Tags</label>
                <input className="form-control" name="searchTags" placeholder='Search Tags (separate by comma)' value={this.state.searchTags ? this.state.searchTags : ""} onChange={this.handleChange} />
              </div>

              <div>
                <label>Priority</label>
                <input className="form-control" name="priority" placeholder='Priority' value={this.state.priority ? this.state.priority : ""} onChange={this.handleChange} />
              </div>
            </div>
            <div className="flexbox">
              <div>
                <label>Color Key</label>
                <textarea className="form-control" name="colorKey" placeholder="Color Key" value={this.state.colorKey ? this.state.colorKey : ""} onChange={this.handleChange}></textarea>
              </div>
            </div>
            <div>
              <label>Subcategories</label>
              <div className="edit-motif-subcategories">
                {this.state.subcategories ? this.state.subcategories.map((subcategoryId, index) => {
                  return (
                    <div key={"subcategory-" + subcategoryId}>
                      <p className="motif-subcategory">{this.props.subcategories.byId[subcategoryId].name}</p>
                      <p className="delete-subcategory" onClick={() => this.deleteSubcategory(subcategoryId)}>X</p>
                    </div>
                  )
                }) : null }
              </div>
              <select className="form-control" name="newSubcategory" value={this.state.newSubcategory} onChange={this.addNewSubcategory}>
                {buildSelectOptions(this.props.categories, this.props.subcategories)}
              </select>
            </div>

            <hr />
            <div className="flexbox">
              <button onClick={this.handleSubmit} className="btn btn-success">Update Motif</button>
            </div>
          </div>
        </div>
      )
    } else {
      return(<p>No Motif Selected</p>)
    }
  }
}

export default EditMotif