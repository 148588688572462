import React from "react";
import { CHUNK_SIZE_SVG } from "../constants";

const initSVG = (configs) => {
  const { pixelConfigs, width, height } = configs;
  const RECT_SIZE = `${CHUNK_SIZE_SVG}px`;
  
  const getRect = () => {
    return (
      <rect
        key={"shape"}
        x={1}
        y={1}
        width={(width + 1) * CHUNK_SIZE_SVG}
        height={88}
        fill={"transparent"}
        // fill={"#1e2e5a"}
      />
    );
  };
  let newPoss = pixelConfigs.map((rect, ind) => {
    let paddingTop = 0;

    switch (height) {
      case 22:
      case 21:
      case 20:
        paddingTop = 0;
        break;
      case 19:
      case 18:
        paddingTop = 1;
        break;
      case 17:
      case 16:
        paddingTop = 2;
        break;
      case 15:
      case 14:
        paddingTop = 3;
        break;
      case 13:
      case 12:
        paddingTop = 4;
        break;
      case 11:
      case 10:
        paddingTop = 5;
        break;
      case 9:
      case 8:
        paddingTop = 6;
        break;
      case 7:
      case 6:
        paddingTop = 7;
        break;
      case 5:
      case 4:
        paddingTop = 8;
        break;

      default:
        paddingTop = 0;
        break;
    }

    let x = rect.pos[0] * CHUNK_SIZE_SVG;
    let y = (paddingTop + rect.pos[1]) * CHUNK_SIZE_SVG;

    return (
      <rect
        key={ind}
        x={x}
        y={y}
        width={CHUNK_SIZE_SVG}
        height={CHUNK_SIZE_SVG}
        fill={rect.color}       
      />
    );
  });
  return [getRect(), ...newPoss];
  // return [...newPoss];
};

export default initSVG;
