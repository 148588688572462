import React, { Component } from 'react'

class NewRestriction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      restrictionA: "",
      restrictionB: "",
      message: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleSubmit() {
    this.props.adminCreateRestriction(this.state);
    this.setState({
      name: "",
      restrictionA: "",
      restrictionB: "",
      message: ""
    });
  }

  render() {
    return (
      <div id="new-restriction">
        <input className="form-control" name="name" id="name" value={this.state.name} onChange={this.handleChange} placeholder="Restriction name" />

        <input className="form-control" name="restrictionA" id="restrictionA" value={this.state.restrictionA} onChange={this.handleChange} placeholder="Admin Tag A (lowercase)" />

        <p className="restriction-comparison"> ==//== </p>

        <input className="form-control" name="restrictionB" id="restrictionB" value={this.state.restrictionB} onChange={this.handleChange} placeholder="Admin Tag B (lowercase)" />

        <input className="form-control message" name="message" id="message" value={this.state.message} onChange={this.handleChange} placeholder="Custom message" />

        <button className="btn btn-m0" onClick={this.handleSubmit}>Create Restriction</button>
      </div>
    )
  }
}

export default NewRestriction;
