import React, { Component } from 'react';
import ModalBackground from './ModalBackground';
import '../../styles/modal.scss';

class PersonalizationModal extends Component {
  render() {
    return (
      <div className="modal-wrapper">
        <ModalBackground closeModal={this.props.closeModal} />
        <div id="premium-modal" className="modal-content">
          <div>
            <p>Personalized images allow you to customize your Life Belt with
            things like initials, wedding dates, and representations for children.</p>
            
            <p>The graphics shown on your digital belt rendering won’t change to represent
            the letters, dates, or colors you enter, but the actual belt you receive will
            reflect all of your customization.</p>

            <div className="close-buttons">
              <button className="btn grey btn-m0" onClick={this.props.closeModal}>Close</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PersonalizationModal;
