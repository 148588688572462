import React, { Component } from "react";
import ModalBackground from "./ModalBackground";
import "../../styles/modal.scss";

class PersonInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorField: "",
      error: "",
      first: "",
      last: "",
      email: "",
      emailVerify: "",
      phone: "",
      notes: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.userInfo) {
      this.setState({ ...this.props.userInfo });
    }
  }

  handleChange(e) {
    var newState = {};

    if (
      e.target.name === "first" &&
      e.target.value !== "" &&
      !e.target.value.match(/^[a-zA-Z]+$/)
    ) {
      this.setError(this.first.name, "Only letters allowed");
    } else if (
      e.target.name === "last" &&
      e.target.value !== "" &&
      !e.target.value.match(/^[a-zA-Z]+$/)
    ) {
      this.setError(this.last.name, "Only letters allowed");
    } else {
      this.setError();
      newState[e.target.name] = e.target.value;
      this.setState(newState);
    }
  }

  setError(errorField = "", error = "") {
    this.setState({
      errorField: errorField,
      error: error,
    });
  }

  validateFormAndSubmit() {
    this.setError();
    var shouldSubmit = true;

    if (this.first.value === "" || !this.first.value.match(/^[a-zA-Z]+$/)) {
      shouldSubmit = false;
      this.setError(this.first.name, "Must include a first name");
      this.first.focus();
      return;
    }

    if (this.last.value === "" || !this.last.value.match(/^[a-zA-Z]+$/)) {
      shouldSubmit = false;
      this.setError(this.last.name, "Must include a last name");
      this.last.focus();
      return;
    }

    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (
      this.email.value === "" ||
      !emailRegex.test(this.email.value.toLowerCase())
    ) {
      shouldSubmit = false;
      this.setError(this.email.name, "Must include a valid email address");
      this.email.focus();
      return;
    }

    if (
      this.emailVerify.value === "" ||
      this.emailVerify.value !== this.email.value
    ) {
      shouldSubmit = false;
      this.setError(this.emailVerify.name, "Emails do not match");
      this.emailVerify.focus();
      return;
    }

    if (this.phone.value === "") {
      shouldSubmit = false;
      this.setError(this.phone.name, "Must include a phone number");
      this.phone.focus();
      return;
    }

    const { errorField, error, ...userInfo } = this.state;

    if (shouldSubmit) {
      this.props.addUserInfo(userInfo);
      this.props.closeModal();
    }
  }

  render() {
    return (
      <div className="modal-wrapper">
        <ModalBackground closeModal={this.props.closeModal} />
        <div id="person-info-modal" className="modal-content">
          <h2>Your information</h2>
          <div id="submit-info-form">
            <div id="form">
              <div className="subrow">
                <input
                  onChange={this.handleChange}
                  value={this.state.first}
                  id="first"
                  name="first"
                  ref={(input) => {
                    this.first = input;
                  }}
                  className={
                    this.state.errorField === "first"
                      ? "form-control half error-field"
                      : "form-control half"
                  }
                  placeholder="First name"
                />
                <input
                  onChange={this.handleChange}
                  value={this.state.last}
                  id="last"
                  name="last"
                  ref={(input) => {
                    this.last = input;
                  }}
                  className={
                    this.state.errorField === "last"
                      ? "form-control half error-field"
                      : "form-control half"
                  }
                  placeholder="Last name"
                />
              </div>

              <input
                onChange={this.handleChange}
                value={this.state.email}
                id="email"
                name="email"
                ref={(input) => {
                  this.email = input;
                }}
                className={
                  this.state.errorField === "email"
                    ? "form-control error-field"
                    : "form-control"
                }
                placeholder="Email"
              />

              <input
                onChange={this.handleChange}
                value={this.state.emailVerify}
                id="emailVerify"
                name="emailVerify"
                ref={(input) => {
                  this.emailVerify = input;
                }}
                className={
                  this.state.errorField === "emailVerify"
                    ? "form-control error-field"
                    : "form-control"
                }
                placeholder="Verify email"
              />

              <input
                onChange={this.handleChange}
                value={this.state.phone}
                id="phone"
                name="phone"
                ref={(input) => {
                  this.phone = input;
                }}
                className={
                  this.state.errorField === "phone"
                    ? "form-control error-field"
                    : "form-control"
                }
                placeholder="Phone number"
              />
              {this.state.error !== "" ? (
                <p className="error">{this.state.error}</p>
              ) : null}
              <div className="subrow">
                <button
                  className="btn btn-m0"
                  id="pay-button"
                  style={{ width: "245px", marginRight: "10px" }}
                  onClick={this.validateFormAndSubmit.bind(this)}
                >
                  <p className="main">Save</p>
                </button>
                <button
                  className="btn grey btn-m0"
                  id="pay-button"
                  style={{ width: "245px" }}
                  onClick={this.props.closeModal}
                >
                  <p className="main">Cancel</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonInfoModal;
