import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestLibrarySuccess,
         submitBelt,
         submitDraft,
         resetCompletedOrder,
         sendBulkAnalyticsEvent } from '../actions';
import Submit from '../components/generator/Submit';

const mapStateToProps = (state) => {
  return {
    allMotifs: state.generator.library.Motifs,
    belt: state.generator.belt,
    userInfo: state.generator.userInfo,
    ideaBox: state.generator.ideaBox,
    completedOrder: state.generator.completedOrder
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    requestLibrarySuccess: requestLibrarySuccess,
    submitBelt: submitBelt,
    submitDraft: submitDraft,
    resetCompletedOrder: resetCompletedOrder,
    sendBulkAnalyticsEvent: sendBulkAnalyticsEvent
  }, dispatch);
}

const SubmitContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Submit)

export default SubmitContainer;
