import React, { Component } from 'react';
import '../../styles/motif.scss';
import MotifInfo from './MotifInfo';
import MotifTools from './MotifTools';
import MotifPersonalization from './MotifPersonalization';
import BlockInitials from './customMotifs/BlockInitials';
import BoldInitials from './customMotifs/BoldInitials';
import GreekInitials from './customMotifs/GreekInitials';
import ClassicInitials from './customMotifs/ClassicInitials';
import InitialsWithDate from './customMotifs/InitialsWithDate';
import WeddingRingsWithDate from './customMotifs/WeddingRingsWithDate';
import HeartWithInitials from './customMotifs/HeartWithInitials';
import TwoStackedInitials from './customMotifs/TwoStackedInitials';
import ThreeStackedInitials from './customMotifs/ThreeStackedInitials';
import FourStackedInitials from './customMotifs/FourStackedInitials';
import FiveStackedInitials from './customMotifs/FiveStackedInitials';
import SixStackedInitials from './customMotifs/SixStackedInitials';
import DateMotif from './customMotifs/DateMotif';
import Children from './customMotifs/Children';

import PersonalizedData from './PersonalizedData';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import flow from 'lodash/flow';
import { motifRand } from '../../helpers';
import getConfigs from '../../helpers/customMotifs/getConfigs';
import getConfigs10px from '../../helpers/customMotifs/getConfigs10px';
import getCompoundConfigs from '../../helpers/customMotifs/getCompoundConfigs';
import getDateCompoundConfigs from '../../helpers/customMotifs/getDateCompoundConfigs';
import getHeartCompoundConfigs from '../../helpers/customMotifs/getHeartCompoundConfigs';
import getChildConfigs from '../../helpers/customMotifs/getChildConfigs';

import getPngFromSvg from '../../helpers/customMotifs/getPngFromSvg';
import { validatePersonMotifAddToBelt } from '../../helpers';

class Motif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTools: false,
      showInfo: false,
      showEdit: false,
      showSVG: false,
      dataType: null,
      svgConfig: null,
      submission: null,
      validData: false,
      // imgSource: "",
    };

    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.updateMotifOnBelt = this.updateMotifOnBelt.bind(this);
  }

  componentDidMount() {
    if (this.props.personalizedData && this.props.personalizedData.type) {
      this.setState({
        dataType: this.props.personalizedData.type,
      });
    }
    if (this.props.personalizedData && this.props.personalizedData.valid) {
      this.setSVGConfig(this.props.personalizedData);
    }
    if (
      this.props.personalizedData &&
      this.props.personalizedData.isFirstLoad
    ) {
      this.setState({
        showEdit: true,
      });
    } else {
      this.setState({
        showEdit: false,
        dataType: null,
      });
    }
  }

  updateMotifOnBelt(submission) {
    this.setSVGConfig(submission);

    this.setState({ showTools: false, showInfo: false, showEdit: false });
  }

  toggleEdit() {
    this.setState({
      showEdit: !this.state.showEdit,
      showTools: false,
      showInfo: false,
    });
  }

  modifyKeys(obj) {
    return Object.keys(obj)
      .filter((key) => key !== 'color')
      .map((key) => obj[key]);
  }

  getMotifsArr(keys, categoryName) {
    let motifsArr = [];
    keys.forEach((key) => {
      let curMotif;
      if (key) {
        curMotif = this.props.allPersonMotifs.find(
          (mot) =>
            mot.key.trim().toLowerCase() === key.trim().toLowerCase() &&
            categoryName.trim().toLowerCase() ===
              mot.categoryName.trim().toLowerCase()
        );
        motifsArr.push(curMotif);
      }
    });

    if (motifsArr.includes(undefined)) {
      console.log('motifsArr', motifsArr);
      this.setState({ validData: false });
      return [];
    } else {
      return motifsArr;
    }
  }

  setSVGConfig(submission) {
    const { type, data, valid } = submission;
    this.setState({ validData: valid });
    let configs;
    let date;

    let keysArr;

    if (typeof data.initials === 'object' && !Array.isArray(data.initials)) {
      keysArr = Object.keys(data.initials);
    }

    let objCompound = {};
    let arrs = {};

    // update Motif On Belt
    const updateSVGConfigs = (configs) => {
      const { motifId, motifRand } = this.props;
      if (
        validatePersonMotifAddToBelt(
          this.props.beltWithMotifs,
          this.props.setNotification,
          motifRand,
          configs.width,
          type
        )
      ) {
        this.setState({
          svgConfig: configs,
        });
        this.props.updateMotifOnBelt(motifId, motifRand, submission, configs);
      }
    };

    // get configs for compounds
    let getConfigObj = (lettCategory) => {
      keysArr.forEach((key) => {
        arrs[key] = this.modifyKeys(data.initials[key]);
        objCompound[key] = getConfigs10px(
          this.getMotifsArr(arrs[key], lettCategory),
          data.initials[key].color,
          lettCategory
        );
      });
      configs = getCompoundConfigs(objCompound);
      if (configs) {
        updateSVGConfigs(configs);
      }
    };

    switch (type) {
      case 'blockInitials':
        configs = getConfigs10px(
          this.getMotifsArr(data.initials, 'Letters_10px'),
          data.color,
          'Letters_10px'
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'boldInitials':
        configs = getConfigs(
          this.getMotifsArr(data.initials, 'Letters_14px'),
          data.color,
          false,
          2
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'greekInitials':
        configs = getConfigs(
          this.getMotifsArr(data.initials, 'Greek_letters'),
          data.color
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'initialsWithDate':
        date = String(data.date).replace(/,/g, '-').split('');
        let configsDate = getConfigs(
          this.getMotifsArr(date, 'Numbers_5px'),
          data.color,
          true
        );
        let configsInitials = getConfigs10px(
          this.getMotifsArr(data.initials, 'Letters_8px'),
          data.color,
          'Letters_8px'
        );
        configs = getDateCompoundConfigs(
          {
            top: configsInitials,
            bottom: configsDate,
          },
          'initialsWithDate'
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'date':
        date = String(data.date).replace(/,/g, '-').split('');
        configs = getConfigs(
          this.getMotifsArr(date, 'Numbers_6px'),
          data.color,
          true
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'weddingRingsWithDate':
        date = String(data.date).replace(/,/g, '-').split('');
        let dateConfigs = getConfigs(
          this.getMotifsArr(date, 'Numbers_5px'),
          data.color,
          true
        );
        let ringMotif = this.props.allPersonMotifs.find(
          (mot) =>
            mot.key.trim().toLowerCase() === 'rings' &&
            mot.categoryName.trim().toLowerCase() === 'rings'
        );
        let configsRings = ringMotif
          ? {
              pixelConfigs: ringMotif.pixelConfigs,
              width: ringMotif.width,
              height: ringMotif.height,
            }
          : {};

        configs = getDateCompoundConfigs(
          {
            top: configsRings,
            bottom: dateConfigs,
          },
          'weddingRingsWithDate'
        );
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'heartWithInitials':
        let heartInits = [data.initials[0], 'plus', data.initials[1]];
        let letConfigs = getConfigs(
          this.getMotifsArr(heartInits, 'Letters_5px'),
          data.color,
          true
        );
        let heartMotif = this.props.allPersonMotifs.find(
          (mot) =>
            mot.key.trim().toLowerCase() === 'heart' &&
            mot.categoryName.trim().toLowerCase() === 'heart'
        );
        let configsHeart = heartMotif
          ? {
              pixelConfigs: heartMotif.pixelConfigs,
              width: heartMotif.width,
              height: heartMotif.height,
            }
          : {};

        configs = getHeartCompoundConfigs({
          heart: configsHeart,
          letters: letConfigs,
        });
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      case 'twoStackedInitials':
        getConfigObj('Letters_8px');
        break;
      case 'threeStackedInitials':
        getConfigObj('Letters_5px');
        break;
      case 'fourStackedInitials':
        getConfigObj('Letters_5px');
        break;
      case 'fiveStackedInitials':
        getConfigObj('Letters_5px');
        break;
      case 'sixStackedInitials':
        getConfigObj('Letters_5px');
        break;
      case 'children':
        const childMotifs = this.props.allPersonMotifs.filter(
          (motif) =>
            motif.categoryName.trim().toLowerCase() === 'children' &&
            (motif.key.trim().toLowerCase() === 'boy' ||
              motif.key.trim().toLowerCase() === 'girl')
        );

        configs = getChildConfigs(data, childMotifs);
        if (configs) {
          updateSVGConfigs(configs);
        }
        break;
      default:
        return null;
    }
  }

  renderPersonalizedMotif() {
    if (this.props.personalizedData === null) {
      return null;
    }

    switch (this.props.personalizedData.type) {
      case 'blockInitials':
        return (
          <BlockInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'boldInitials':
        return (
          <BoldInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'greekInitials':
        return (
          <GreekInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'classicInitials':
        return (
          <ClassicInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'initialsWithDate':
        return (
          <InitialsWithDate
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'date':
        return (
          <DateMotif
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'weddingRingsWithDate':
        return (
          <WeddingRingsWithDate
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'heartWithInitials':
        return (
          <HeartWithInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'twoStackedInitials':
        return (
          <TwoStackedInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'threeStackedInitials':
        return (
          <ThreeStackedInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'fourStackedInitials':
        return (
          <FourStackedInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'fiveStackedInitials':
        return (
          <FiveStackedInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'sixStackedInitials':
        return (
          <SixStackedInitials
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      case 'children':
        return (
          <Children
            setNotification={this.props.setNotification}
            toggle={this.toggleEdit}
            updateMotifOnBelt={this.updateMotifOnBelt}
            data={this.props.personalizedData}
          />
        );
      default:
        return null;
    }
  }

  handleEnter() {
    if (this.props.listId === 'library') {
      this.setState({
        showInfo: true,
      });
    } else if (this.props.listId === 'belt') {
      this.setState({
        showInfo: true,
        showTools: true,
      });
    } else {
      this.setState({
        showTools: true,
      });
    }
  }

  handleLeave() {
    this.setState({
      showTools: false,
      showInfo: false,
    });
  }

  handleDown() {
    this.setState({
      showTools: false,
      showInfo: false,
      showEdit: false,
    });
  }

  handleClick() {
    // Add motif to belt on click from library or ideaBox (when clickToAdd function present);
    if (
      this.props.clickToAdd &&
      this.props.ideaBox &&
      this.props.addMotifToIdeaBox
    ) {
      this.props.clickToAdd(this.props.motif.id, motifRand());
      if (this.props.ideaBox.motifs.indexOf(this.props.motif.id) === -1) {
        this.props.addMotifToIdeaBox(this.props.motif.id);
      }
    }
  }

  render() {
    if (!this.props.motif) {
      localStorage.removeItem('sbg_s');
    }
    const { isDragging, connectDragSource, connectDropTarget } = this.props;
    const hideWhenDragged =
      this.props.listId !== 'library' && this.props.listId !== 'ideabox';
    const opacity = isDragging && hideWhenDragged ? 0 : 1;
    const zIndex =
      this.props.listId === 'library' ? 1000 - this.props.zIndex : 100;
    var className = ' ' + this.props.listId;
    className =
      this.props.motif && this.props.motif.upcharge > 0
        ? className + ' premium'
        : className;
    className =
      this.props.motif && this.props.motif.dbid.indexOf('PL') >= 0
        ? className + ' premium'
        : className;
    className = this.state.showInfo ? className + ' hover' : className;

    return connectDragSource(
      connectDropTarget(
        <div
          className={'motif' + className}
          style={{ opacity, zIndex }}
          onMouseEnter={this.handleEnter}
          onMouseLeave={this.handleLeave}
        >
          {this.state.svgConfig && this.state.validData ? (
            <img
              src={getPngFromSvg(this.state.svgConfig)}
              alt=''
              onMouseDown={this.handleDown}
              onClick={this.handleClick}
            />
          ) : this.props.motif && this.props.motif.filename ? (
            <>
              <img
                src={
                  'https://lifebelt-new.s3.us-east-2.amazonaws.com/' +
                  this.props.motif.filename
                }
                alt=''
                onMouseDown={this.handleDown}
                onClick={this.handleClick}
              />
            </>
          ) : null}

          {this.state.showTools ? (
            <MotifTools
              removeMotif={this.props.removeMotif}
              index={this.props.index}
            />
          ) : null}
          {this.props.motif && this.props.motif.upcharge > 0 ? (
            <span className='premium-tag'>Premium</span>
          ) : null}
          {this.props.motif && this.props.motif.dbid.indexOf('PL') >= 0 ? (
            <span className='premium-tag'>Personalized</span>
          ) : null}
          {this.state.showInfo ? (
            <MotifInfo
              listId={this.props.listId}
              motif={this.props.motif}
              showPremiumModal={this.props.showPremiumModal}
              showPersonalizationModal={this.props.showPersonalizationModal}
            />
          ) : null}
          {this.props.personalizedData && this.props.listId === 'belt' ? (
            <MotifPersonalization
              toggle={this.toggleEdit}
              data={this.props.personalizedData}
            />
          ) : null}
          {this.state.showEdit && this.props.listId === 'belt'
            ? this.renderPersonalizedMotif()
            : null}
          {this.props.showCustomInfo && this.props.personalizedData ? (
            <PersonalizedData data={this.props.personalizedData} />
          ) : null}
        </div>
      )
    );
  }
}

const motifSource = {
  beginDrag(props, monitor, component) {
    return {
      index: props.index,
      listId: props.listId,
      motif: props.motif,
    };
  },
  endDrag(props, monitor, component) {},
};

const motifTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    const sourceListId = monitor.getItem().listId;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get horizontal middle
    const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientX = clientOffset.x - hoverBoundingRect.right;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return;
    }

    // Time to actually perform the action
    if (props.listId === sourceListId && props.listId === 'belt') {
      props.moveMotif(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
};

export default flow(
  DropTarget('MOTIF', motifTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })),
  DragSource('MOTIF', motifSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))
)(Motif);
