import { authHeader, handleResponse } from '../../helpers/index.js';

export const ADMIN_RECEIVE_SUBMITTED_BELTS = "ADMIN_RECEIVE_SUBMITTED_BELTS";
export const ADMIN_RECEIVE_DRAFTED_BELTS = "ADMIN_RECEIVE_DRAFTED_BELTS";

// Submitted Belts
export function adminRequestSubmittedBelts() {
  return function(dispatch) {
    return fetch("/api/admin/submitted-belts", {
      headers: authHeader()
    })
      .then(res => handleResponse(res))
      .then(submittedBelts => {
        if (submittedBelts) {
          dispatch(adminRecieveSubmittedBelts(submittedBelts))
        }
      });
  }
}

export function adminRecieveSubmittedBelts(submittedBelts) {
  return {
    type: ADMIN_RECEIVE_SUBMITTED_BELTS,
    payload: submittedBelts
  }
}

// Drafted Belts
export function adminRequestDraftedBelts() {
  return function(dispatch) {
    return fetch("/api/admin/submitted-belts/drafts", {
      headers: authHeader()
    })
      .then(res => handleResponse(res))
      .then(draftedBelts => {
        if (draftedBelts) {
          dispatch(adminRecieveDraftedBelts(draftedBelts))
        }
      });
  }
}

export function adminRecieveDraftedBelts(draftedBelts) {
  return {
    type: ADMIN_RECEIVE_DRAFTED_BELTS,
    payload: draftedBelts
  }
}

export function resendAdminEmail(beltId) {
  return function(dispatch) {
    return fetch("/api/generator/send", {
      method: "POST",
      body: JSON.stringify({id: beltId}),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(data => {
      console.log(data);
    });
  }
}

export function resendAdminDraftEmail(beltId) {
  return function(dispatch) {
    return fetch("/api/generator/send-draft", {
      method: "POST",
      body: JSON.stringify({id: beltId}),
      headers: {
        'Content-Type': 'application/json',
        ...authHeader()
      }
    })
    .then(res => handleResponse(res))
    .then(data => {
      console.log(data);
    });
  }
}
