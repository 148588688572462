import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import GeneratorContainer from '../containers/GeneratorContainer';
import AdminContainer from '../containers/AdminContainer';
import LoginContainer from '../containers/LoginContainer';
import SubmitContainer from '../containers/SubmitContainer';
import Design from "./generator/Design";
import FAQ from './generator/FAQ';
import Contact from './generator/Contact';
import { PrivateRoute } from '../helpers/index.js';

import '../styles/root.scss';

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <div>
        <Route path="/" exact component={GeneratorContainer} />
        <Route path="/submit" component={SubmitContainer} />
        <Route path="/design" component={Design} />
        <Route path="/login" component={LoginContainer} />
        <PrivateRoute path="/admin" component={AdminContainer} />

        <Route path="/faq" exact component={FAQ} />
        <Route path="/contact" exact component={Contact} />
      </div>
    </Router>
  </Provider>
)

export default Root
