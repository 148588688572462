import { authHeader, handleResponse } from '../../helpers/index.js';

export const ADMIN_RECEIVE_ANALYTICS = "ADMIN_RECEIVE_ANALYTICS";
export const ADMIN_RECEIVE_FULL_ANALYTICS = "ADMIN_RECEIVE_FULL_ANALYTICS";

export function adminRequestAnalytics() {
  return function(dispatch) {
    return fetch("/api/admin/motifs/analytics", {
      headers: authHeader()
    })
    .then(res => handleResponse(res, dispatch))
    .then(json => {
      dispatch(adminReceiveAnalytics(json));
    });
  }
}

export function adminRequestFullAnalytics() {
  return function(dispatch) {
    return fetch("/api/admin/motifs/full-analytics", {
      headers: authHeader()
    })
    .then(res => handleResponse(res, dispatch))
    .then(json => {
      dispatch(adminReceiveFullAnalytics(json));
    });
  }
}

export function adminReceiveAnalytics(data) {
  return {
    type: ADMIN_RECEIVE_ANALYTICS,
    payload: data
  }
}

export function adminReceiveFullAnalytics(data) {
  return {
    type: ADMIN_RECEIVE_FULL_ANALYTICS,
    payload: data
  }
}
