import React from 'react';

const MobileBlock = () => {
  return (
    <div id="mbnycu">
      <div>
        <img className="logo" src="/mobile.jpg" alt="" />
      </div>
    </div>
  )
}

export default MobileBlock;