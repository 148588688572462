import React, { Component } from "react";
import NewPersonCategory from "./NewPersonCategory";
import EditPersonCategory from "./EditPersonCategory";

const CategoryItem = ({ category, deleteCategory, adminUpdateCategory }) => {
  return (
    <div className="category-container">
      <div className="category">
        <p>{category.name}</p>
        <EditPersonCategory
          id={category.id}
          name={category.name}
          adminUpdateCategory={adminUpdateCategory}
        />
        <p className="delete" onClick={() => deleteCategory(category.id)}>
          Delete
        </p>
      </div>
    </div>
  );
};

class AdminPersonCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allIds: [],
    };
   
    this.deleteCategory = this.deleteCategory.bind(this);
  }

  componentDidMount() {
    this.setState({
      allIds: this.props.categories.allIds,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.categories.allIds !== prevProps.categories.allIds) {
      this.setState({
        allIds: this.props.categories.allIds,
      });
    }
  }

  deleteCategory(id) {
    this.props.adminDeleteCategory(id);
  }

  render() {   
    return (
      <div id="admin-categories">
        <NewPersonCategory adminCreateCategory={this.props.adminCreateCategory} />
        <hr />
        {this.state.allIds.length > 0
          ? this.state.allIds.map((id, index) => (
              <CategoryItem
                key={this.props.categories.byId[id].id}
                index={index}
                category={this.props.categories.byId[id]}
                style={{ zIndex: 100000 }}
                deleteCategory={this.deleteCategory}
                adminUpdateCategory={this.props.adminUpdateCategory}
              />
            ))
          : null}
      </div>
    );
  }
}

export default AdminPersonCategory;
