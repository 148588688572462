const getConfigs = ( motifsArr = [], color, dash, padding = 1) => {
  let curLeftPad = 0;
  if (!color) {
    color = "#ffffff";
  }
  let minLetHeight = Infinity;
  let maxLetHeight = 0;

  let configs = [];  

  if (dash) {
    motifsArr.forEach((motif) => {
      if (motif.height < minLetHeight) {
        minLetHeight = motif.height;
      }
      if (motif.height > maxLetHeight) {
        maxLetHeight = motif.height;
      }
    });
  }
 
  motifsArr.forEach((curMotif) => {
    
    if (curMotif && curMotif.pixelConfigs) {
      let curConfigs = curMotif.pixelConfigs;
      let minX = Infinity;
      let minY = Infinity;

      curConfigs.forEach((item) => {
        if (item.pos[0] < minX) {
          minX = item.pos[0];
        }
        if (item.pos[1] < minY) {
          minY = item.pos[1];
        }
      });
      let difX = minX - 1;
      let difY = minY - 1;

      curConfigs.forEach((item) => {
        let pos;

        if (dash && minLetHeight === curMotif.height && maxLetHeight) {
          pos = [
            item.pos[0] - difX + curLeftPad,
            item.pos[1] - difY + (maxLetHeight - minLetHeight) / 2,
          ];
        } else {
          pos = [item.pos[0] - difX + curLeftPad, item.pos[1] - difY];
        }
        configs.push({
          ...item,
          pos,
          color: color,
        });
      });
    }

    curLeftPad =
      curLeftPad + (curMotif && curMotif.width ? curMotif.width : 0) + padding;
  });

  //compound width, height

  let minX = Infinity;
  let maxX = 0;
  let minY = Infinity;
  let maxY = 0;

  configs.forEach((el) => {
    if (el.pos[0] < minX) {
      minX = el.pos[0];
    }
    if (el.pos[0] > maxX) {
      maxX = el.pos[0];
    }
    if (el.pos[1] < minY) {
      minY = el.pos[1];
    }
    if (el.pos[1] > maxY) {
      maxY = el.pos[1];
    }
  });

  let width = maxX - minX + 1;
  let height = maxY - minY + 1;
 
  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getConfigs;
