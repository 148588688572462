import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { nextPopup, closePopup } from '../../actions';

import '../../styles/popup.scss';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupOrder: [
        {
          content: "Next " + String.fromCharCode(8594),
          header: "Frequently asked questions",
          text: "Learn about timing, pricing, design tips, and more!"
        },
        {
          content: "Next " + String.fromCharCode(8594),
          header: "Motif Library",
          text: " Browse our catalog or search by keyword.  Any items you click will be added to your idea box for easy access."
        },
        {
          content: "Finish " + String.fromCharCode(8594),
          header: "Sizing",
          text: "You’ll need to confirm the belt size before completing your purchase.  Please read our instructions carefully!"
        },
        {
          content: "Finish " + String.fromCharCode(8594),
          header: "Your info",
          text: "You’ll need to fill this form carefully!"
        }
      ]
    }

    this.next = this.next.bind(this);
    this.close = this.close.bind(this);
  }

  next() {
    this.props.nextPopup(this.props.popupOrder + 1);
  }

  close() {
    this.props.closePopup();
  }

  render() {
    return this.props.popup.show && this.props.popup.index === this.props.popupOrder ? (
      <div key={this.props.popupOrder} id="popup" style={{top: this.props.top, left: this.props.left}} className={this.props.location} >
        <div className="popup-box">
          <div className="content">
            <img src="/close_icon.png" alt="" onClick={this.close} />
            <h4 className="popup-header">{this.state.popupOrder[this.props.popupOrder].header}</h4>
            <p className="popup-text">{this.state.popupOrder[this.props.popupOrder].text}</p>
          </div>
          <button onClick={this.next}>{this.state.popupOrder[this.props.popupOrder].content}</button>
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    popup: state.generator.popup
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    nextPopup: nextPopup,
    closePopup: closePopup
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);
