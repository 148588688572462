import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class AdminSubmittedBelts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showStart: 0,
      showEnd: 500,
      showText: "Showing belts 1-500, click to show all"
    }

    this.showAll = this.showAll.bind(this);
  }

  componentDidMount() {
    this.props.adminRequestSubmittedBelts();
  }

  showAll() {
    this.setState({
      showEnd: 10000000000000000,
      showText: "Showing all submitted belts (" + this.props.submittedBelts.length + " belts)"
    });
  }

  render() {
    return (
      <div id="admin-submitted-belts">
        <p style={{marginBottom: "10px", color: "blue", textDecoration: "underline"}} onClick={this.showAll}>{this.state.showText}</p>
        <table className="submitted-belt-list">
          <thead>
            <tr>
              <th>Order #</th>
              <th>Name</th>
              <th>Date</th>
              <th>Email</th>
              <th>Notes</th>
              <th>Price</th>
              <th>Size</th>
              <th># Motifs</th>
              <th># Premium</th>
              <th># Custom</th>
              <th>Resend Admin Email</th>
              <th>View</th>
            </tr>
          </thead>

          <tbody>
            {
              this.props.submittedBelts.slice(this.state.showStart, this.state.showEnd).map(belt => {
                return (
                  <tr key={belt.id}>
                    <td style={{fontWeight: "bold"}}>{belt.orderNumber}</td>
                    <td style={{fontWeight: "bold", maxWidth: "160px", wordBreak: 'break-word'}}>{belt.firstName} {belt.lastName}</td>
                    <td>{new Date(belt.createdAt).toLocaleDateString("en-US")}</td>
                    <td style={{maxWidth: "250px", wordBreak: 'break-word'}}>{belt.email}</td>
                    <td style={{maxWidth: "250px", wordBreak: 'break-word'}}>{belt.notes}</td>
                    <td>${belt.beltPrice}.00</td>
                    <td>{belt.beltSize} in</td>
                    <td>{belt.motifIds.length}</td>
                    <td>{belt.numberPremium}</td>
                    <td>{belt.numberCustom}</td>
                    <td className="resend" onClick={() => this.props.resendAdminEmail(belt.id)}>Resend Admin</td>
                    <td><Link to={"/?completed=" + belt.orderNumber}>View</Link></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
    )
  }
}

export default AdminSubmittedBelts;
