import React, { Component } from 'react';
import Motif from './Motif';
import { DropTarget } from 'react-dnd';
import { validateAddToBelt } from '../../helpers';

class IdeaBox extends Component {
  clickToAdd(motifId, rand) {
    if (validateAddToBelt(motifId, rand, this.props.allMotifs, this.props.belt, this.props.restrictions, this.props.setNotification)) {
      this.props.sendAnalyticsEvent(motifId, "belt", null);
      this.props.addMotifToBelt(motifId, rand);
    }
  }

  pushMotif(motif) {
    if (this.props.ideaBox.motifs.indexOf(motif.id) === -1) {
      this.props.sendAnalyticsEvent(motif.id, "idea_box", null);
      this.props.addMotifToIdeaBox(motif.id);
    } else {
      this.props.setNotification("Motif already in Idea Box", "error");
    }
  }

  handleRemove(motifIndex) {
    if (this.props.belt.motifs.map((x) => x[0]).indexOf(this.props.ideaBox.motifs[motifIndex]) === -1) {
      this.props.removeMotifFromIdeaBox(motifIndex);
    } else {
      this.props.setNotification("Cannnot remove motif from Idea Box if used in belt!", "error", 10000);
    }
  }

  render() {
    const { connectDropTarget } = this.props;

    return connectDropTarget(
      <div id="idea-box">
        <div id="idea-cont">
          <div id="idea-content">
            {this.props.ideaBox.motifs.length > 0 ? this.props.ideaBox.motifs.map((motifId, i) => {
              return (
                <Motif key={"motif-idea-" + motifId}
                       listId="ideabox"
                       index={i}
                       motif={this.props.allMotifs.byId[motifId]}
                       clickToAdd={this.clickToAdd.bind(this)}
                       addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                       ideaBox={this.props.ideaBox}
                       removeMotif={this.handleRemove.bind(this)} />
              );
            }) : null}
          </div>
        </div>
      </div>
    );
  }
}

const motifTarget = {
  drop(props, monitor, component) {
    const { id } = props;
    const sourceObj = monitor.getItem();    
    if ( id !== sourceObj.listId ) component.pushMotif(sourceObj.motif);
    return {
      listId: id
    };
  }
}

export default DropTarget("MOTIF", motifTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))(IdeaBox);
