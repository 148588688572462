import React, { Component } from "react";

class MotifInfo extends Component {
  render() {
    return (
      <div className="motif-info-container">
        <div className="motif-info">
          <p>
            {this.props.motif.name}
            {/* {this.props.motif.dbid.indexOf("PL") >= 0 &&
            this.props.listId === "belt" ? (
              <span
                style={{ marginLeft: "5px" }}
                className="why"
                onClick={() =>
                  this.props.showPersonalizationModal
                    ? this.props.showPersonalizationModal()
                    : null
                }
              >
                about
              </span>
            ) : null} */}
          </p>
          {/* {this.props.motif.dbid.indexOf("PL") >= 0 &&
          this.props.listId === "library" ? (
            <p className="upcharge">
              <span
                className="why"
                onClick={() =>
                  this.props.showPersonalizationModal
                    ? this.props.showPersonalizationModal()
                    : null
                }
              >
                about
              </span>
            </p>
          ) : null} */}
          {this.props.motif.upcharge ? (
            <p className="upcharge">
              add ${this.props.motif.upcharge}&nbsp;&nbsp;
              <span
                className="why"
                onClick={() =>
                  this.props.showPremiumModal
                    ? this.props.showPremiumModal()
                    : null
                }
              >
                why?
              </span>
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

export default MotifInfo;
