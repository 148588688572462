import React, { Component } from "react";

import PreviewMotif from "./PreviewMotif";

import "../../styles/submit-belt.scss";
import "../../styles/belt-sizing.scss";

class SubmitBelt extends Component {
  beltHeight() {
    const beltSize = this.props.belt.size;
    return "belt-size-" + beltSize;
  }

  hasPersonalized(motifs) {
    let count = 0;
    motifs.forEach((motif) => {
      if (motif[2]) {
        count += 1;
      }
    });
    return count;
  }

  render() {
   
    const beltSizeClass = this.beltHeight();
    const motifIds = this.props.belt.hasSet
      ? this.props.belt.motifs.map((x) => x[0])
      : this.props.completedOrder
      ? this.props.completedOrder.belt.motifIds
      : [];

    return (
      <div id="submit-belt">
        <div className="container">
          <h4>
            Your Finished Belt
            {this.hasPersonalized(this.props.belt.motifs) > 0 ? (
              <span>
                {" "}
                Click the "Show Personalized Info" toggle above to see a list of
                your personalized selections.
              </span>
            ) : null}
          </h4>
          <div id="belt-mock" className={beltSizeClass}>
            {motifIds.length > 0
              ? motifIds.map((motifId, i) => {
                  return (
                    // <PreviewMotif key={"belt-" + i} motif={this.props.allMotifs.byId[motifId]}  personalizedData={this.props.belt.motifs.length > 0 ? this.props.belt.motifs[i][2] : null} showCustomInfo={this.props.showCustomInfo} />

                    <PreviewMotif
                      key={"belt-" + i}
                      motif={this.props.allMotifs.byId[motifId]}
                      personalizedData={
                        this.props.belt.motifs.length > 0
                          ? this.props.belt.motifs[i][2]
                          : null
                      }
                      svgConfig={
                        this.props.belt.motifs.length > 0
                          ? this.props.belt.motifs[i][3]
                          : null
                      }
                      showCustomInfo={this.props.showCustomInfo}
                    />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitBelt;
