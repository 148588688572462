import React, { Component } from 'react';
import { Route } from 'react-router-dom';

import SubmitToolbar from './SubmitToolbar';
import SubmitBelt from './SubmitBelt';
import SubmitForm from './SubmitForm';
import SubmitComplete from './SubmitComplete';
import DraftSuccess from './DraftSuccess';
import MobileBlock from './MobileBlock';
import Contentful from "../../helpers/contentful";

import '../../styles/submit.scss';

class Submit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCustomInfo: false,
      contentful: new Contentful(),
      basePrice: null,
      link: ''
    };
   
    this.submitBelt = this.submitBelt.bind(this);
    this.submitDraft = this.submitDraft.bind(this);

    this.initContent();
  }

  initContent = async () => {
    let basePrice = await this.state.contentful.getEntry(
      '538ELQymmXp0rlJE83IqW9'
    );
    this.setState({ basePrice });
  };

  componentDidMount() {
    // Redirect to belt generator if not valid belt
    if (
      this.props.location.pathname === '/submit/done' &&
      !this.props.completedOrder
    ) {
      this.props.history.push('/');
    }
    if (!this.props.userInfo) {
      this.props.history.push('/');
    } else if (
      this.props.belt.motifs.length < 6 ||
      !this.props.belt.hasSet ||
      !(
        this.props.location.pathname === '/submit' ||
        this.props.location.pathname === '/submit/done'
      )
    ) {
      this.props.history.push('/');
    }
  }

  submitBelt(userInfo) {
    // Clear belt and add order #, pay link and email to done info
    const { first, last } = userInfo;

    const listPremium = this.props.belt.motifs
      .map((x) => this.props.allMotifs.byId[x[0]])
      .filter((x) => x.upcharge > 0)
      .map((x) => x.name.replace(/ /g, '_'))
      .join(',');

    const beltPrice =
      this.props.belt.motifs
        .map((x) => this.props.allMotifs.byId[x[0]].upcharge)
        .reduce((a, b) => a + b) + this.state.basePrice.price;

    const addPrice = beltPrice - this.state.basePrice.price;

    const orderNumber = `${first ? first : ''} ${last ? last : ''} ${String(
      Date.now()
    ).slice(7)} size ${this.props.belt.size}`

    const linkString =  `https://smathersandbranson.com/add-lifebelt?code=b-life-${
      this.props.belt.size
    }&name=${orderNumber.replace(/ /g, '_')}&link=` +
      encodeURIComponent(
        `${window.location.origin}/design?design=${orderNumber}`
      ) +
      `&ab=${addPrice.toString(2)}&premium=${listPremium}`
      this.setState({link: linkString})

    const submission = {
      orderNumber,
      beltSize: this.props.belt.size,
      beltPrice,
      motifs: this.props.belt.motifs.map(
        (x) => this.props.allMotifs.byId[x[0]]
      ),
      beltMotifs: this.props.belt.motifs,
      customMotifData: this.props.belt.motifs.map((x) => x[2]),
      numberCustom: this.props.belt.motifs.filter((x) => x[2] !== null).length,
      numberPremium: this.props.belt.motifs
        .map((x) => this.props.allMotifs.byId[x[0]].upcharge > 0)
        .filter((x) => x).length,
      linkString,
      ...userInfo,
    };

    this.sendBelt(submission, () => {
      this.props.history.push('/submit/done');

      window.open(linkString,
        '_blank'
      );
    });
  }

  async submitDraft(userInfo) {
    const { first, last } = userInfo;      
    const submission = {
      orderNumber: `${first ? first : ''} ${last ? last : ''} ${String(
        Date.now()
      ).slice(7)} size ${this.props.belt.size}`,
      beltSize: this.props.belt.size,
      beltPrice:
        this.props.belt.motifs
          .map((x) => this.props.allMotifs.byId[x[0]].upcharge)
          .reduce((a, b) => a + b) + this.state.basePrice.price,
      motifs: this.props.belt.motifs.map(
        (x) => this.props.allMotifs.byId[x[0]]
      ),
      beltMotifs: this.props.belt.motifs,
      customMotifData: this.props.belt.motifs.map((x) => x[2]),
      numberCustom: this.props.belt.motifs.filter((x) => x[2] !== null).length,
      numberPremium: this.props.belt.motifs
        .map((x) => this.props.allMotifs.byId[x[0]].upcharge > 0)
        .filter((x) => x).length,
      beltData: this.props.belt,
      ideaBox: this.props.ideaBox.motifs,
      ...userInfo,
    };

    this.sendDraft(submission, () => {
      this.props.history.push('/submit/draft-success');
    });
  }

  sendBelt(submission, callback) {
    this.props.sendBulkAnalyticsEvent(submission.motifs, 'submitted', null);
    this.props.submitBelt(submission);

    callback();
  }

  sendDraft(submission, callback) {
    this.props.sendBulkAnalyticsEvent(submission.motifs, 'drafted', null);
    this.props.submitDraft(submission);

    callback();
  }

  toggleCustomInfo() {
    this.setState({
      showCustomInfo: !this.state.showCustomInfo,
    });
  }

  render() {
    return (
      <div>
        <MobileBlock />
        <div id='submit'>
          <SubmitToolbar
            {...this.props}
            showCustomInfo={this.state.showCustomInfo}
            toggleCustomInfo={this.toggleCustomInfo.bind(this)}
          />

          <SubmitBelt
            {...this.props}
            showCustomInfo={this.state.showCustomInfo}           
          />

          <Route
            path='/submit'
            exact
            render={(props) => (
              <SubmitForm
                {...this.props}
                submitBelt={this.submitBelt}
                submitDraft={this.submitDraft}
              />
            )}
          />
          <Route
            path='/submit/done'
            exact
            render={(props) => <SubmitComplete {...this.props}  linkString={this.state.link} />}
          />
          <Route
            path='/submit/draft-success'
            exact
            render={(props) => <DraftSuccess {...this.props} />}
          />
        </div>
      </div>
    );
  }
}

export default Submit;
