import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminCreateMotif,
  adminRequestMotifs,
  adminUpdateMotif,
  adminRequestCategories,
  adminUpdateCategoryOrder,
  adminCreateCategory,
  adminDeleteCategory,
  adminUpdateCategory,
  adminAddSubcategory,
  adminDeleteSubcategory,
  adminUpdateSubcategory,
  adminDeleteMotif,
  adminCreateRestriction,
  adminRequestRestrictions,
  adminDeleteRestriction,
  logoutUser,
  adminRequestSubmittedBelts,
  adminRequestDraftedBelts,
  adminUploadCSV,
  adminUploadTXT,
  adminRequestAnalytics,
  adminUploadMotifImages,
  resendAdminEmail,
  resendAdminDraftEmail,
  closeAdminNotification,
  adminRequestFullAnalytics,

  adminCreatePersonMotif,
  adminRequestPersonMotifs,
  adminUpdatePersonMotif,
  adminDeletePersonMotif,
  adminRequestPersonCategories,
  adminCreatePersonCategory,
  adminDeletePersonCategory,
  adminUpdatePersonCategory,  
} from "../actions";
import Admin from "../components/admin/Admin";

const mapStateToProps = (state) => {
  return {
    motifs: state.admin.Motifs,
    personMotifs: state.admin.PersonMotifs,
    categories: state.admin.Categories,
    personCategories: state.admin.PersonCategories,
    subcategories: state.admin.Subcategories,
    restrictions: state.admin.Restrictions,
    submittedBelts: state.admin.SubmittedBelts,
    draftedBelts: state.admin.DraftedBelts,
    analytics: state.admin.Analytics,
    fullAnalytics: state.admin.FullAnalytics,
    notification: state.admin.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminCreateMotif: adminCreateMotif,
      adminRequestMotifs: adminRequestMotifs,
      adminUpdateMotif: adminUpdateMotif,
      adminRequestCategories: adminRequestCategories,
      adminUpdateCategoryOrder: adminUpdateCategoryOrder,
      adminCreateCategory: adminCreateCategory,
      adminDeleteCategory: adminDeleteCategory,
      adminUpdateCategory: adminUpdateCategory,
      adminAddSubcategory: adminAddSubcategory,
      adminDeleteSubcategory: adminDeleteSubcategory,
      adminUpdateSubcategory: adminUpdateSubcategory,
      adminDeleteMotif: adminDeleteMotif,
      adminCreateRestriction: adminCreateRestriction,
      adminRequestRestrictions: adminRequestRestrictions,
      adminDeleteRestriction: adminDeleteRestriction,
      logoutUser: logoutUser,
      adminRequestSubmittedBelts: adminRequestSubmittedBelts,
      adminRequestDraftedBelts: adminRequestDraftedBelts,
      adminUploadCSV: adminUploadCSV,
      adminUploadTXT: adminUploadTXT,
      adminRequestAnalytics: adminRequestAnalytics,
      adminUploadMotifImages: adminUploadMotifImages,
      resendAdminEmail: resendAdminEmail,
      resendAdminDraftEmail: resendAdminDraftEmail,
      closeAdminNotification: closeAdminNotification,
      adminRequestFullAnalytics: adminRequestFullAnalytics,

      adminCreatePersonMotif: adminCreatePersonMotif,
      adminRequestPersonMotifs: adminRequestPersonMotifs,
      adminUpdatePersonMotif: adminUpdatePersonMotif,
      adminRequestPersonCategories: adminRequestPersonCategories,
      adminCreatePersonCategory: adminCreatePersonCategory,
      adminDeletePersonCategory: adminDeletePersonCategory,
      adminUpdatePersonCategory: adminUpdatePersonCategory,
      adminDeletePersonMotif: adminDeletePersonMotif,
    },
    dispatch
  );
};

const AdminContainer = connect(mapStateToProps, mapDispatchToProps)(Admin);

export default AdminContainer;
