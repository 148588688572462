import React from "react";

const PersonalizedData = (props) => {
  if (props.data.type === "weddingRingsWithDate") {
    return (
      <div className="personalized-data-info">
        <p>
          <span style={{ fontWeight: "500", fontSize: "11px" }}>Date:</span>{" "}
          <span>{props.data.data.date.join("-")}</span>
        </p>
      </div>
    );
  }

  if (props.data.type === "date") {
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Color:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.color }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Date:</span>{" "}
            <span>{props.data.data.date.join("-")}</span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("oneChild") >= 0) {
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Hair:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.hair }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Clothing:
            </span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.clothing }}
            ></span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("children") >= 0) {
    let data = props.data.data;
    delete data.isFirstLoad;
    delete data.type;
    delete data.valid;
    let keys = Object.keys(data);

    return (
      <div className="personalized-data-info">
        {keys.map((key) => {
          return (
            <div key={key}>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Gender:
                </span>{" "}
                {data[key].gender.toUpperCase()}
              </p>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Skin:
                </span>{" "}
                <span
                  className="block"
                  style={{ background: data[key].skin }}
                ></span>
              </p>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Hair:
                </span>{" "}
                <span
                  className="block"
                  style={{ background: data[key].hair }}
                ></span>
              </p>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Clothing:
                </span>{" "}
                <span
                  className="block"
                  style={{ background: data[key].clothing }}
                ></span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  if (props.data.type.indexOf("StackedInitials") >= 0) {
    let data = props.data.data;
    delete data.isFirstLoad;
    delete data.type;
    delete data.valid;
    let keys = Object.keys(data.initials);

    return (
      <div className="personalized-data-info">
        {keys.map((key) => {
          return (
            <div key={key}>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Color:
                </span>{" "}
                <span
                  className="block"
                  style={{ background: data.initials[key].color }}
                ></span>
              </p>
              <p>
                <span style={{ fontWeight: "500", fontSize: "11px" }}>
                  Initials:
                </span>{" "}
                {data.initials[key].first.includes("R-")
                  ? data.initials[key].first.replace("R-", "")
                  : data.initials[key].first}{" "}
                {data.initials[key].second.includes("R-")
                  ? data.initials[key].second.replace("R-", "")
                  : data.initials[key].second}{" "}
                {data.initials[key].third.includes("R-")
                  ? data.initials[key].third.replace("R-", "")
                  : data.initials[key].third}{" "}
                {data.initials[key].fourth.includes("R-")
                  ? data.initials[key].fourth.replace("R-", "")
                  : data.initials[key].fourth}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  if (props.data.type.indexOf("initialsWithDate") >= 0) {
    const initials = props.data.data.initials.map((simbol) =>
      simbol.includes("R-") ? simbol.replace("R-", "") : simbol
    );
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Color:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.color }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Initials:
            </span>{" "}
            <span>{initials.join(" ")}</span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Date:</span>{" "}
            <span>{props.data.data.date.join("-")}</span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("blockInitials") >= 0) {
    const initials = props.data.data.initials.map((simbol) =>
      simbol.includes("R-") ? simbol.replace("R-", "") : simbol
    );
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Color:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.color }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Initials:
            </span>{" "}
            <span>{initials.join(" ")}</span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("boldInitials") >= 0) {
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Color:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.color }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Initials:
            </span>{" "}
            <span>{props.data.data.initials.join(" ")}</span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("greekInitials") >= 0) {
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>Color:</span>{" "}
            <span
              className="block"
              style={{ background: props.data.data.color }}
            ></span>
          </p>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Initials:
            </span>{" "}
            <span>{props.data.data.initials.join(" ")}</span>
          </p>
        </div>
      </div>
    );
  }

  if (props.data.type.indexOf("classicInitials") >= 0) {
    return (
      <div className="personalized-data-info">
        <p style={{ lineHeight: "1.6" }}>
          {props.data.data.initials[0]}{" "}
          <span
            style={{
              fontSize: "18px",
              lineHeight: "1",
              verticalAlign: "middle",
            }}
          >
            {props.data.data.initials[2]}
          </span>{" "}
          {props.data.data.initials[1]}
        </p>
      </div>
    );
  }

  if (props.data.type.indexOf("heartWithInitials") >= 0) {
    return (
      <div className="personalized-data-info">
        <div>
          <p>
            <span style={{ fontWeight: "500", fontSize: "11px" }}>
              Initials:
            </span>{" "}
            <span>{props.data.data.initials.join(" + ")}</span>
          </p>
        </div>
      </div>
    );
  }

  return null;
};

export default PersonalizedData;
