import React, { Component } from "react";
import "../../../styles/personalization.scss";

const ColorRow = (props) => {
  return (
    <div>
      <div className="radio-group">
        <p>Choose Color</p>
        <div datatip="Light Blue">
          <input
            type="radio"
            className="initials-light-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "light-blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "light-blue"}></label>
        </div>
        <div datatip="Cornflower">
          <input
            type="radio"
            className="initials-cornflower"
            checked={props.row.color === "#5c95d4"}
            value="#5c95d4"
            id={props.rowName + "cornflower"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "cornflower"}></label>
        </div>
        <div datatip="Purple">
          <input
            type="radio"
            className="initials-purple"
            checked={props.row.color === "#7859c6"}
            value="#7859c6"
            id={props.rowName + "purple"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "purple"}></label>
        </div>
        <div datatip="Red">
          <input
            type="radio"
            className="initials-red"
            checked={props.row.color === "#bc0000"}
            value="#bc0000"
            id={props.rowName + "red"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "red"}></label>
        </div>
        <div datatip="Melon">
          <input
            type="radio"
            className="initials-melon"
            checked={props.row.color === "#f67668"}
            value="#f67668"
            id={props.rowName + "melon"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "melon"}></label>
        </div>
        <div datatip="White">
          <input
            type="radio"
            className="initials-white"
            checked={props.row.color === "#ffffff"}
            value="#ffffff"
            id={props.rowName + "white"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "white"}></label>
        </div>
      </div>
    </div>
  );
};
const ColorRow2 = (props) => {
  return (
    <div className="radio-group">
      <div>
        <div datatip="Kelly">
          <input
            type="radio"
            className="initials-kelly"
            checked={props.row.color === "#239a4f"}
            value="#239a4f"
            id={props.rowName + "kelly"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "kelly"}></label>
        </div>
        <div datatip="Moss">
          <input
            type="radio"
            className="initials-moss"
            checked={props.row.color === "#85bb64"}
            value="#85bb64"
            id={props.rowName + "moss"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "moss"}></label>
        </div>
        <div datatip="Light Garnet">
          <input
            type="radio"
            className="initials-lightGarnet"
            checked={props.row.color === "#980a0a"}
            value="#980a0a"
            id={props.rowName + "lightGarnet"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "lightGarnet"}></label>
        </div>
        <div datatip="Orange">
          <input
            type="radio"
            className="initials-orange"
            checked={props.row.color === "#f8750c"}
            value="#f8750c"
            id={props.rowName + "orange"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "orange"}></label>
        </div>
        <div datatip="Yellow">
          <input
            type="radio"
            className="initials-yellow"
            checked={props.row.color === "#fccb20"}
            value="#fccb20"
            id={props.rowName + "yellow"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "yellow"}></label>
        </div>
        <div datatip="Gold">
          <input
            type="radio"
            className="initials-gold"
            checked={props.row.color === "#e0be61"}
            value="#e0be61"
            id={props.rowName + "gold"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "gold"}></label>
        </div>
      </div>
    </div>
  );
};

class BoldInitials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      color: "",
      error: null,
    };

    this.changeFirst = this.changeFirst.bind(this);
    this.changeSecond = this.changeSecond.bind(this);
    this.changeThird = this.changeThird.bind(this);
    this.changeFourth = this.changeFourth.bind(this);
    this.changeFifth = this.changeFifth.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isLetter(str) {
    return (
      (str.length === 1 && str.match(/[A-Z]/i)) ||
      (str.length === 1 && str.match(/[0-9]/i)) ||
      str === ""
    );
  }

  componentDidMount() {
    this.first.focus();
    if (this.props.data.data.initials.length > 0) {
      this.setState({
        first: this.props.data.data.initials[0],
        second: this.props.data.data.initials[1],
        third: this.props.data.data.initials[2],
        fourth: this.props.data.data.initials[3],
        fifth: this.props.data.data.initials[4],
        color: this.props.data.data.color,
      });
    }
  }

  clearInput(name) {
    this.setState({
      [name]: "",
    });
  }

  changeFirst(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).first.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        first: newLetter,
        error: null,
      });
      this.second.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z and numbers 0-9",
      });
    }
  }

  changeSecond(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).second.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        second: newLetter,
        error: null,
      });
      this.third.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z and numbers 0-9",
      });
    }
  }

  changeThird(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).third.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        third: newLetter,
        error: null,
      });
      this.fourth.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z and numbers 0-9",
      });
    }
  }

  changeFourth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fourth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        fourth: newLetter,
        error: null,
      });
      this.fifth.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z and numbers 0-9",
      });
    }
  }

  changeFifth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fifth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        fifth: newLetter,
        error: null,
      });
      this.fifth.blur();
    } else {
      this.setState({
        error: "You can only enter letters A-Z and numbers 0-9",
      });
    }
  }

  changeColor(e) {
    this.setState({ ...this.state, color: e.target.value });
  }

  onSubmit() {
    const initials = [
      this.state.first,
      this.state.second,
      this.state.third,
      this.state.fourth,
      this.state.fifth,
    ];
    const submission = {
      type: "boldInitials",
      data: {
        initials: initials,
        color: this.state.color,
      },
      valid:
        initials.filter((x) => x !== "").length >= 1 &&
        initials.filter((x) => x !== "").length <= 5 &&
        this.state.color !== "",

      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex">
              <input
                ref={(first) => {
                  this.first = first;
                }}
                value={this.state.first}
                onChange={this.changeFirst}
                onClick={() => this.clearInput("first")}
              />
              <input
                ref={(second) => {
                  this.second = second;
                }}
                value={this.state.second}
                onChange={this.changeSecond}
                onClick={() => this.clearInput("second")}
              />
              <input
                ref={(third) => {
                  this.third = third;
                }}
                value={this.state.third}
                onChange={this.changeThird}
                onClick={() => this.clearInput("third")}
              />
              <input
                ref={(fourth) => {
                  this.fourth = fourth;
                }}
                value={this.state.fourth}
                onChange={this.changeFourth}
                onClick={() => this.clearInput("fourth")}
              />
              <input
                ref={(fifth) => {
                  this.fifth = fifth;
                }}
                value={this.state.fifth}
                onChange={this.changeFifth}
                onClick={() => this.clearInput("fifth")}
              />

              <ColorRow
                row={this.state}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
              <br />
              <ColorRow2
                row={this.state}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
            </div>

            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <br />
            <div className="character-rules">
              <p>* To remove a character, please click the box</p>
              <p>
                * 1-5 Characters Allowed. Uppercase Letters and Numbers Only.
              </p>
              <br />
              <br />
              <p>
                Please enter initials in First, Middle, Last Initial order. For
                example, Andrew Benjamin Carpenter's initials would be entered
                as ABC. We recommend placing the recipients initials on the far
                right side of the belt so they sit on the right hip when worn.
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default BoldInitials;
