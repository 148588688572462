import React, { Component } from 'react';
import Belt from './Belt';

import '../../styles/belt.scss';
import '../../styles/belt-sizing.scss';

class BeltPreview extends Component {
  render() {
    return (
      <div id="belt">
        <div className="container">
          <h4>Your Belt Design<span>click motifs to add, and as you add more, you can rearrange by dragging and dropping them into the preferred order.</span></h4>
          <Belt id="belt"
                allMotifs={this.props.allMotifs}
                allPersonMotifs={this.props.allPersonMotifs}
                belt={this.props.belt}
                ideaBox={this.props.ideaBox}
                addMotifToBelt={this.props.addMotifToBelt}
                addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                removeMotifFromBelt={this.props.removeMotifFromBelt}
                moveMotifOnBelt={this.props.moveMotifOnBelt}
                restrictions={this.props.restrictions}
                setNotification={this.props.setNotification}
                showPremiumModal={this.props.showPremiumModal}
                showPersonalizationModal={this.props.showPersonalizationModal}
                updateMotifOnBelt={this.props.updateMotifOnBelt}
                showCustomInfo={this.props.showCustomInfo}
                sendAnalyticsEvent={this.props.sendAnalyticsEvent} />
        </div>
      </div>
    )
  }
}

export default BeltPreview;
