
const getChildConfigs = (data, motifs = []) => {
  let curLeftPad = 0;
  let configs = [];
  let motifsArr = [];
  const keysArr = Object.keys(data).map((item) => data[item]);

  keysArr.forEach((elem) => {
    let curMotif;
    if (elem) {
      curMotif = motifs.find((mot) =>
        mot.key.trim().toLowerCase().includes(elem.gender.toLowerCase())
      );
    
      motifsArr.push({
        ...curMotif,
        coloredPatterns: {
          skin: elem.skin,
          hair: elem.hair,
          clothing: elem.clothing,
        },
      });
    }
  });
 
  motifsArr.forEach((curMotif) => {  

    if (curMotif && curMotif.pixelConfigs) {
      let curConfigs = curMotif.pixelConfigs;
      let minX = Infinity;
      let minY = Infinity;

      curConfigs.forEach((item) => {
        if (item.pos[0] < minX) {
          minX = item.pos[0];
        }
        if (item.pos[1] < minY) {
          minY = item.pos[1];
        }
      });
      let difX = minX - 1;
      let difY = minY - 1;

      curConfigs.forEach((item) => {
        let pos = [item.pos[0] - difX + curLeftPad, item.pos[1] - difY];

        configs.push({
          ...item,
          pos,
          color: (item.pattern && curMotif.coloredPatterns && curMotif.coloredPatterns[item.pattern]) ? curMotif.coloredPatterns[item.pattern] : item.color,
        });
      });
    }

    curLeftPad =
      curLeftPad + (curMotif && curMotif.width ? curMotif.width : 0) + 1;
    
  });

  //compound width, height

  let minX = Infinity;
  let maxX = 0;
  let minY = Infinity;
  let maxY = 0;

  configs.forEach((el) => {
    if (el.pos[0] < minX) {
      minX = el.pos[0];
    }
    if (el.pos[0] > maxX) {
      maxX = el.pos[0];
    }
    if (el.pos[1] < minY) {
      minY = el.pos[1];
    }
    if (el.pos[1] > maxY) {
      maxY = el.pos[1];
    }
  });

  let width = maxX - minX + 1;
  let height = maxY - minY + 1;
 
  return {
    pixelConfigs: configs,
    width,
    height,
  };
};

export default getChildConfigs;
