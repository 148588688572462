import {combineReducers} from 'redux';
import AdminReducers from './AdminReducers';
import AuthReducers from './AuthReducers';
import GeneratorReducers from './GeneratorReducers';

const allReducers = combineReducers({
  admin: AdminReducers,
  auth: AuthReducers,
  generator: GeneratorReducers
});

export default allReducers;
