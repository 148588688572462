import React, { Component } from "react";
import "../../../styles/personalization.scss";

const ColorRow = (props) => {
  return (
    <div>
      <div className="radio-group">
        <p>Choose Color</p>
        <div datatip="Pink">
          <input
            type="radio"
            className="initials-pink"
            checked={props.row.color === "#f994ae"}
            value="#f994ae"
            id={props.rowName + "pink"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "pink"}></label>
        </div>
        <div datatip="Blue">
          <input
            type="radio"
            className="initials-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "blue"}></label>
        </div>
        <div datatip="Mint">
          <input
            type="radio"
            className="initials-mint"
            checked={props.row.color === "#89dc97"}
            value="#89dc97"
            id={props.rowName + "mint"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "mint"}></label>
        </div>
        <div datatip="White">
          <input
            type="radio"
            className="initials-white"
            checked={props.row.color === "#ffffff"}
            value="#ffffff"
            id={props.rowName + "white"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "white"}></label>
        </div>
      </div>
    </div>
  );
};

class DateMotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: "",
      day: "",
      year: "",
      color: "",
      error: null,
    };

    this.changeMonth = this.changeMonth.bind(this);
    this.changeDay = this.changeDay.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  isNumber(str) {
    return str.match(/^[0-9]{1,2}$/) || str === "";
  }

  clearInput(e) {
    this.setState({
      [e.target.name]: "",
    });
  }

  componentDidMount() {
    this.month.focus();
    if (this.props.data.data.date.length > 0) {
      this.setState({
        month: this.props.data.data.date[0],
        day: this.props.data.data.date[1],
        year: this.props.data.data.date[2],
        color: this.props.data.data.color
      });
    }
  }

  changeMonth(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        month: e.target.value,
      });
      this.day.focus();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        month: e.target.value,
      });
    }
  }

  changeDay(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        day: e.target.value,
      });
      this.year.focus();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        day: e.target.value,
      });
    }
  }

  changeYear(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        year: e.target.value,
      });
      this.year.blur();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        year: e.target.value,
      });
    }
  }

  changeColor(e) {
    this.setState({ ...this.state, color: e.target.value });
  }

  onSubmit() {
    const date = [this.state.month, this.state.day, this.state.year];
    const submission = {
      type: "date",
      data: {
        date: date,
        color: this.state.color
      },
      valid:
        date.filter((x) => this.isNumber(x) && x !== "").length === 3 && this.state.year.length === 2 &&
        this.state.color !== "",
      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex">
              <input
                className="date"
                ref={(month) => {
                  this.month = month;
                }}
                value={this.state.month}
                name="month"
                onClick={this.clearInput}
                placeholder="MM"
                onChange={this.changeMonth}
              />
              <p>-</p>
              <input
                className="date"
                ref={(day) => {
                  this.day = day;
                }}
                value={this.state.day}
                name="day"
                onClick={this.clearInput}
                placeholder="DD"
                onChange={this.changeDay}
              />
              <p>-</p>
              <input
                className="date"
                ref={(year) => {
                  this.year = year;
                }}
                value={this.state.year}
                name="year"
                onClick={this.clearInput}
                placeholder="YY"
                onChange={this.changeYear}
              />
              {/* <div className="input-container no-flex"> */}
              <ColorRow
                row={this.state}
                rowName="top"
                change={this.change}
                changeColor={this.changeColor}
                addSuffix={this.addSuffix}
                clearInput={this.clearInput}
              />
            </div>
            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <br />
            <div className="character-rules">
              <p>* To remove a number, please click the box</p>
              <br />
              <br />
              <p>
                4-6 Numbers Allowed, MM-DD-YY or M-D-YY Format (ex. 04-11-18 or
                4-11-18)
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default DateMotif;
