import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../styles/toolbar.scss";
import NotificationsContainer from "../../containers/NotificationsContainer";
import Popup from "./Popup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Contentful from "../../helpers/contentful";

class Toolbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleShare: false,
      copyButton: "Copy link",
      contentful: new Contentful(),
      basePrice: null,
    };

    this.toggleShare = this.toggleShare.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.arePersonalizedValid = this.arePersonalizedValid.bind(this);
    
    this.initContent();
  }

  initContent = async () => {
    let basePrice = await this.state.contentful.getEntry(
      '538ELQymmXp0rlJE83IqW9'
    );
    this.setState({ basePrice });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        copyButton: "Copy link",
      });
    }
  }

  continueToPurchase() {
    if (
      this.props.belt.motifs.length > 5 &&
      this.props.belt.hasSet &&
      this.props.userInfo &&
      this.arePersonalizedValid()
    ) {
      this.props.history.push("/submit");
    }
  }

  arePersonalizedValid() {
    const numPersonalized = this.props.belt.motifs.filter(
      (x) => x[2] !== null
    ).length;
    const personalizedDataArr = this.props.belt.motifs.map((x) => x[2]);
    const numValidPersonalized = personalizedDataArr.filter(
      (x) => x !== null && x.valid === true
    ).length;
    return numPersonalized === numValidPersonalized;
  }

  toggleShare() {
    if (!this.state.toggleShare) {
      this.props.sendShareData(this.props.belt, this.props.ideaBox.motifs);
    }

    this.setState({
      toggleShare: !this.state.toggleShare,
    });
  }

  onCopy() {
    this.props.sendAnalyticsEvent(null, "share_clicked", null);
    this.setState({ copyButton: "Copied!" });
  }

  handleRedirect = () => {
    window.location.assign("https://smathersandbranson.com");
  };

  render() {
    const queryParamString =
      window.location.origin + "?share=" + this.props.shareUUID;
    const motifsOnBelt = this.props.belt.motifs.map((x) =>
      this.props.library.Motifs.byId[x[0]]
        ? this.props.library.Motifs.byId[x[0]].upcharge
        : null
    );

    return (
      <div id="toolbar">
        <div className="container">
          <div id="toolbar-container">
            <div className="toolbar-left">             
              <div onClick={this.handleRedirect}>
                <img className="logo" src="/sb_logo.png" alt="" />
              </div>
              <div
                style={{
                  position: "relative",
                  display: "inherit",
                  marginLeft: "5px",
                }}
              >
                <Link to="/faq">FAQs</Link>
                <Popup
                  popupOrder={0}
                  top={"45px"}
                  left={"-50px"}
                  location={"bottom"}
                />
              </div>
              <div
                style={{
                  position: "relative",
                  display: "inherit",
                  marginLeft: "5px",
                }}
              >
                <Link to="/contact">Contact Us</Link>
              </div>
             
            </div>
            <div className="toolbar-center">
            <div className="toolbar-item">
            { this.state.basePrice ?  
                <p className="toolbar-item-content">
                  $
                  {motifsOnBelt.length > 0
                    ? motifsOnBelt.reduce((a, b) => a + b) + this.state.basePrice.price
                    : this.state.basePrice.price}
                </p> : null }
                <p className="toolbar-item-label">Belt Price</p>
              </div>

              <div className="toolbar-item-size">
                <p className="toolbar-item-content">
                  {this.props.belt.hasSet ? this.props.belt.size : "-- "}
                  <span className="small">in</span>
                </p>
                <p className="toolbar-item-label">Belt Size</p>
              </div>

              <div style={{ position: "relative" }}>
                <button className="btn grey" onClick={this.props.openSizeModal}>
                  Belt Size
                </button>
                <Popup
                  popupOrder={2}
                  top={"60px"}
                  left={"-3px"}
                  location={"bottom"}
                />
              </div>

              <div style={{ position: "relative" }}>
                <button
                  className="btn grey"
                  onClick={this.props.openPersonInfoModal}
                  style={{ marginRight: "15px" }}
                >
                  Your info
                </button>
                <Popup
                  popupOrder={3}
                  top={"60px"}
                  left={"-3px"}
                  location={"bottom"}
                />
              </div>
            </div>
            <div className="toolbar-right">             
              {this.props.belt.motifs.map((x) => x[2]).filter((x) => x !== null)
                .length > 0 ? (
                <div className="toolbar-item">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={this.props.showCustomInfo}
                      onChange={this.props.toggleCustomInfo}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p className="toolbar-item-label">Show Personalized Info</p>
                </div>
              ) : null}

              {this.props.belt.motifs.length > 0 ? (
                <button
                  className="btn ghost btn-m0"
                  onClick={() => {
                    if (
                      window.confirm("Are you sure you want to clear the belt?")
                    )
                      this.props.clearBelt();
                  }}
                >
                  Clear Belt
                </button>
              ) : null}
         
              <div className="share-container">
                <button className="btn grey" onClick={this.toggleShare}>
                  {this.state.toggleShare ? "Hide " : ""}Save/Share
                </button>
                {this.state.toggleShare ? (
                  <div id="share-popup">
                    {this.props.shareUUID ? (
                      <div>
                        <p className="copy-instructions">
                          Click the button below to copy the link to this
                          version of your design.
                        </p>
                        <p className="copy-instructions">
                          You can then paste this link into an email or document
                          to share or save.
                        </p>
                        <p className="copy-instructions">
                          If you make any changes to your design, you must copy
                          a new link in order to generate the new version of the
                          belt.
                        </p>
                        <CopyToClipboard
                          text={queryParamString}
                          onCopy={this.onCopy}
                        >
                          <button className="btn btn-share">
                            {this.state.copyButton}
                          </button>
                        </CopyToClipboard>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <button
                className={
                  this.props.belt.motifs.length > 5 &&
                  this.props.belt.hasSet &&
                  this.props.userInfo &&
                  this.arePersonalizedValid()
                    ? "btn"
                    : "btn btn-disabled"
                }
                onClick={this.continueToPurchase.bind(this)}
              >
                Continue
                {this.props.belt.motifs.length <= 5 ||
                !this.props.belt.hasSet ||
                !this.props.userInfo ? null : (
                  <span>&nbsp;&nbsp;&rarr;</span>
                )}
                {this.props.belt.motifs.length <= 5 ? (
                  <div className="button-tooltip">
                    Life Belts must include at least 6 motifs.
                  </div>
                ) : null}
                {!this.props.belt.hasSet ? (
                  <div className="button-tooltip">
                    Please choose a belt size first.
                  </div>
                ) : null}
                {!this.arePersonalizedValid() ? (
                  <div className="button-tooltip">
                    You must complete your personalized motifs.
                  </div>
                ) : null}
                {!this.props.userInfo ? (
                  <div className="button-tooltip">
                    You must fill in your contact info. Press the "YOUR INFO"
                    button to do so
                  </div>
                ) : null}
              </button>
            </div>
            <NotificationsContainer />
          </div>
        </div>
      </div>
    );
  }
}

export default Toolbar;
