import React, { Component } from 'react';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ModalBackground from "./ModalBackground";
import { Link } from "react-router-dom";
import Contentful from "../../helpers/contentful";
import "../../styles/modal.scss";

class SizeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beltSize: this.props.beltSize,
      contentful: new Contentful(),
      content: null,
    };
    this.initContent();
  }

  initContent = async () => {
    let content = await this.state.contentful.getEntry(
      "7CIJVQQNDxd8F9A02J63pn"
    );
    this.setState({ content });
  };

  handleChange(e) {
    this.setState({
      beltSize: e.target.value
    });
  }

  render() {   
    return (
      <>
        {this.state.content ? (
          <div className="modal-wrapper">
            <ModalBackground closeModal={this.props.closeModal} />
            <div id="size-modal" className="modal-content">
              <div>
                <h2>{this.state.content.title}</h2>
                <div>
                  <div className="size-buttons">
                    <select
                      className=""
                      value={this.state.beltSize}
                      onChange={this.handleChange.bind(this)}
                    >
                      <option value="28">28 Belt (fits size 25-26 pants)</option>
                      <option value="29">29 Belt (fits size 26-27 pants)</option>
                      <option value="30">30 Belt (fits size 27-28 pants)</option>
                      <option value="31">31 Belt (fits size 28-29 pants)</option>
                      <option value="32">32 Belt (fits size 29-30 pants)</option>
                      <option value="33">33 Belt (fits size 30-31 pants)</option>
                      <option value="34">34 Belt (fits size 31-32 pants)</option>
                      <option value="35">35 Belt (fits size 32-33 pants)</option>
                      <option value="36">36 Belt (fits size 33-34 pants)</option>
                      <option value="37">37 Belt (fits size 34-35 pants)</option>
                      <option value="38">38 Belt (fits size 35-36 pants)</option>
                      <option value="39">39 Belt (fits size 36-37 pants)</option>
                      <option value="40">40 Belt (fits size 37-38 pants)</option>
                      <option value="41">41 Belt (fits size 38-39 pants)</option>
                      <option value="42">42 Belt (fits size 39-40 pants)</option>
                      <option value="43">43 Belt (fits size 40-41 pants)</option>
                      <option value="44">44 Belt (fits size 41-42 pants)</option>
                      <option value="45">45 Belt (fits size 42-43 pants)</option>
                      <option value="46">46 Belt (fits size 43-44 pants)</option>
                      <option value="47">47 Belt (fits size 44-45 pants)</option>
                      <option value="48">48 Belt (fits size 45-46 pants)</option>
                      <option value="49">49 Belt (fits size 46-47 pants)</option>
                      <option value="50">50 Belt (fits size 47-48 pants)</option>
                      <option value="51">51 Belt (fits size 48-49 pants)</option>
                      <option value="52">52 Belt (fits size 49-50 pants)</option>
                    </select>
                    <button
                      className="btn"
                      onClick={() => this.props.updateSize(this.state.beltSize)}
                    >
                      Update
                    </button>
                    <button
                      className="btn grey"
                      onClick={this.props.closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <br />
                <div id="size-modal-instructions">
                  {documentToReactComponents(this.state.content.text1)}
                  <h3 className="size-modal-header">
                    {this.state.content.subtitle1}
                  </h3>
                  <div className="size-modal-text-container">
                    <img
                      className="img-left"
                      src={this.state.content.measuringWithBelt.fields.file.url}
                      style={{ maxWidth: "400px" }}
                      alt=""
                    />
                    {documentToReactComponents(this.state.content.text2)}
                  </div>

                  <h3 className="size-modal-header">
                    {this.state.content.subtitle2}
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <img
                      className="img-right"
                      src={
                        this.state.content.measuringDirections.fields.file.url
                      }
                      style={{ maxWidth: "800px" }}
                      alt=""
                    />
                    {documentToReactComponents(this.state.content.text3)}
                  </div>

                  <h3 className="size-modal-header">
                    {this.state.content.subtitle3}
                  </h3>
                  <div className="size-modal-text-container">
                    {documentToReactComponents(this.state.content.text4)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default SizeModal;
