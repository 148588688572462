export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export function loginUser(password) {
  return function(dispatch) {
    return fetch("/api/authentication/login", {
      method: "POST",
      body: JSON.stringify({password: password}),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(result => {
      if (result.error) {
        localStorage.removeItem('admin');
        dispatch(failure(result.error))
      } else {
        localStorage.setItem('admin', JSON.stringify({token: result.token}));
        dispatch(success(result.token))
      }
    });
  }

  function success(token) { return { type: LOGIN_SUCCESS, payload: token } }
  function failure(error) { return { type: LOGIN_FAILURE, payload: error } }
}

export function logoutUser() {
  localStorage.removeItem('admin');
  return {
    type: "LOGOUT_SUCCESS"
  }
}
