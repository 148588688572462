import React, { Component } from 'react'
import NewRestriction from './NewRestriction';

class AdminRestriction extends Component {
  constructor(props) {
    super(props);
    this.deleteRestriction = this.deleteRestriction.bind(this);
  }

  componentDidMount() {
    this.props.adminRequestRestrictions();
  }

  buildRestrictions() {
    return this.props.restrictions.allIds.map(id => {
      var restriction = this.props.restrictions.byId[id];
      return (
        <tr key={"restriction-" + restriction.id}>
          <td className="restriction-name">{restriction.name}</td>
          <td>{restriction.restrictionA}</td>
          <td>{restriction.restrictionB}</td>
          <td>{restriction.message}</td>
          <td><p className="delete-restriction" onClick={() => this.deleteRestriction(restriction.id)}>Delete</p></td>
        </tr>
      )
    })
  }

  deleteRestriction(id) {
    this.props.adminDeleteRestriction(id);
  }

  render() {
    return (
      <div id="admin-restrictions">
        <NewRestriction categories={this.props.categories} subcategories={this.props.subcategories} adminCreateRestriction={this.props.adminCreateRestriction} />
        <hr />
        <table className="restriction-list">
          <thead>
            <tr>
              <th>Restriction Name</th>
              <th>First Subcategory</th>
              <th>Second Subcategory</th>
              <th>Custom Message</th>
              <th style={{width: '40px'}}>Delete</th>
            </tr>
          </thead>

          <tbody>
            {this.props.restrictions.allIds.length > 0 ? this.buildRestrictions() : null}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AdminRestriction;
