import React, { Component } from "react";

import "../../styles/submit-belt.scss";
import "../../styles/belt-sizing.scss";

const queryString = require("query-string");
const paramObj = queryString.parse(window.location.search);

class Design extends Component {
  constructor(props) {
    super(props)  
    this.state = {
      design: null,
    };
    this.getDesign();
  }

  async getDesign() {
    fetch("/api/generator/design?design=" + paramObj.design)
      .then((res) => res.json())
      .then((data) => {       
        this.setState({ design: `data:image/png;base64,${data.design}` });
      });
  }
 
  render() {
     
    return (
      <div id="generator-mobile">
        <div id="submit-belt">
          <div className="container">
            <h4>
              DESIGN FILE NAME: [{" "}
              {paramObj ? paramObj.design.toUpperCase() : ""} ]
            </h4>
            {this.state.design ? (
              <img
                src={this.state.design}
                style={{ width: "100%" }}
                alt=""
              ></img>
            ) : null}          
          </div>
        </div>
      </div>
    );
  }
}

export default Design;
