import React, { Component } from "react";

import "../../styles/generator.scss";
import Toolbar from "./Toolbar";
import BeltPreview from "./BeltPreview";
import LibraryIdea from "./LibraryIdea";
import MobileBlock from "./MobileBlock";
import SizeModal from "./SizeModal";
import PremiumModal from "./PremiumModal";
import Loading from "./Loading";
import PersonalizationModal from "./PersonalizationModal";
import { sizing, sum } from "../../helpers";
import PersonInfoModal from "./PersonInfoModal";

const queryString = require("query-string");

class Generator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSizeModal: false,
      showPersonInfoModal: false,
      showPremiumModal: false,
      showPersonalizationModal: false,
      showCustomInfo: false,
    };

    this.addMotifToBeltParent = this.addMotifToBeltParent.bind(this);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.requestLibrary();
    this.props.requestPersonLibrary();
    this.props.requestRestrictions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const paramObj = queryString.parse(window.location.search);
      if (
        (Object.keys(paramObj).indexOf("share") === 0 ||
          Object.keys(paramObj).indexOf("completed") === 0) &&
        Object.keys(this.props.library.Motifs.byId).length > 0
      ) {
        this.props.loadStateFromQuery(paramObj);
        this.props.history.push("/");
      } else if (
        Object.keys(paramObj).indexOf("design") === 0 &&
        Object.keys(this.props.library.Motifs.byId).length > 0
      ) {
        this.props.loadStateFromQuery(paramObj);
        this.props.history.push("/design/");
      }
    }
  }

  addMotifToBeltParent(motifId, rand) {
    const blockInitials = [
      "PL-31",
      "PL-35",
      "PL-30",
      "PL-33",
      "PL-36",
      "PL-32",
      "PL-34",
    ];
    const boldInitials = ["PL-48"];
    const classicInitials = [
      "PL-38",
      "PL-42",
      "PL-37",
      "PL-40",
      "PL-43",
      "PL-39",
      "PL-41",
    ];
    const initialsWithDate = ["PL-26", "PL-27", "PL-28", "PL-29"];
    const date = ["PL-44", "PL-45", "PL-46", "PL-47"];
    const weddingRingsWithDate = ["PL-2"];
    const heartWithInitials = ["PL-1", "PM-heart3"];
    const twoStackedInitials = ["PL-21"];
    const threeStackedInitials = ["PL-22"];
    const fourStackedInitials = ["PL-23"];
    const fiveStackedInitials = ["PL-24"];
    const sixStackedInitials = ["PL-25"];
    // const oneBoy = ["PL-3", "PL-9", "PL-15"];
    // const oneGirl = ["PL-4", "PL-10", "PL-16"];
    // const twoChildren = ["PL-5", "PL-11", "PL-17"];
    const children = ["PL-6", "PL-12", "PL-18"];
    // const fourChildren = ["PL-7", "PL-13", "PL-19"];
    // const fiveChildren = ["PL-8", "PL-14", "PL-20"];
    const greekInitials = ["PL-49"];
    const foundMotif = this.props.library.Motifs.byId[motifId];

    let personalizedData;

    if (blockInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "blockInitials",
        data: {
          initials: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (boldInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "boldInitials",
        data: {
          initials: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (greekInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "greekInitials",
        data: {
          initials: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (weddingRingsWithDate.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "weddingRingsWithDate",
        data: {
          date: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (date.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "date",
        data: {
          date: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (classicInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "classicInitials",
        data: {
          initials: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (initialsWithDate.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "initialsWithDate",
        data: {
          initials: [],
          date: [],
          color: "",
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (heartWithInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "heartWithInitials",
        data: {
          initials: [],
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (twoStackedInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "twoStackedInitials",
        data: {
          initials: {},
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (threeStackedInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "threeStackedInitials",
        data: {
          initials: {},
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (fourStackedInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "fourStackedInitials",
        data: {
          initials: {},
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (fiveStackedInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "fiveStackedInitials",
        data: {
          initials: {},
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (sixStackedInitials.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "sixStackedInitials",
        data: {
          initials: {},
        },
        valid: false,
        isFirstLoad: true,
      };
    } else if (children.indexOf(foundMotif.dbid) >= 0) {
      personalizedData = {
        type: "children",
        data: {
          one: {
            gender: "",
            hair: "",
            clothing: "",
          },
          two: {
            gender: "",
            hair: "",
            clothing: "",
          },
          three: {
            gender: "",
            hair: "",
            clothing: "",
          },
        },
        valid: false,
        isFirstLoad: true,
      };
    }

    this.props.addMotifToBelt(motifId, rand, personalizedData);
  }

  showSizeModal() {
    this.setState({
      showSizeModal: true,
    });
  }

  hideSizeModal() {
    this.setState({
      showSizeModal: false,
    });
  }

  showPersonInfoModal() {
    this.setState({
      showPersonInfoModal: true,
    });
  }

  hidePersonInfoModal() {
    this.setState({
      showPersonInfoModal: false,
    });
  }

  showPremiumModal() {
    this.setState({
      showPremiumModal: true,
    });
  }

  hidePremiumModal() {
    this.setState({
      showPremiumModal: false,
    });
  }

  showPersonalizationModal() {
    this.setState({
      showPersonalizationModal: true,
    });
  }

  hidePersonalizationModal() {
    this.setState({
      showPersonalizationModal: false,
    });
  }

  toggleCustomInfo() {
    this.setState({
      showCustomInfo: !this.state.showCustomInfo,
    });
  }

  updateBeltSize(beltSize) {
    //
    // When downsizing, if current motifs don't fit, remove last
    // from belt until they fit and notify motifs have been removed.
    //
    var newBeltMotifs = [...this.props.belt.motifs];

    const sideSpacing = 38 * 2;
    var minGapSpacing =
      newBeltMotifs.length > 0 ? (newBeltMotifs.length - 1) * 9 : 0;
    var motifWidths = sum(
      newBeltMotifs.map((x) => this.props.library.Motifs.byId[x[0]].width)
    );

    var previousWidth = sideSpacing + minGapSpacing + motifWidths;

    var shouldNotify = false;

    while (previousWidth > sizing[beltSize]) {
      shouldNotify = true;
      newBeltMotifs.pop();
      minGapSpacing =
        newBeltMotifs.length > 0 ? (newBeltMotifs.length - 1) * 9 : 0;
      motifWidths = sum(
        // newBeltMotifs.map((x) => this.props.library.Motifs.byId[x[0]].width)
        newBeltMotifs.map((x) =>
          x[3] ? x[3].width : this.props.library.Motifs.byId[x[0]].width
        )
      );
      previousWidth = sideSpacing + minGapSpacing + motifWidths;
    }

    if (shouldNotify) {
      // Notify or 15 seconds
      this.props.setNotification(
        "Not all previous motifs fit on the new belt size. Some have been removed to your idea box.",
        "error",
        15000
      );
    }

    // Update belt size and belt
    this.props.replaceBeltMotifsAndResize(newBeltMotifs, beltSize);
    this.hideSizeModal();
  }

  render() {
    let persoMotifCategories = [];
    if (
      this.props.library.PersonMotifs.byId &&
      Object.keys(this.props.library.PersonMotifs.byId).length > 0
    ) {
      persoMotifCategories = Object.keys(
        this.props.library.PersonMotifs.byId
      ).map((id) => {
        let category =
          this.props.library.PersonCategories.byId[
            this.props.library.PersonMotifs.byId[id].PersonCategoryId
          ];
        let m = {
          ...this.props.library.PersonMotifs.byId[id],
          categoryName: category ? category.name : "",
        };
        return m;
      });
    }
    return (
      <div>
        {Object.keys(this.props.library.Motifs.byId).length > 0 ? (
          <div onMouseDown={() => this.props.hideNotification()}>
            <MobileBlock />
            <div id="generator">
              {this.state.showPremiumModal ? (
                <PremiumModal closeModal={this.hidePremiumModal.bind(this)} />
              ) : null}
              {this.state.showPersonalizationModal ? (
                <PersonalizationModal
                  closeModal={this.hidePersonalizationModal.bind(this)}
                />
              ) : null}
              {this.state.showSizeModal ? (
                <SizeModal
                  beltSize={this.props.belt.size}
                  closeModal={this.hideSizeModal.bind(this)}
                  updateSize={this.updateBeltSize.bind(this)}
                />
              ) : null}
              {this.state.showPersonInfoModal ? (
                <PersonInfoModal
                  closeModal={this.hidePersonInfoModal.bind(this)}
                  addUserInfo={this.props.addUserInfo}
                  userInfo={this.props.userInfo}
                />
              ) : null}
              <Toolbar
                {...this.props}
                openSizeModal={this.showSizeModal.bind(this)}
                openPersonInfoModal={this.showPersonInfoModal.bind(this)}
                showCustomInfo={this.state.showCustomInfo}
                toggleCustomInfo={this.toggleCustomInfo.bind(this)}
                sendShareData={this.props.sendShareData}
                shareUUID={this.props.shareUUID}
              />
              <BeltPreview
                allMotifs={this.props.library.Motifs}
                allPersonMotifs={persoMotifCategories}
                belt={this.props.belt}
                ideaBox={this.props.ideaBox}
                addMotifToBelt={this.addMotifToBeltParent}
                moveMotifOnBelt={this.props.moveMotifOnBelt}
                addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                removeMotifFromBelt={this.props.removeMotifFromBelt}
                restrictions={this.props.restrictions}
                setNotification={this.props.setNotification}
                showPremiumModal={this.showPremiumModal.bind(this)}
                showPersonalizationModal={this.showPersonalizationModal.bind(
                  this
                )}
                updateMotifOnBelt={this.props.updateMotifOnBelt}
                showCustomInfo={this.state.showCustomInfo}
                sendAnalyticsEvent={this.props.sendAnalyticsEvent}
              />

              <LibraryIdea
                library={this.props.library}
                addMotifToBelt={this.addMotifToBeltParent}
                addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                ideaBox={this.props.ideaBox}
                belt={this.props.belt}
                restrictions={this.props.restrictions}
                setNotification={this.props.setNotification}
                showPremiumModal={this.showPremiumModal.bind(this)}
                showPersonalizationModal={this.showPersonalizationModal.bind(
                  this
                )}
                clearIdeaBox={this.props.clearIdeaBox}
                sendAnalyticsEvent={this.props.sendAnalyticsEvent}
              />
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default Generator;
