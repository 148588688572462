import React, { Component } from "react";
import "../../../styles/personalization.scss";

const ColorRow = (props) => {
  return (
    <div>
      <div className="radio-group">
        <p>Choose Color</p>
        <div datatip="Pink">
          <input
            type="radio"
            className="initials-pink"
            checked={props.row.color === "#f994ae"}
            value="#f994ae"
            id={props.rowName + "pink"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "pink"}></label>
        </div>
        <div datatip="Blue">
          <input
            type="radio"
            className="initials-blue"
            checked={props.row.color === "#8cbddf"}
            value="#8cbddf"
            id={props.rowName + "blue"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "blue"}></label>
        </div>
        <div datatip="Mint">
          <input
            type="radio"
            className="initials-mint"
            checked={props.row.color === "#89dc97"}
            value="#89dc97"
            id={props.rowName + "mint"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "mint"}></label>
        </div>
        <div datatip="White">
          <input
            type="radio"
            className="initials-white"
            checked={props.row.color === "#ffffff"}
            value="#ffffff"
            id={props.rowName + "white"}
            name={props.rowName + "color"}
            onChange={(e) => props.changeColor(e, props.rowName)}
          />
          <label htmlFor={props.rowName + "white"}></label>
        </div>
      </div>
    </div>
  );
};
class InitialsWithDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      second: "",
      third: "",
      fourth: "",
      fifth: "",
      month: "",
      day: "",
      year: "",
      color: "",
      error: null,
    };

    this.changeFirst = this.changeFirst.bind(this);
    this.changeSecond = this.changeSecond.bind(this);
    this.changeThird = this.changeThird.bind(this);
    this.changeFourth = this.changeFourth.bind(this);
    this.changeFifth = this.changeFifth.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.changeDay = this.changeDay.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.changeColor = this.changeColor.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.addSuffix = this.addSuffix.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  isLetter(str) {
    return (str.length === 1 && str.match(/[A-Z]/i)) || str === "";
  }

  isNumber(str) {
    return str.match(/^[0-9]{1,2}$/) || str === "";
  }

  clearInput(name) {
    this.setState({
      [name]: "",
    });
  }

  addSuffix(suffix) {
    if (this.state.first === "") {
      this.setState({
        first: suffix,
      });
    } else if (this.state.second === "") {
      this.setState({
        second: suffix,
      });
    } else if (this.state.third === "") {
      this.setState({
        third: suffix,
      });
    } else if (this.state.fourth === "") {
      this.setState({
        fourth: suffix,
      });
    } else if (this.state.fifth === "") {
      this.setState({
        fifth: suffix,
      });
    }
  }

  componentDidMount() {
    this.first.focus();
    if (this.props.data.data.initials.length > 0) {
      this.setState({
        first: this.props.data.data.initials[0],
        second: this.props.data.data.initials[1],
        third: this.props.data.data.initials[2],
        fourth: this.props.data.data.initials[3],
        fifth: this.props.data.data.initials[4],
        month: this.props.data.data.date[0],
        day: this.props.data.data.date[1],
        year: this.props.data.data.date[2],
        color: this.props.data.data.color,
      });
    }
  }

  changeFirst(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).first.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        first: newLetter,
        error: null,
      });
      this.second.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeSecond(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).second.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        second: newLetter,
        error: null,
      });
      this.third.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeThird(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).third.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        third: newLetter,
        error: null,
      });
      this.fourth.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeFourth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fourth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        fourth: newLetter,
        error: null,
      });
      this.fifth.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeFifth(e) {
    const input = e.target.value.toUpperCase().split("");
    const state = Object.assign({}, this.state).fifth.split("");
    let newLetter = input.filter((n) => !state.includes(n)).join("");
    if (input[0] === state[0] && input[1] === state[0]) {
      newLetter = input[0];
    }

    if (this.isLetter(newLetter)) {
      this.setState({
        fifth: newLetter,
        error: null,
      });
      this.month.focus();
    } else {
      this.setState({
        error: "You can only enter letters A-Z",
      });
    }
  }

  changeMonth(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        month: e.target.value,
      });
      this.day.focus();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        month: e.target.value,
      });
    }
  }

  changeDay(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        day: e.target.value,
      });
      this.year.focus();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        day: e.target.value,
      });
    }
  }

  changeYear(e) {
    if (!this.isNumber(e.target.value)) {
      this.setState({
        error: "You can only add numbers",
      });
    } else if (e.target.value.length === 2) {
      this.setState({
        year: e.target.value,
      });
      this.year.blur();
    } else if (e.target.value.length > 2) {
      this.setState({
        error: "Only 2 digits allowed for each input",
      });
    } else {
      this.setState({
        year: e.target.value,
      });
    }
  }

  changeColor(e) {
  
    this.setState({ ...this.state, color: e.target.value });
  }

  onSubmit() {
    const initials = [
      this.state.first,
      this.state.second,
      this.state.third,
      this.state.fourth,
      this.state.fifth,
    ];
    const date = [this.state.month, this.state.day, this.state.year];
    const submission = {
      type: "initialsWithDate",
      data: {
        initials: initials,
        date: date,
        color: this.state.color,
      },
      valid:
        initials.filter((x) => x !== "").length >= 1 &&
        initials.filter((x) => x !== "").length <= 5 &&
        date.filter((x) => this.isNumber(x) && x !== "" && x.length >= 1)
          .length === 3 &&
        this.state.year.length === 2 &&
        this.state.color !== "",
      isFirstLoad: false,
    };

    if (!submission.valid) {
      this.props.setNotification(
        'Your personalized motif is not complete. Click "Edit" when you\'re ready to complete it.',
        "error",
        10000
      );
    }

    this.props.updateMotifOnBelt(submission);
    this.props.toggle();
  }

  render() {
    return (
      <div className="motif-personalization-edit">
        <div className="personalization-wrapper">
          <div className="content">
            <div className="input-container no-flex">
              <h5>Initials</h5>
              <div className="edit-flex">
                <input
                  ref={(first) => {
                    this.first = first;
                  }}
                  value={
                    this.state.first.includes("R-")
                      ? this.state.first.replace("R-", "")
                      : this.state.first
                  }
                  onChange={this.changeFirst}
                  onClick={() => this.clearInput("first")}
                />
                <input
                  ref={(second) => {
                    this.second = second;
                  }}
                  value={
                    this.state.second.includes("R-")
                      ? this.state.second.replace("R-", "")
                      : this.state.second
                  }
                  onChange={this.changeSecond}
                  onClick={() => this.clearInput("second")}
                />
                <input
                  ref={(third) => {
                    this.third = third;
                  }}
                  value={
                    this.state.third.includes("R-")
                      ? this.state.third.replace("R-", "")
                      : this.state.third
                  }
                  onChange={this.changeThird}
                  onClick={() => this.clearInput("third")}
                />
                <input
                  ref={(fourth) => {
                    this.fourth = fourth;
                  }}
                  value={
                    this.state.fourth.includes("R-")
                      ? this.state.fourth.replace("R-", "")
                      : this.state.fourth
                  }
                  onChange={this.changeFourth}
                  onClick={() => this.clearInput("fourth")}
                />
                <input
                  ref={(fifth) => {
                    this.fifth = fifth;
                  }}
                  value={
                    this.state.fifth.includes("R-")
                      ? this.state.fifth.replace("R-", "")
                      : this.state.fifth
                  }
                  onChange={this.changeFifth}
                  onClick={() => this.clearInput("fifth")}
                />
              </div>
              <div className="suffix">
                <p onClick={() => this.addSuffix("R-Jr")}>Jr</p>
                <p onClick={() => this.addSuffix("R-II")}>II</p>
                <p onClick={() => this.addSuffix("R-III")}>III</p>
                <p onClick={() => this.addSuffix("R-IV")}>IV</p>
                <p onClick={() => this.addSuffix("R-V")}>V</p>
                <p onClick={() => this.addSuffix("R-VI")}>VI</p>
                <p onClick={() => this.addSuffix("+")}>+</p>
              </div>
              <br />
              <h5>Date</h5>
              <div className="edit-flex">
                <input
                  className="date"
                  ref={(month) => {
                    this.month = month;
                  }}
                  value={this.state.month}
                  placeholder="MM"
                  onChange={this.changeMonth}
                  onClick={() => this.clearInput("month")}
                />
                <p>-</p>
                <input
                  className="date"
                  ref={(day) => {
                    this.day = day;
                  }}
                  value={this.state.day}
                  placeholder="DD"
                  onChange={this.changeDay}
                  onClick={() => this.clearInput("day")}
                />
                <p>-</p>
                <input
                  className="date"
                  ref={(year) => {
                    this.year = year;
                  }}
                  value={this.state.year}
                  placeholder="YY"
                  onChange={this.changeYear}
                  onClick={() => this.clearInput("year")}
                />
              </div>
              <div>
                <ColorRow
                  row={this.state}
                  rowName="top"
                  change={this.change}
                  changeColor={this.changeColor}
                  addSuffix={this.addSuffix}
                  clearInput={this.clearInput}
                />
              </div>
            </div>

            <br />
            {this.state.error ? (
              <p className="edit-error">{this.state.error}</p>
            ) : null}
            <div className="character-rules">
              <p>* To remove a character, please click the box</p>
              <p>* Click a suffix to add it to the next open box</p>
              <br />
              <br />
              <p>
                <b>Initials</b>
              </p>
              <p>
                1-5 Characters Allowed. Uppercase Letters, Suffixes and Plus
                Sign.
              </p>
              <p>
                * Please enter initials in First, Middle, Last Initial order.
                For example, Andrew Benjamin Carpenter's initials would be
                entered as ABC.
              </p>
              <br />
              <br />
              <p>
                <b>Date</b>
              </p>
              <p>
                4-6 Numbers Required, MM-DD-YY or M-D-YY Format (ex. 04-11-18 or
                4-11-18)
              </p>
            </div>
          </div>
          <button onClick={this.onSubmit}>{String.fromCharCode(10003)}</button>
        </div>
      </div>
    );
  }
}

export default InitialsWithDate;
