import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { buildSelectOptions } from '../../helpers/index.js';

class NewMotif extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      name: "",
      width: "",
      height: "",
      backgroundColors: "",
      upcharge: "",
      maxUses: "",
      colorKey: "",
      adminTags: "",
      subcategories: [],
      newSubcategory: "",
      searchTags: "",
      priority: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addNewSubcategory = this.addNewSubcategory.bind(this);
    this.deleteSubcategory = this.deleteSubcategory.bind(this);
  }

  handleChange(e) {
    if (e.target.type === "file") {
      this.setState({
        file: e.target.files[0]
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value
      });
    }
  }

  addNewSubcategory(e) {
    if (e.target.value !== "" && (this.state.subcategories.indexOf(parseInt(e.target.value)) === -1)) {
      this.setState({
        subcategories: [...this.state.subcategories, parseInt(e.target.value)],
        newSubcategory: ""
      });
    }
  }

  deleteSubcategory(id) {
    const newSubArr = this.state.subcategories;
    this.state.subcategories.splice(this.state.subcategories.indexOf(parseInt(id)), 1);
    this.setState({
      ...this.state,
      subcategories: newSubArr
    });
  }

  handleSubmit() {
    var newMotif = Object.assign({}, this.state);

    if (newMotif.backgroundColors !== "") {
      newMotif.backgroundColors = newMotif.backgroundColors.replace(/ /g, "").split(",").map(i => parseInt(i));
    }

    if (newMotif.adminTags !== "") {
      newMotif.adminTags = newMotif.adminTags.replace(/ /g, "").split(",");
    }

    const data = new FormData();

    data.append('file', newMotif.file);
    data.append('name', newMotif.name);
    data.append('width', newMotif.width);
    data.append('height', newMotif.height);
    data.append('backgroundColors', newMotif.backgroundColors);
    data.append('upcharge', newMotif.upcharge);
    data.append('maxUses', newMotif.maxUses);
    data.append('colorKey', newMotif.colorKey);
    data.append('adminTags', newMotif.adminTags);
    data.append('subcategories', newMotif.subcategories);
    data.append('searchTags', newMotif.searchTags);
    data.append('priority', newMotif.priority);

    this.props.adminCreateMotif(data);
    this.props.history.push("/admin/motifs");
  }

  render() {
    return (
      <div id="new-motif">
        <div className="cardbox">
          <div className="cardbox-header">
            <h4>Create New Motif</h4>
            <Link className="close-link" to="/admin/motifs">cancel</Link>
          </div>
          <div className="flexbox">
            <div>
              <label>File</label>
              <input type="file" onChange={this.handleChange} name="file"/>
            </div>

            <div>
              <label>Motif Name</label>
              <input className="form-control" name="name" placeholder="Name" value={this.state.name} onChange={this.handleChange} />
            </div>

            <div>
              <label>Width</label>
              <input className="form-control" name="width" placeholder="Width" value={this.state.width} onChange={this.handleChange} />
            </div>
            
            <div>
              <label>Height</label>
              <input className="form-control" name="height" placeholder="Height" value={this.state.height} onChange={this.handleChange} />
            </div>
            
            <div>
              <label>Background Colors</label>
              <input className="form-control" name="backgroundColors" placeholder='Bkg Colors (separate by comma)' value={this.state.backgroundColors} onChange={this.handleChange} />
            </div>
            
            <div>
              <label>Upcharge</label>
              <input className="form-control" name="upcharge" placeholder="Upcharge" value={this.state.upcharge} onChange={this.handleChange} />
            </div>
            
            <div>
              <label>Max Uses</label>
              <input className="form-control" name="maxUses" placeholder="Max Uses" value={this.state.maxUses} onChange={this.handleChange} />
            </div>
            
            <div>
              <label>Admin Tags</label>
              <input className="form-control" name="adminTags" placeholder='Admin Tags (separate by comma)' value={this.state.adminTags} onChange={this.handleChange} />
            </div>

            <div>
              <label>Search Tags</label>
              <input className="form-control" name="searchTags" placeholder='Search Tags (separate by comma)' value={this.state.searchTags} onChange={this.handleChange} />
            </div>

            <div>
              <label>Priority</label>
              <input className="form-control" name="priority" placeholder='Priority' value={this.state.priority} onChange={this.handleChange} />
            </div>
          </div>
          <div className="flexbox">
            <div>
              <label>Color Key</label>
              <textarea className="form-control" name="colorKey" placeholder="Color Key" value={this.state.colorKey} onChange={this.handleChange}></textarea>
            </div>
          </div>
          <div>
            <label>Subcategories</label>
            <div className="edit-motif-subcategories">
              {this.state.subcategories ? this.state.subcategories.map((subcategoryId, index) => {
                return (
                  <div key={"subcategory-" + subcategoryId}>
                    <p className="motif-subcategory" key={"subcategory-" + subcategoryId}>{this.props.subcategories.byId[subcategoryId].name}</p>
                    <p className="delete-subcategory" onClick={() => this.deleteSubcategory(subcategoryId)}>X</p>
                  </div>
                )
              }) : null }
            </div>
            <select className="form-control" name="newSubcategory" value={this.state.newSubcategory} onChange={this.addNewSubcategory}>
              {buildSelectOptions(this.props.categories, this.props.subcategories)}
            </select>
          </div>
          <hr />
          <div className="flexbox">
            <button onClick={this.handleSubmit} className="btn btn-success">Create Motif</button>
          </div>
        </div>
      </div>
    )
  }
}

export default NewMotif