import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/login.scss';

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: ""
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.password.focus();

    if (localStorage.getItem('admin')) {
      this.props.history.push("/admin/motifs");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth !== this.props.auth && localStorage.getItem('admin')) {
      this.props.history.push("/admin/motifs");
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.loginUser(this.state.password);
  }

  render() {
    return (
      <div id="login-container">
        <div className="login-module">
          <h4>Admin Login</h4>

          <form onSubmit={this.handleSubmit}>
            <input ref={(input) => { this.password = input; }} name="password" value={this.state.password} onChange={this.handleChange} className="form-control" placeholder="Password" type="password" />
            <button className="btn btn-m0" type="submit">Login</button>
          </form>

          <p className="error-message">{this.props.auth.errorMessage ? this.props.auth.errorMessage : null}</p>
          <Link to="/">Back to app</Link>
        </div>
      </div>
    )
  }
}

export default LoginComponent;
