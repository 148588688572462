import React, { Component } from 'react';

class MotifTools extends Component {
  render() {
    return (
      <div className="motif-tools">
        <div className="tool">
          <p onClick={() => this.props.removeMotif(this.props.index)}>&times;</p>
        </div>
      </div>
    )
  }
}

export default MotifTools;
