import React, { Component } from 'react'

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      newSub: "",
      visible: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.addNewSub = this.addNewSub.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {
    this.setState({
      name: this.props.name
    });
  }

  handleChange(e) {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    this.props.adminUpdateCategory(this.props.id, this.state.name);
    this.setState({
      visible: false
    });
  }

  addNewSub(e) {
    this.setState({
      newSub: "",
      visible: false
    });
    this.props.adminAddSubcategory(this.props.id, this.state.newSub);
  }

  toggleEdit() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <div>
        { this.state.visible ?
          (
            <div id="edit-category">
              <input className="form-control" type="text" name="name" value={this.state.name} onChange={this.handleChange} />
              <button className="btn btn-secondary" onClick={this.handleSubmit}>Update Name</button>

              <hr />

              <input className="form-control" name="newSub" placeholder="Subcategory Name" value={this.state.newSub} onChange={this.handleChange} />
              <button className="btn btn-secondary" onClick={this.addNewSub}>Add Subcategory</button>
            </div>
          ) : (
            <p className="toggle-edit" onClick={this.toggleEdit}>Edit</p>
          )
        }
      </div>
    )
  }
}

export default EditCategory;
