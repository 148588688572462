import React, { Component } from 'react';
import Motif from './Motif';
import sortBy from 'lodash/sortBy';
import { validateAddToBelt } from '../../helpers';

class Library extends Component {
  clickToAdd(motifId, rand) {
    if (validateAddToBelt(motifId, rand, this.props.library.Motifs, this.props.belt, this.props.restrictions, this.props.setNotification)) {
      this.props.sendAnalyticsEvent(motifId, "belt", null);
      this.props.addMotifToBelt(motifId, rand);
    }
  }
  
  createLibrary() {

      return this.props.library.Categories.allIds.map(categoryId => {
      var category = this.props.library.Categories.byId[categoryId];
      var subcategories = category.Subcategories.map(subcategoryId => {
        var subcategory = this.props.library.Subcategories.byId[subcategoryId];

        // Sort the motifs in subcategory by priority then name
        var sortedMotifs = sortBy(subcategory.motifs.map(key => this.props.library.Motifs.byId[key]), ['priority', 'name']);
      
        var motifs = sortedMotifs.map((motif, i) => {
          return (
            <Motif key={"motif-" + motif.id}
                   listId="library"
                   motif={motif}
                   clickToAdd={this.clickToAdd.bind(this)}
                   addMotifToIdeaBox={this.props.addMotifToIdeaBox}
                   ideaBox={this.props.ideaBox}
                   zIndex={i}
                   showPremiumModal={this.props.showPremiumModal}
                   showPersonalizationModal={this.props.showPersonalizationModal} />
          )
        })
        return motifs.length > 0 ? (
          <div className="library-subcategory" key={"subcategory-" + subcategoryId} >
            {category.name !== subcategory.name ? <h4>{subcategory.name}</h4> : null}
            <div className="motifs-container">
              {motifs}
            </div>
          </div>
        ) : null;
      })
      return subcategories.length > 0 ? (
        <div className="library-category" key={"category-" + categoryId} >
          <div className="category-column">
            <h2>{category.name}</h2>
          </div>
          <div className="subcategory-column">
            {subcategories}
          </div>
        </div>
      ) : null;
    });
  }

  createSearchLibrary() {
    var searchMotifs;

    if (this.props.searchMotifs.length > 0) {
      searchMotifs = this.props.searchMotifs.map((motifId, i) => {
        var motif = this.props.library.Motifs.byId[motifId];
        return (
          <Motif key={"motif-" + motifId}
            listId="library"
            motif={motif}
            clickToAdd={this.clickToAdd.bind(this)}
            addMotifToIdeaBox={this.props.addMotifToIdeaBox}
            ideaBox={this.props.ideaBox}
            zIndex={i}
            showPremiumModal={this.props.showPremiumModal}
            showPersonalizationModal={this.props.showPersonalizationModal} />
        );
      });
    } else {
      searchMotifs = <p className="no-results">No results</p>;
    }

    return (
      <div className="library-category">
        <div className="category-column">
          <h2>Search Results</h2>
        </div>
        <div className="subcategory-column">
          <div className="library-subcategory">
            <div className="motifs-container">
              {searchMotifs}
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div id="library">
        <div id="library-cont">
          {this.props.searching ? this.createSearchLibrary() : this.createLibrary()}
        </div>
      </div>
    )
  }
}

export default Library;
