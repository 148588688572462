import React, { Component } from 'react';
import ModalBackground from './ModalBackground';
import '../../styles/modal.scss';
import Contentful from '../../helpers/contentful';

class PremiumModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentful: new Contentful(),
      content: null,
    };

    this.initContent();
  }

  initContent = async () => {
    let content = await this.state.contentful.getEntry(
      '4MtmhZlNgLbqy9tCyGKyt9'
    );
    this.setState({ content });
  };

  render() {
    return (
      <div className='modal-wrapper'>
        <ModalBackground closeModal={this.props.closeModal} />
        <div id='premium-modal' className='modal-content'>
          <div>
            {this.state.content ? (
              <>              
                <h2>{this.state.content.title1}</h2>
                <p>{this.state.content.text1}</p>
                <h2>{this.state.content.title2}</h2>
                <p>{this.state.content.text2}</p>
              </>
            ) : null}
             <div className='close-buttons'>
              <button
                className='btn grey btn-m0'
                onClick={this.props.closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PremiumModal;
